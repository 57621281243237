import React from 'react';
import './InfoComponent.css';

const TabanUniversiteInfo = () => {
  return (
    <div className="info-container">
      <h2>TEMEL (TABAN) EK ÖDEME YAPILMASININ AMACI NEDİR?</h2>
      <p>
        Yükseköğretim kurumlarının döner sermaye faaliyetleri çerçevesinde; hizmet sunum şartları ve kriterleri de dikkate alınmak suretiyle personelin unvanı, görevi, çalışma şartları ve süresi, eğitim-öğretim ve araştırma faaliyetleri ve mesleki uygulamalar ile ilgili performansı ve özellik arz eden riskli bölümlerde çalışma gibi hizmete katkı unsurları esas alınarak yapılacak taban ödemenin oran, usul ve esaslarını belirlemektir.
      </p>

      <h2>TEMEL (TABAN) EK ÖDEME KİMLERİ KAPSAR?</h2>
      <p>
        4/11/1981 tarihli ve 2547 sayılı Yükseköğretim Kanununun 38 inci maddesine göre bu kuruluşlarda görevlendirilen personeli, diğer kamu kurum ve kuruluşlarının kadrolarında bulunan ve en az bir ay süreyle bu Yükseköğretim kuruluşlarda görevlendirilen sağlık personelini kapsar.
      </p>

      <h2>TEMEL (TABAN) EK ÖDEME RESMİ DAYANAĞI NEDİR?</h2>
      <p>
        4/11/1981 tarihli ve 2547 sayılı Kanunun 58 inci maddesinin (Değişik ibare:RG-8/7/2014-29054) (h) ve (i) fıkralarına dayanılarak hazırlanmıştır.
      </p>

      <h2>TEMEL (TABAN) EK ÖDEME ESASLARI NELERDİR?</h2>
      <p>
        *Taban ödeme; personelin ödüllendirilerek motivasyonunun artırılması amacıyla yapılan ödemedir. 2547 sayılı Kanunun 58 inci maddesinin (c) fıkrası kapsamındaki personele yapılacak bu ödeme, Ek-4’te yer alan tablodaki kadro unvanına göre belirlenen taban katsayısının en yüksek devlet memuru aylığı ile çarpımı sonucu bulunan tutardır. Bu ödemenin hesaplanmasında aktif çalışılan gün katsayısı ayrıca çarpan olarak kullanılır. Ancak bu ödeme mesai saatleri dışında serbest meslek faaliyetinde bulunan veya özel sağlık kuruluşlarında çalışmakta olanlara ödenmez.
      </p>
      <p>
        Ayrıca İlgili yönetmeliğin Ek-4 Taban Ödeme Katsayı Cetvelinde yer alan kadro unvanları için katsayılar; 9/3/2022 tarihli ve 31942 sayılı Resmî Gazete’de yayımlanan Tıpta ve Diş Hekimliğinde Uzmanlık Eğitimi Yönetmeliğine göre, yan dal uzmanlık eğitimini tamamlamış olanlar için %100, yan dal uzmanlık eğitimi alan asistan/araştırma görevlileri için %50 artırımlı uygulanır.
      </p>

      <h2>TEMEL (TABAN) EK ÖDEME HESAPLANIRKEN GELİR VERGİ DİLİMİNİ NASIL ÖĞRENEBİLİRİM?</h2>
      <p>
        *Gelir vergisi dilimini en son E-Bordro Maaş bilginizde yer alan Toplam Gelir Matrah tutarının aşağıdaki tabloda karşılık gelen vergi oranı olarak belirlenir.
      </p>
      <table>
        <thead>
          <tr>
            <th>Gelir Dilimi</th>
            <th>Vergi Oranı</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0-110.000 TL</td>
            <td>Yüzde 15</td>
          </tr>
          <tr>
            <td>110.000 TL - 230.000 TL</td>
            <td>Yüzde 20</td>
          </tr>
          <tr>
            <td>230.000 TL - 870.000 TL</td>
            <td>Yüzde 27</td>
          </tr>
          <tr>
            <td>870.000 TL - 3.000.000 TL</td>
            <td>Yüzde 35</td>
          </tr>
          <tr>
            <td>3.000.000 TL'den fazlası</td>
            <td>Yüzde 40</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TabanUniversiteInfo;

import React, { useState } from 'react';
import { Container, Row, Col, Form, Table, Alert, Button} from 'react-bootstrap';
import { useNavigate } from "react-router-dom"; 

const data = [
  { unvan: "112 S.M. SAĞLIK PERSONELİ", tekoks: 0.37, evdm: 8624.062, tesvikKatsayi: 0.8, odeme: 2552.72, gelirVergisi: 382.91, damgaVergisi: 19.38, netTutar: 2150.44 },
  { unvan: "112 SAĞLIK PERSONELİ", tekoks: 0.9, evdm: 8624.062, tesvikKatsayi: 0.9, odeme: 4467.26, gelirVergisi: 670.09, damgaVergisi: 33.91, netTutar: 3763.27 },
  { unvan: "112 DOKTOR", tekoks: 2.486, evdm: 8624.062, tesvikKatsayi: 0.9, odeme: 19295.45, gelirVergisi: 2894.32, damgaVergisi: 146.45, netTutar: 16254.70 },
  { unvan: "GENEL İDARİ HİZMETLER", tekoks: 0.37, evdm: 8624.062, tesvikKatsayi: 0.7, odeme: 1103.88, gelirVergisi: 165.58, damgaVergisi: 8.38, netTutar: 929.92 },
  { unvan: "TEKNİK HİZMETLER", tekoks: 0.5, evdm: 8624.062, tesvikKatsayi: 0.7, odeme: 1103.88, gelirVergisi: 165.58, damgaVergisi: 8.38, netTutar: 929.92 },
  { unvan: "İSM UZMAN TABİP", tekoks: 1.2, evdm: 8624.062, tesvikKatsayi: 0.9, odeme: 13043.27, gelirVergisi: 2046.49, damgaVergisi: 103.55, netTutar: 11493.22 },
  { unvan: "İSM TABİP", tekoks: 2.26, evdm: 8624.062, tesvikKatsayi: 0.9, odeme: 4867.32, gelirVergisi: 4074.89, damgaVergisi: 206.19, netTutar: 4430.24 },
  { unvan: "UZMAN DİŞ TABİBİ", tekoks: 4.5, evdm: 8624.062, tesvikKatsayi: 0.1, odeme: 827.91, gelirVergisi: 106.18, damgaVergisi: 6.28, netTutar: 697.44 },
  { unvan: "YARDIMCI HİZMETLER SINIFI", tekoks: 0.14, evdm: 8624.062, tesvikKatsayi: 0.7, odeme: 16371.92, gelirVergisi: 2455.79, damgaVergisi: 124.26, netTutar: 13791.87 },
  { unvan: "İSM SORUMLU TABİP", tekoks: 0.37, evdm: 8624.062, tesvikKatsayi: 0.7, odeme: 1103.88, gelirVergisi: 165.58, damgaVergisi: 8.38, netTutar: 929.92 }
];

function BasamakTesvikOdemeleri() {
  const [selectedUnvan, setSelectedUnvan] = useState(data[0]);

  const handleSelectChange = (event) => {
    const selected = data.find(item => item.unvan === event.target.value);
    setSelectedUnvan(selected);
  };
  const navigate = useNavigate();

  return (
    <Container className="BasamakOdeme mt-5">
            <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1 id="basamak-tesvik-odemeleri" className="my-4">1. BASAMAK TEŞVİK ÖDEME HESAPLAMALARI</h1>
      <Form>
        <Form.Group as={Row} controlId="formUnvanSelect">
          <Form.Label column sm={2}>Ünvan Seçin</Form.Label>
          <Col sm={10}>
            <Form.Control as="select" onChange={handleSelectChange}>
              {data.map((item, index) => (
                <option key={index} value={item.unvan}>{item.unvan}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      </Form>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>T.E.K.Ö.K.S.</th>
            <th>E.V.D.M.</th>
            <th>Teşvik Katsayısı</th>
            <th>Ödenen Teşvik Ek Ödeme Brüt Tutarı</th>
            <th>Gelir Vergisi</th>
            <th>Damga Vergisi</th>
            <th>Net Tutar</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selectedUnvan.tekoks}</td>
            <td>{selectedUnvan.evdm}</td>
            <td>{selectedUnvan.tesvikKatsayi}</td>
            <td>{selectedUnvan.odeme}</td>
            <td>{selectedUnvan.gelirVergisi}</td>
            <td>{selectedUnvan.damgaVergisi}</td>
            <td>{selectedUnvan.netTutar}</td>
          </tr>
        </tbody>
      </Table>
      <Alert variant="info" className="mt-4">
        <h3>Bilgilendirme</h3>
        <h4>---------------------</h4>
        
      </Alert>  
    </Container>
  );
}

export default BasamakTesvikOdemeleri;

import React from 'react';
import './IcapInfo.css';

const IcapInfo = () => {
  return (
    <div className="oncall-container">
      <h2>İCAP NÖBET ÖDEMELERİNİN YAPILMASININ AMACI NEDİR?</h2>
      <p>
        Sağlık Profesyonellerinin haftalık çalışma süresi dışında normal, acil veya branş icap nöbeti tutarak, bu nöbet karşılığında kurumunca izin kullanmasına müsaade edilmeyen memurlar ile sözleşmeli personele, izin suretiyle karşılanamayan her bir icap nöbet saati için icap nöbet ücreti ödenmesidir.
      </p>
      
      <h2>İCAP NÖBET ÖDEMELERİ KİMLERİ KAPSAR?</h2>
      <p>
        Yataklı tedavi kurumları, seyyar hastaneler, ağız ve diş sağlığı merkezleri, aile sağlığı merkezleri, toplum sağlığı merkezleri ve 112 acil sağlık hizmetlerinde görev yapan memurları kapsar.
      </p>

      <h2>İCAP NÖBET ÖDEMELERİNİN RESMİ DAYANAĞI NEDİR?</h2>
      <p>
        657 sayılı Devlet Memurları Kanunu, 2021/7 Sağlık Bakanlığı Ölüm Bildirim Genelgesi, 7.Dönem Toplu Sözleşme ve Yataklı Tedavi Kurumu Uygulama yönetmeliğine dayanır.
      </p>

      <h2>İCAP NÖBET ÖDEMELERİNİN NASIL HESAPLANIR?</h2>
      <p>
        *İcap nöbet ücretleri (nöbet süresi kesintisiz 12 saatten az olmamak üzere), aşağıda gösterilen gösterge rakamlarının aylık katsayısı ile çarpılması sonucu hesaplanacak tutarda icap nöbet ücreti ödenir. <br/>
        *Bu ücret damga vergisi hariç herhangi bir vergi ve kesintiye tabi tutulmaz.
      </p>

      <table>
        <thead>
          <tr>
            <th>Unvan</th>
            <th>Gösterge</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Eğitim görevlisi, başasistan, uzman tabip</td>
            <td>64</td>
          </tr>
          <tr>
            <td>Tabip, tıpta uzmanlık mevzuatında belirtilen dallarda, bu mevzuat hükümlerine göre uzmanlık belgesi alan tabip dışı personel, aynı dallarda doktora belgesi alanlar</td>
            <td>58</td>
          </tr>
          <tr>
            <td>Diş tabibi ve eczacılar</td>
            <td>52</td>
          </tr>
          <tr>
            <td>Mesleki yükseköğrenim görmüş sağlık personeli</td>
            <td>40</td>
          </tr>
          <tr>
            <td>Lise dengi mesleki öğrenim görmüş sağlık personeli</td>
            <td>34</td>
          </tr>
          <tr>
            <td>Diğer personel</td>
            <td>26</td>
          </tr>
        </tbody>
      </table>

      <h2>İCAP BAYRAM NÖBET ÖDEMELERİ NASIL HESAPLANIR?</h2>
      <p>
        Bayram icap nöbet ödemeleri 7.Dönem Toplu Sözleşmesi 7(2) maddesine istinaden 657 sayılı Kanunun ek 33 üncü maddesi uyarınca ödenen nöbet ücretleri resmi ve dini bayram günleri için %25 artırımlı ödenir.
      </p>
    </div>
  );
}

export default IcapInfo;

import React, { useState } from "react";
import { Container, Modal, Row, Col, Form, Alert,Button  } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import IcapInfo from "./IcapInfo";
import { useNavigate } from "react-router-dom"; 
const data = [
  {
    Unvani: "1-Eğitim Görevlisi, Başasistan, Uzman Tabip",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 160,
    normal: 57.66,
    diniBayramNormal: 14.41,
  },
  {
    Unvani: "2-Pratisyen Tabip",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 145,
    normal: 52.25,
    diniBayramNormal: 13.06,
  },
  {
    Unvani: "3-Diş tabibi ve Eczacılar",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 130,
    normal: 46.85,
    diniBayramNormal: 11.71,
  },
  {
    Unvani: "4-Lisans ve Önlisans Sağlık Personeli",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 100,
    normal: 36.04,
    diniBayramNormal: 9.01,
  },
  {
    Unvani: "4-Lisans ve Önlisans Sağlık Personeli",
    memuriyetTipi: "4B",
    gostergeRakamı: 100,
    normal: 30.99,
    diniBayramNormal: 7.75,
  },
  {
    Unvani: "5-Lise veya dengi Sağlık Personeli",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 85,
    normal: 30.63,
    diniBayramNormal: 7.66,
  },
  {
    Unvani: "5-Lise veya dengi Sağlık Personeli",
    memuriyetTipi: "4B",
    gostergeRakamı: 85,
    normal: 26.34,
    diniBayramNormal: 6.59,
  },
  {
    Unvani: "6-Diğer personel",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 65,
    normal: 23.42,
    diniBayramNormal: 5.86,
  }
];



function Icap() {
  const [selectedUnvan, setSelectedUnvan] = useState("");
  const [selectedmemuriyetTipi, setSelectedmemuriyetTipi] = useState("");
  const [normalNobet, setNormalNobet] = useState(0);
  const [diniBayramNormal, setDiniBayramNormal] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [netTutar, setNetTutar] = useState(0);
  const navigate = useNavigate();
  const handleClose = () => setShowModal(false);
  const uniqueUnvanlar = Array.from(new Set(data.map((item) => item.Unvani)));
  const filteredmemuriyetTipi = data.filter(
    (item) => item.Unvani === selectedUnvan
  );
  const selectedData = data.find(
    (item) =>
      item.Unvani === selectedUnvan &&
      item.memuriyetTipi === selectedmemuriyetTipi
  );



  const unvanChange = (val) => {
    setSelectedUnvan(val);
    setNormalNobet(0);
    setDiniBayramNormal(0);
    setNetTutar(0);
  };
  const memuriyetTipiChange = (val) => {
    setSelectedmemuriyetTipi(val);
    setNormalNobet(0);
    setDiniBayramNormal(0);
    setNetTutar(0);
  };

  const normalNobetSet = (saat) => {
    if (Number(saat) < Number(diniBayramNormal)) {
      setModalMessage(
        "Bayram nöbet saati nöbet saatinden fazla olamaz."
      );
      setShowModal(true);
    } else {

        setNormalNobet(saat);
        setNetTutar(
          selectedData.normal * saat +
            selectedData.diniBayramNormal * diniBayramNormal
        );
    }
    

  };
  const bayramNobetSet = (saat) => {
    if (Number(saat) > Number(normalNobet)) {
      setModalMessage(
        "Bayram nöbet saati nöbet saatinden fazla olamaz."
      );
      setShowModal(true);
    } else {

      setDiniBayramNormal(saat);
      setNetTutar((selectedData.normal * normalNobet) + (selectedData.diniBayramNormal * saat));
    }

  };



  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1
        className="text-center mb-4"
        style={{ fontSize: "2.2rem", fontWeight: "bold" }}
      >
        İCAP ÜCRETİ HESAPLAMA
      </h1>
      <Form>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1.5rem" }}>
          <Form.Label column sm="6">
            Ünvan:
          </Form.Label>
          <Col sm="6">
            <Form.Control
              as="select"
              style={{ fontSize: "1.5rem" }}
              value={selectedUnvan}
              onChange={(e) => unvanChange(e.target.value)}
            >
              <option value="">Seçiniz</option>
              {uniqueUnvanlar.map((unvan) => (
                <option key={unvan} value={unvan}>
                  {unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {selectedUnvan && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
              Memuriyet Tipi:
            </Form.Label>
            <Col sm="6">
              <Form.Control
                as="select"
                style={{ fontSize: "1.5rem" }}
                value={selectedmemuriyetTipi}
                onChange={(e) => memuriyetTipiChange(e.target.value)}
              >
                <option value="">Seçiniz</option>
                {filteredmemuriyetTipi.map((item) => (
                  <option key={item.memuriyetTipi} value={item.memuriyetTipi}>
                    {item.memuriyetTipi}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedData && (
          <>
            <Form.Group as={Row} className="mb-3 align-items-center">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Gösterge Rakamı:
              </Form.Label>
              <Form.Label
                column
                sm="6"
                style={{
                  fontSize: "1.5rem",
                  backgroundColor: "red",
                  maxWidth: "25%",
                  marginLeft: "12.5%",
                  color: "white",
                }}
              >
                {Number(selectedData.gostergeRakamı) * 0.4}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Nöbet Saati:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="number"
                  value={normalNobet}
                  max={770}
                  min={0}
                  onChange={(e) => normalNobetSet(e.target.value)}
                  style={{ fontSize: "1.5rem" }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Bayram Nöbet Saati:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="number"
                  value={diniBayramNormal}
                  max={770}
                  min={0}
                  onChange={(e) => bayramNobetSet(e.target.value)}
                  style={{ fontSize: "1.5rem" }}
                />
              </Col>
            </Form.Group>
          </>
        )}

        {selectedData && netTutar > 0 && (
          <Alert variant="success" className="basamak-sonuc">
            <h4
              className="basamak-yazi"
              style={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              ÖDENECEK NET TUTAR:{" "}
              {netTutar.toFixed(2).toLocaleString(undefined)} ₺
            </h4>
          </Alert>
        )}
      </Form>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Uyarı</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
      <IcapInfo></IcapInfo>
    </Container>
  );
}

export default Icap;

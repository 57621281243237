import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TesvikBirinciInfo from "./TesvikBirinciInfo";
const data = [
  {
    gorevYeriUnvani: "112 VE ÖZELLİKLİ BİRİMLER",
    gorevUnvani: "SAĞLIK PERSONELİ",
    tekoks: 0.518,
    eydma: 8624.062,
    netTesvikKatsayisi: 1,
  },
  {
    gorevYeriUnvani: "112 VE ÖZELLİKLİ BİRİMLER",
    gorevUnvani: "PRATİSYEN HEKİM",
    tekoks: 2.486,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.9,
  },
  {
    gorevYeriUnvani: "112 VE ÖZELLİKLİ BİRİMLER",
    gorevUnvani: "GENEL İDARİ HİZMETLER",
    tekoks: 0.224,
    eydma: 8624.062,
    netTesvikKatsayisi: 1,
  },
  {
    gorevYeriUnvani: "112 VE ÖZELLİKLİ BİRİMLER",
    gorevUnvani: "TEKNİK HİZMETLER",
    tekoks: 0.224,
    eydma: 8624.062,
    netTesvikKatsayisi: 1,
  },
  {
    gorevYeriUnvani: "112 VE ÖZELLİKLİ BİRİMLER",
    gorevUnvani: "UZMAN TABİP",
    tekoks: 4.95,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.9,
  },
  {
    gorevYeriUnvani: "112 VE ÖZELLİKLİ BİRİMLER",
    gorevUnvani: "YARDIMCI HİZMETLER SINIFI",
    tekoks: 0.168,
    eydma: 8624.062,
    netTesvikKatsayisi: 1,
  },
  {
    gorevYeriUnvani: "HALK SAĞLIĞI LABORATUARLARI",
    gorevUnvani: "SAĞLIK PERSONELİ",
    tekoks: 0.37,
    eydma: 8624.062,
    netTesvikKatsayisi: 1,
  },
  {
    gorevYeriUnvani: "HALK SAĞLIĞI LABORATUARLARI",
    gorevUnvani: "LABORATUVAR SORUMLU TABİP",
    tekoks: 2.26,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.9,
  },
  {
    gorevYeriUnvani: "HALK SAĞLIĞI LABORATUARLARI",
    gorevUnvani:
      "LABORATUVAR SORUMLUSU BİYOLOG, MÜHENDİS, KİMYAGER, LİSANSİYERLER",
    tekoks: 0.37,
    eydma: 8624.062,
    netTesvikKatsayisi: 1,
  },
  {
    gorevYeriUnvani: "İL SAĞLIK MÜDÜRLÜĞÜ VE BAĞLI BİRİMLER",
    gorevUnvani: "SAĞLIK PERSONELİ",
    tekoks: 0.37,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.8,
  },
  {
    gorevYeriUnvani: "İL SAĞLIK MÜDÜRLÜĞÜ VE BAĞLI BİRİMLER",
    gorevUnvani: "GENEL İDARİ HİZMETLER",
    tekoks: 0.16,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.8,
  },
  {
    gorevYeriUnvani: "İL SAĞLIK MÜDÜRLÜĞÜ VE BAĞLI BİRİMLER",
    gorevUnvani: "TEKNİK HİZMETLER",
    tekoks: 0.16,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.8,
  },
  {
    gorevYeriUnvani: "İL SAĞLIK MÜDÜRLÜĞÜ VE BAĞLI BİRİMLER",
    gorevUnvani: "UZMAN TABİP",
    tekoks: 4.5,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.7,
  },
  {
    gorevYeriUnvani: "İL SAĞLIK MÜDÜRLÜĞÜ VE BAĞLI BİRİMLER",
    gorevUnvani: "PRATİSYEN HEKİM",
    tekoks: 2.26,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.7,
  },
  {
    gorevYeriUnvani: "İL SAĞLIK MÜDÜRLÜĞÜ VE BAĞLI BİRİMLER",
    gorevUnvani: "UZMAN DİŞ TABİBİ",
    tekoks: 4.5,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.7,
  },
  {
    gorevYeriUnvani: "İL SAĞLIK MÜDÜRLÜĞÜ VE BAĞLI BİRİMLER",
    gorevUnvani: "YARDIMCI HİZMETLER SINIFI",
    tekoks: 0.12,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.8,
  },
  {
    gorevYeriUnvani: "İL SAĞLIK MÜDÜRLÜĞÜ VE BAĞLI BİRİMLER",
    gorevUnvani: "TSM SORUMLU UZMAN TABİP",
    tekoks: 2.26,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.7,
  },
];

const TesvikBirinciBasamak = () => {
  const [selectedGorevYeriUnvani, setSelectedGorevYeriUnvani] = useState("");
  const [selectedGorevUnvani, setSelectedGorevUnvani] = useState("");
  const [selectedVergiDilimi, setSelectedVergiDilimi] = useState(0.15);
  const navigate = useNavigate();
  const uniqueGorevYeriUnvanlari = [
    ...new Set(data.map((item) => item.gorevYeriUnvani)),
  ];

  const handleGorevYeriUnvaniChange = (e) => {
    setSelectedGorevYeriUnvani(e.target.value);
    setSelectedGorevUnvani("");
  };

  const handleGorevUnvaniChange = (e) => {
    setSelectedGorevUnvani(e.target.value);
  };

  const handleVergiDilimiChange = (e) => {
    setSelectedVergiDilimi(parseFloat(e.target.value));
  };

  const filteredGorevUnvanlari = data
    .filter((item) => item.gorevYeriUnvani === selectedGorevYeriUnvani)
    .map((item) => item.gorevUnvani);
  const uniqueGorevUnvanlari = [...new Set(filteredGorevUnvanlari)];

  const selectedData = data.find(
    (item) =>
      item.gorevYeriUnvani === selectedGorevYeriUnvani &&
      item.gorevUnvani === selectedGorevUnvani
  );

  const BrutOdemeTutari = selectedData
    ? (
        selectedData.tekoks *
        selectedData.eydma *
        selectedData.netTesvikKatsayisi
      ).toFixed(2)
    : 0;

  const GelirVergisi = selectedData
    ? (BrutOdemeTutari * selectedVergiDilimi).toFixed(2)
    : 0;

  const DamgaVergisi = selectedData
    ? (BrutOdemeTutari * 0.00759).toFixed(2)
    : 0;

  const NetTutar = selectedData
    ? (BrutOdemeTutari - GelirVergisi - DamgaVergisi).toFixed(2)
    : 0;

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1>Teşvik Birinci Basamak Bilgileri</h1>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Görev Yeri Ünvanı:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={selectedGorevYeriUnvani}
              onChange={handleGorevYeriUnvaniChange}
            >
              <option value="">Seçiniz</option>
              {uniqueGorevYeriUnvanlari.map((unvani) => (
                <option key={unvani} value={unvani}>
                  {unvani}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {selectedGorevYeriUnvani && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Görev Ünvanı:
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="select"
                value={selectedGorevUnvani}
                onChange={handleGorevUnvaniChange}
              >
                <option value="">Seçiniz</option>
                {uniqueGorevUnvanlari.map((unvani) => (
                  <option key={unvani} value={unvani}>
                    {unvani}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedData && (
          <>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Vergi Dilimi (%):
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  value={selectedVergiDilimi}
                  onChange={handleVergiDilimiChange}
                >
                  <option value={0.15}>%15</option>
                  <option value={0.2}>%20</option>
                  <option value={0.27}>%27</option>
                  <option value={0.35}>%35</option>
                  <option value={0.4}>%40</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                T.E.K.Ö.K.S.:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={selectedData.tekoks}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                E.Y.D.M.A:
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" value={selectedData.eydma} readOnly />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Net Teşvik Katsayısı:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={selectedData.netTesvikKatsayisi}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Ödenecek Teşvik Ek Ödeme Brüt Tutarı:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={BrutOdemeTutari + " ₺"}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Gelir Vergisi:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={GelirVergisi + " ₺"}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Damga Vergisi:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={DamgaVergisi + " ₺"}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Net Tutar:
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" value={NetTutar + " ₺"} readOnly />
              </Col>
            </Form.Group>
          </>
        )}
      </Form>
      <TesvikBirinciInfo></TesvikBirinciInfo>
    </Container>
  );
};

export default TesvikBirinciBasamak;

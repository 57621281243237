import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Image, Card, Button } from 'react-bootstrap';
import Hesaplama from './TayinBedeli'; // Hesaplama bileşenini import ediyoruz
import TabanEkOdeme from './TabanEkOdeme'; // Taban Ek Odeme bileşenini import ediyoruz
import BasamakTesvikOdemeleri from './BasamakTesvikOdemeleri'; // Basamak Teşvik Ödemeleri bileşenini import ediyoruz
import MaasHesabi from './MaasHesabi'; // 657 Maaş Hesaplaması bileşenini import ediyoruz
import { useNavigate } from "react-router-dom"; 
import BasamakOdeme from './BasamakOdeme';


function SecimAdli() {
  const navigate = useNavigate();
  const cards = [
    {
      title: "Hizmet 4",
      link: "/Maas",
      linkText: "MAAŞ HESAPLAMASI"
    },
    {
      title: "Hizmet 2",
      link: "/calisiyor",
      linkText: "PERFORMANS ÖDEMESİ"
    },


    {
      title: "Hizmet 4",
      link: "/nobet",
      linkText: "NÖBET"
    },
    {
      title: "Hizmet 4",
      link: "/icap",
      linkText: "İCAP"
    }
  ];
  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Container className="mt-4">
              <Button
                variant="primary"
                className="mt-2"
                style={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  fontSize: "1rem",
                }}
                onClick={() => navigate(-1)}
              >
                Anasayfa
              </Button>
              <h1
                className="text-center mb-4"
                style={{ fontSize: "2.2rem", fontWeight: "bold" }}
              >
                ADLİ TIP KURUMU
              </h1>
              <Row>
                {cards.map((card, index) => (
                  <Col md={4} lg={4} key={index} className="mb-4">
                    <Card>
                      <Card.Header>
                        <img
                          src={`${process.env.PUBLIC_URL}/ss_logo.png`}
                          alt="Sağlık-Sen Logo"
                          className="img-fluid mx-auto d-block"
                          style={{ width: "50%" }} // Logo boyutunu ayarla
                        />
                      </Card.Header>
                      <Card.Body className="d-flex justify-content-center">
                        <Button
                          variant="primary"
                          as={Link}
                          to={card.link}
                          className="mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          {card.linkText}
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          }
        />
      </Routes>
    </div>
  );  
}

export default SecimAdli;

import React, { useState } from 'react';
import { Container, Row, Col, Form, Table, Alert, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"; 
import TayinBedeliInfo from './TayinBedeliInfo'; 
const data = [
  { unvan: "GENEL İDARİ HİZMETLER SINIFI", ekGosterge: 2400, aylikKatsayi: 0.907796, gunlukBrutTutar: 72.62 },
  { unvan: "PRATİSYEN HEKİM", ekGosterge: 2400, aylikKatsayi: 0.907796, gunlukBrutTutar: 72.62 },
  { unvan: "SAĞLIK MEMURU LİSE", ekGosterge: 2400, aylikKatsayi: 0.907796, gunlukBrutTutar: 72.62 },
  { unvan: "SAĞLIK TEKNİKERİ LİSANS", ekGosterge: 2400, aylikKatsayi: 0.907796, gunlukBrutTutar: 72.62 },
  { unvan: "UZMAN HEKİM", ekGosterge: 2400, aylikKatsayi: 0.907796, gunlukBrutTutar: 72.62 },
];

function TayinBedeli() {
  const [selectedUnvan, setSelectedUnvan] = useState("GENEL İDARİ HİZMETLER SINIFI");
  const [gunSayisi, setGunSayisi] = useState(0); // Default çalışma gün sayısı
  const [result, setResult] = useState(null);

  const uniqueUnvanlar = Array.from(new Set(data.map((item) => item.unvan)));
  const navigate = useNavigate();
  const handleGunSayisiChange = (event) => {
    const gun = parseInt(event.target.value, 10);
    setGunSayisi(gun);
    if (selectedUnvan) {
      handleCalculate(gun);
    }
  };

  const selectedData = data.find((item) => item.unvan === selectedUnvan);

  const handleSelectChange = (event) => {
    const unvan = event.target.value;
    setSelectedUnvan(unvan);
    if (gunSayisi > 0) {
      handleCalculate(gunSayisi, unvan);
    }
  };

  const handleCalculate = (gun, unvan = selectedUnvan) => {
    const selected = data.find((item) => item.unvan === unvan);
    if (selected) {
      const aylikBrutTutar = selected.gunlukBrutTutar * gun;
      const damgaVergisi = aylikBrutTutar * 0.00759; // Damga vergisi oranı
      const netTutar = aylikBrutTutar - damgaVergisi;
      setResult({
        aylikBrutTutar: aylikBrutTutar.toFixed(2),
        damgaVergisi: damgaVergisi.toFixed(2),
        netTutar: netTutar.toFixed(2),
      });
    }
  };

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1
        className="text-center mb-4"
        style={{ fontSize: "2.2rem", fontWeight: "bold" }}
      >
        TAYİN (YEMEK) BEDELİ ÖDEMESİ
      </h1>
      <Form>
        {/* <Form.Group as={Row} className="mb-3" style={{ fontSize: "1.5rem" }}>
          <Form.Label column sm="6">
            Ünvan:
          </Form.Label>
          <Col sm="6">
            <Form.Control
              as="select"
              style={{ fontSize: "1.5rem" }}
              value={selectedUnvan}
              onChange={handleSelectChange}
            >
              <option value="">Seçiniz</option>
              {uniqueUnvanlar.map((unvan) => (
                <option key={unvan} value={unvan}>
                  {unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group> */}
        {selectedData && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
              Çalışma Gün Sayısı:
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="number"
                value={gunSayisi}
                max={31}
                min={0}
                onChange={handleGunSayisiChange}
                style={{ fontSize: "1.5rem" }}
              />
            </Col>
          </Form.Group>
        )}
      </Form>
      {result && (
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>Aylık Brüt Tutar</th>
              <th>Damga Vergisi</th>
              <th>Net Tutar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{result.aylikBrutTutar}</td>
              <td>{result.damgaVergisi}</td>
              <td>{result.netTutar}</td>
            </tr>
          </tbody>
        </Table>
      )}
      <TayinBedeliInfo />
    </Container>
  );
}

export default TayinBedeli;

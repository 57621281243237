import React from 'react';
import './IlkBasamakOdemeInfo.css';

const IlkBasamakOdemeInfo = () => {
    return (
        <div className="base-payment-container">
          <h2>TEMEL (TABAN) EK ÖDEME YAPILMASININ AMACI NEDİR?</h2>
          <p>
            Sağlık Bakanlığınca belirlenen hizmet sunum şartları ve hedefleri dikkate alınmak suretiyle personelin unvanı, görevi, disiplin durumu, çalışma şartları ve süresi, hizmete katkısı, verimliliği gibi unsurlar esas alınarak, il sağlık müdürlüğü ve il sağlık müdürlüğü birimlerinde görev yapan personele döner sermayeden yapılacak taban ödemenin oran, usul ve esaslarını belirlemektir.
          </p>
    
          <h2>TEMEL (TABAN) EK ÖDEME KİMLERİ KAPSAR?</h2>
          <p>
            İl sağlık müdürlüğü ve il sağlık müdürlüğü birimlerinde görevli olan memur ve sözleşmeli personeli, açıktan vekil olarak atananları, 4/11/1981 tarihli ve 2547 sayılı Yükseköğretim Kanununun 38 inci maddesine göre bu kuruluşlarda görevlendirilen personeli, diğer kamu kurum ve kuruluşlarının kadrolarında bulunan ve en az bir ay süreyle bu kuruluşlarda görevlendirilen sağlık personelini kapsar.
          </p>
    
          <h2>TEMEL (TABAN) EK ÖDEME RESMİ DAYANAĞI NEDİR?</h2>
          <p>
            12/08/2022 tarih ve 31921 sayılı Resmi Gazete’de yayımlanarak yürürlüğe giren Sağlık Bakanlığı Ek Ödeme Yönetmeliğinin 14 üncü maddesine dayanılarak hazırlanmıştır.
          </p>
    
          <h2>TEMEL (TABAN) EK ÖDEME ESASLARI NELERDİR?</h2>
          <p>
            *Taban ödeme; motivasyon ve ödüllendirme amacıyla personele yapılan ödemedir. Bu ödeme, personelin kadro unvanına göre Ek-2 sayılı tabloda belirlenen taban katsayısı ile en yüksek Devlet memuru aylığı ve aktif çalışma gün katsayısının çarpımı sonucu bulunan tutardır. Bu ödeme disiplin cezası alanlardan; uyarma ve kınama cezası alanlara bir ek ödeme dönemi, aylıktan kesme cezası alanlara iki ek ödeme dönemi, kademe ilerlemesi cezası alanlara üç ek ödeme dönemi süresince yapılmaz.
          </p>
    
          <h2>TEMEL (TABAN) EK ÖDEME HANGİ DURUMLARDA KESİNTİYE UĞRAMAZ?</h2>
          <ul>
            <li>Resmi tatil günlerinde,</li>
            <li>İdari ve nöbet izinlerinde,</li>
            <li>Yılda 7 günü geçmeyen kısa süreli hastalık rapor sürelerinde,</li>
            <li>Hakem hastane tarafından onaylanan heyet rapor sürelerinde,</li>
            <li>Yılda en fazla iki defa görevlendirme suretiyle kendi alanı ile ilgili yurt içi kongre, konferans, seminer ve sempozyum gibi etkinliklere katılan personelin bir yılda toplam 10 günü geçmeyen sürelerinde,</li>
            <li>Görevi sırasında veya görevinden dolayı Bakanlıkça ilan edilmiş bulaşıcı ve salgın hastalığa yakalanan, kazaya, yaralanmaya veya saldırıya uğrayanların bu durumlarını sağlık raporuyla belgelendirmesi halinde kullandıkları hastalık izin sürelerinde,</li>
            <li>7.Dönem Toplu Sözleşme kapsamında yılda toplam 12 gün yıllık izin süresince, çalışılmış gün olarak kabul edilir.</li>
          </ul>
    
          <h2>TEMEL (TABAN) EK ÖDEME NASIL HESAPLANIR?</h2>
          <p>
            Kurum hizmet hedefleri belirlenen birimlerde görev yapan personel için: Taban ödeme brüt tutarı = kurum hizmet katsayısı x personelin Ek-2 tabloda belirlenen taban katsayısı x en yüksek Devlet memuru aylığı x aktif çalışma gün katsayısı temel (taban) ek ödemenin brüt tutarından gelir vergisi ve damga vergisi kesilmek suretiyle temel (taban) ek ödeme net tutarı bulunur.
          </p>
    
          <h2>TEMEL (TABAN) EK ÖDEME HESAPLANIRKEN GELİR VERGİ DİLİMİNİ NASIL ÖĞRENEBİLİRİM?</h2>
          <p>
            *Gelir vergisi dilimini en son E-Bordro Maaş bilginizde yer alan Toplam Gelir Matrah tutarının aşağıdaki tabloda karşılık gelen vergi oranı olarak belirlenir.
          </p>
    
          <table>
            <thead>
              <tr>
                <th>Gelir Dilimi</th>
                <th>Vergi Oranı</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0-110.000 TL</td>
                <td>Yüzde 15</td>
              </tr>
              <tr>
                <td>110.000 TL - 230.000 TL</td>
                <td>Yüzde 20</td>
              </tr>
              <tr>
                <td>230.000 TL - 870.000 TL</td>
                <td>Yüzde 27</td>
              </tr>
              <tr>
                <td>870.000 TL - 3.000.000 TL</td>
                <td>Yüzde 35</td>
              </tr>
              <tr>
                <td>3.000.000 TL'den fazlası</td>
                <td>Yüzde 40</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
export default IlkBasamakOdemeInfo;

const Maas657EkGosterge = {
    "0": {
        "0": 0
    },
    "1": {
        "1": 1320,
        "2": 1380,
        "3": 1440,
        "4": 1500,
        "5": 0,
        "6": 0,
        "7": 0,
        "8": 0,
        "9": 0
    },
    "2": {
        "1": 1155,
        "2": 1210,
        "3": 1265,
        "4": 1320,
        "5": 1380,
        "6": 1440,
        "7": 0,
        "8": 0,
        "9": 0
    },
    "3": {
        "1": 1020,
        "2": 1065,
        "3": 1110,
        "4": 1155,
        "5": 1210,
        "6": 1265,
        "7": 1320,
        "8": 1380,
        "9": 0
    },
    "4": {
        "1": 915,
        "2": 950,
        "3": 985,
        "4": 1020,
        "5": 1065,
        "6": 1110,
        "7": 1155,
        "8": 1210,
        "9": 1265
    },
    "5": {
        "1": 835,
        "2": 865,
        "3": 895,
        "4": 915,
        "5": 950,
        "6": 985,
        "7": 1020,
        "8": 1065,
        "9": 1110
    },
    "6": {
        "1": 760,
        "2": 785,
        "3": 810,
        "4": 835,
        "5": 865,
        "6": 895,
        "7": 915,
        "8": 950,
        "9": 985
    },
    "7": {
        "1": 705,
        "2": 720,
        "3": 740,
        "4": 760,
        "5": 785,
        "6": 810,
        "7": 835,
        "8": 865,
        "9": 895
    },
    "8": {
        "1": 660,
        "2": 675,
        "3": 690,
        "4": 705,
        "5": 720,
        "6": 740,
        "7": 760,
        "8": 785,
        "9": 810
    },
    "9": {
        "1": 620,
        "2": 630,
        "3": 645,
        "4": 660,
        "5": 675,
        "6": 690,
        "7": 705,
        "8": 720,
        "9": 740
    },
    "10": {
        "1": 590,
        "2": 600,
        "3": 610,
        "4": 620,
        "5": 630,
        "6": 645,
        "7": 660,
        "8": 675,
        "9": 690
    },
    "11": {
        "1": 560,
        "2": 570,
        "3": 580,
        "4": 590,
        "5": 600,
        "6": 610,
        "7": 620,
        "8": 630,
        "9": 645
    },
    "12": {
        "1": 545,
        "2": 550,
        "3": 555,
        "4": 560,
        "5": 570,
        "6": 580,
        "7": 590,
        "8": 600,
        "9": 610
    },
    "13": {
        "1": 530,
        "2": 535,
        "3": 540,
        "4": 545,
        "5": 550,
        "6": 555,
        "7": 560,
        "8": 570,
        "9": 580
    },
    "14": {
        "1": 515,
        "2": 520,
        "3": 525,
        "4": 530,
        "5": 535,
        "6": 540,
        "7": 545,
        "8": 550,
        "9": 555
    },
    "15": {
        "1": 500,
        "2": 505,
        "3": 510,
        "4": 515,
        "5": 520,
        "6": 525,
        "7": 530,
        "8": 535,
        "9": 540
    }
};
export default Maas657EkGosterge;
import React, { useState } from "react";
import { Container, Row, Col, Form, Alert, Button} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom"; 
import TabanUniversiteInfo from "./TabanUniversiteInfo";

const data = [
  {
    Unvani: "ASİSTAN (TABİP)",
    UnvanOdemeyeEsas: "ASİSTAN /TEMEL TIP BİLİMLERİ",
    TabanKatsayisi: 3.0,
    BrutTutar: 25872.19,
  },
  {
    Unvani: "ASİSTAN (TABİP)",
    UnvanOdemeyeEsas: "ASİSTAN",
    TabanKatsayisi: 3.4,
    BrutTutar: 29321.81,
  },
  {
    Unvani: "ASİSTAN (TABİP)",
    UnvanOdemeyeEsas: "ASİSTAN (TABİP KADROSUNDA)",
    TabanKatsayisi: 3.4,
    BrutTutar: 29321.81,
  },
  {
    Unvani: "ASİSTAN (YAN DAL)",
    UnvanOdemeyeEsas: "ASİSTAN (YANDAL)",
    TabanKatsayisi: 6.75,
    BrutTutar: 58212.42,
  },
  {
    Unvani: "BAŞASİSTAN",
    UnvanOdemeyeEsas: "BAŞASİSTAN",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "BAŞASİSTAN",
    UnvanOdemeyeEsas: "DOÇENT",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "BAŞASİSTAN YAN DAL",
    UnvanOdemeyeEsas: "DOÇENT",
    TabanKatsayisi: 9.0,
    BrutTutar: 77616.56,
  },
  {
    Unvani: "BİLGİSAYAR İŞLETMENİ",
    UnvanOdemeyeEsas: "ORTA ÖĞRETİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "BİYOLOG",
    UnvanOdemeyeEsas: "BİYOLOG",
    TabanKatsayisi: 0.32,
    BrutTutar: 2759.7,
  },
  {
    Unvani: "BÜRO PERSONELİ",
    UnvanOdemeyeEsas: "YÜKSEK ÖĞRENİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "ÇOCUK GELİŞİMCİSİ",
    UnvanOdemeyeEsas: "ÇOCUK GELİŞİMCİSİ",
    TabanKatsayisi: 0.32,
    BrutTutar: 2759.7,
  },
  {
    Unvani: "DİĞER SAĞLIK PERSONELİ",
    UnvanOdemeyeEsas: "LİSANS",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "DİL VE KONUŞMA TERAPİSTİ",
    UnvanOdemeyeEsas: "DİL VE KONUŞMA TERAPİSTİ",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "DİYETİSYEN",
    UnvanOdemeyeEsas: "DİYETİSYEN",
    TabanKatsayisi: 0.32,
    BrutTutar: 2759.7,
  },
  {
    Unvani: "DOÇENT",
    UnvanOdemeyeEsas: "DOÇENT",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "DOÇENT YAN DAL",
    UnvanOdemeyeEsas: "DOÇENT",
    TabanKatsayisi: 9.0,
    BrutTutar: 77616.56,
  },
  {
    Unvani: "DOKTOR ÖĞRETİM ÜYESİ",
    UnvanOdemeyeEsas: "DOKTOR ÖĞRETİM ÜYESİ",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "DOKTOR ÖĞRETİM ÜYESİ YAN DAL",
    UnvanOdemeyeEsas: "DOKTOR ÖĞRETİM ÜYESİ",
    TabanKatsayisi: 9.0,
    BrutTutar: 77616.56,
  },
  {
    Unvani: "EBE",
    UnvanOdemeyeEsas: "LİSANS",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "EBE",
    UnvanOdemeyeEsas: "ÖNLİSANS",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "EĞİTİM GÖREVLİSİ",
    UnvanOdemeyeEsas: "DOÇENT",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "FİZYOTERAPİST",
    UnvanOdemeyeEsas: "FİZYOTERAPİST",
    TabanKatsayisi: 0.32,
    BrutTutar: 2759.7,
  },
  {
    Unvani: "HEMŞİRE",
    UnvanOdemeyeEsas: "LİSANS",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "HİZMETLİ",
    UnvanOdemeyeEsas: "HİZMETLİ",
    TabanKatsayisi: 0.12,
    BrutTutar: 1034.89,
  },
  {
    Unvani: "İDARİ BÜRO GÖREVLİSİ",
    UnvanOdemeyeEsas: "YÜKSEK ÖĞRENİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "İŞ VE UĞRAŞI TERAPİSTİ",
    UnvanOdemeyeEsas: "İŞ VE UĞRAŞI TERAPİSTİ",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "LABORANT",
    UnvanOdemeyeEsas: "LİSANS",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "LABORANT",
    UnvanOdemeyeEsas: "ÖNLİSANS",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "MEMUR",
    UnvanOdemeyeEsas: "ORTA ÖĞRETİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "MEMUR",
    UnvanOdemeyeEsas: "ORTA ÖĞRETİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "MEMUR",
    UnvanOdemeyeEsas: "YÜKSEK ÖĞRENİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "MEMUR",
    UnvanOdemeyeEsas: "ORTA ÖĞRETİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "MUHASEBECİ",
    UnvanOdemeyeEsas: "ORTA ÖĞRETİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "MÜHENDİS",
    UnvanOdemeyeEsas: "MÜHENDİS (5 YIL VE FAZLASI)",
    TabanKatsayisi: 0.32,
    BrutTutar: 2759.7,
  },
  {
    Unvani: "MÜHENDİS",
    UnvanOdemeyeEsas: "MÜHENDİS (5 YILA KADAR)",
    TabanKatsayisi: 0.32,
    BrutTutar: 2759.7,
  },
  {
    Unvani: "ODYOLOG",
    UnvanOdemeyeEsas: "ODYOLOG",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "PERFÜZYONİST",
    UnvanOdemeyeEsas: "PERFÜZYONİST",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "PROFESÖR",
    UnvanOdemeyeEsas: "PROFESÖR (3 YILINI TAMAMLAYAN)",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "PROFESÖR",
    UnvanOdemeyeEsas: "PROFESÖR (DİĞER)",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "PROFESÖR",
    UnvanOdemeyeEsas: "PROFESÖR (4 YILINI TAMAMLAYAN)",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "PROFESÖR YAN DAL",
    UnvanOdemeyeEsas: "PROFESÖR (DİĞER)",
    TabanKatsayisi: 9.0,
    BrutTutar: 77616.56,
  },
  {
    Unvani: "PROGRAMCI",
    UnvanOdemeyeEsas: "YÜKSEK ÖĞRENİM (5 YILA KADAR)",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "PSİKOLOG",
    UnvanOdemeyeEsas: "PSİKOLOG",
    TabanKatsayisi: 0.32,
    BrutTutar: 2759.7,
  },
  {
    Unvani: "SAĞLIK FİZİKÇİSİ",
    UnvanOdemeyeEsas: "SAĞLIK FİZİKÇİSİ",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "SAĞLIK FİZİKÇİSİ",
    UnvanOdemeyeEsas: "SAĞLIK FİZİKÇİSİ",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "SAĞLIK FİZİKÇİSİ",
    UnvanOdemeyeEsas: "SAĞLIK FİZİKÇİSİ",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "SAĞLIK MEMURU",
    UnvanOdemeyeEsas: "LİSANS",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "SOSYAL ÇALIŞMACI",
    UnvanOdemeyeEsas: "LİSANS",
    TabanKatsayisi: 0.32,
    BrutTutar: 2759.7,
  },
  {
    Unvani: "ŞEF",
    UnvanOdemeyeEsas: "YÜKSEK ÖĞRENİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "ŞOFÖR",
    UnvanOdemeyeEsas: "DİĞER ÖĞRENİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "TABİP",
    UnvanOdemeyeEsas: "TABİP",
    TabanKatsayisi: 2.26,
    BrutTutar: 19490.38,
  },
  {
    Unvani: "TEKNİKER",
    UnvanOdemeyeEsas: "LİSANS (5 YIL VE FAZLASI)",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "TEKNİKER",
    UnvanOdemeyeEsas: "YÜKSEK ÖĞRENİM (5 YILA KADAR)",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "TEKNİSYEN",
    UnvanOdemeyeEsas: "YÜKSEK ÖĞRENİM (5 YILDAN FAZLA HİZMETİ OLAN)",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "TEKNİSYEN",
    UnvanOdemeyeEsas: "ORTA ÖĞRENİM (5 YIL VE FAZLA HİZMETİ OLAN)",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "TEKNİSYEN YARDIMCISI",
    UnvanOdemeyeEsas: "TEKNİSYEN YARDIMCISI",
    TabanKatsayisi: 0.12,
    BrutTutar: 1034.89,
  },
  {
    Unvani: "TERZİ",
    UnvanOdemeyeEsas: "TERZİ",
    TabanKatsayisi: 0.12,
    BrutTutar: 1034.89,
  },
  {
    Unvani: "TIBBİ TEKNOLOG",
    UnvanOdemeyeEsas: "TIBBİ TEKNOLOG",
    TabanKatsayisi: 0.37,
    BrutTutar: 3190.9,
  },
  {
    Unvani: "UZMAN ECZACI",
    UnvanOdemeyeEsas: "UZMAN ECZACI",
    TabanKatsayisi: 1.2,
    BrutTutar: 10348.87,
  },
  {
    Unvani: "UZMAN TABİP",
    UnvanOdemeyeEsas: "UZMAN TABİP",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },
  {
    Unvani: "UZMAN TABİP",
    UnvanOdemeyeEsas: "DOÇENT",
    TabanKatsayisi: 4.5,
    BrutTutar: 38808.28,
  },

  {
    Unvani: "UZMAN TABİP YAN DAL",
    UnvanOdemeyeEsas: "DOÇENT",
    TabanKatsayisi: 9.0,
    BrutTutar: 77616.56,
  },
  {
    Unvani: "UZMAN TABİP YAN DAL",
    UnvanOdemeyeEsas: "UZMAN TABİP YAN DAL",
    TabanKatsayisi: 9.0,
    BrutTutar: 77616.56,
  },
  {
    Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
    UnvanOdemeyeEsas: "ORTA ÖĞRETİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
  {
    Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
    UnvanOdemeyeEsas: "YÜKSEK ÖĞRENİM",
    TabanKatsayisi: 0.16,
    BrutTutar: 1379.85,
  },
];

function BasamakOdeme() {
  const [selectedUnvan, setSelectedUnvan] = useState("");
  const [selectedUnvanOdemeyeEsas, setSelectedUnvanOdemeyeEsas] = useState("");
  const [calismaGun, setCalismaGun] = useState(30);
  const [toplamGun, setToplamGun] = useState(30);
  const [vergiDilimi, setVergiDilimi] = useState(0.15);
  const navigate = useNavigate();
  const uniqueUnvanlar = Array.from(new Set(data.map((item) => item.Unvani)));
  const filteredUnvanOdemeyeEsas = data.filter(
    (item) => item.Unvani === selectedUnvan
  );
  const selectedData = data.find(
    (item) =>
      item.Unvani === selectedUnvan &&
      item.UnvanOdemeyeEsas === selectedUnvanOdemeyeEsas
  );

  const hesaplaKatsayi = () => {
    if (selectedData && calismaGun > 0 && toplamGun > 0) {
      const k = 8624.06;
      const BrutTutar = k * selectedData.TabanKatsayisi;
      const calisilanBrut = (BrutTutar / toplamGun) * calismaGun;
      const vergidilimiBrut = calisilanBrut * vergiDilimi;
      const damgaVergisi = calisilanBrut * 0.00759;
      const netTutar = calisilanBrut - vergidilimiBrut - damgaVergisi;
      return netTutar;
    }
    return 0;
  };
  const calismaGunSet = (gun) => {
    if (gun > 31) {
      setCalismaGun(31);
    } else if (gun < 0) {
      setCalismaGun(0);
    } else {
      setCalismaGun(gun);
    }
  }
  const toplamGunSet = (gun) => {
    if (gun > 31) {
      setToplamGun(31);
    } else if (gun < 0) {
      setToplamGun(0);
    } else {
      setToplamGun(gun);
    }
  }

  return (
    <Container className="BasamakOdeme mt-5">
            <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1
        className="text-center mb-4"
        style={{ fontSize: "2.2rem", fontWeight: "bold" }}
      >
       HASTANELER TABAN TUTARLARI
      </h1>
      <Form>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1.5rem" }}>
          <Form.Label column sm="6">
            Ünvan:
          </Form.Label>
          <Col sm="6">
            <Form.Control
              as="select"
              style={{ fontSize: "1.5rem" }}
              value={selectedUnvan}
              onChange={(e) => setSelectedUnvan(e.target.value)}
            >
              <option value="">Seçiniz</option>
              {uniqueUnvanlar.map((unvan) => (
                <option key={unvan} value={unvan}>
                  {unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {selectedUnvan && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
              Ödeme Esas:
            </Form.Label>
            <Col sm="6">
              <Form.Control
                as="select"
                style={{ fontSize: "1.5rem" }}
                value={selectedUnvanOdemeyeEsas}
                onChange={(e) => setSelectedUnvanOdemeyeEsas(e.target.value)}
              >
                <option value="">Seçiniz</option>
                {filteredUnvanOdemeyeEsas.map((item) => (
                  <option
                    key={item.UnvanOdemeyeEsas}
                    value={item.UnvanOdemeyeEsas}
                  >
                    {item.UnvanOdemeyeEsas}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedData && (
          <>
            <Form.Group as={Row} className="mb-3 align-items-center" >
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Taban Katsayısı:
              </Form.Label>
              <Form.Label column sm="6"  style={{ fontSize: "1.5rem", backgroundColor:"red", maxWidth:"25%", marginLeft:"12.5%", color:"white" }}>
                {selectedData.TabanKatsayisi}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Çalışma Gün Sayısı:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="number"
                  value={calismaGun}
                  max={31}
                  min={0}
                  onChange={(e) => calismaGunSet(e.target.value)}
                  style={{ fontSize: "1.5rem" }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                column
                sm="6"
                className="basamak-yazi"
                style={{ fontSize: "1.5rem" }}
              >
                Aydaki Toplam Gün Sayısı:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="number"
                  value={toplamGun}
                  max={31}
                  min={0}
                  onChange={(e) => toplamGunSet(e.target.value)}
                  style={{ fontSize: "1.5rem" }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Vergi Dilimi:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  as="select"
                  value={vergiDilimi}
                  onChange={(e) => setVergiDilimi(Number(e.target.value))}
                  style={{ fontSize: "1.5rem" }}
                >
                  <option value={0.15}>%15</option>
                  <option value={0.2}>%20</option>
                  <option value={0.27}>%27</option>
                  <option value={0.35}>%35</option>
                  <option value={0.4}>%40</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </>
        )}

        {selectedData && calismaGun > 0 && toplamGun > 0 && (
          <Alert variant="success" className="basamak-sonuc">
            <h4
              className="basamak-yazi"
              style={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              ÖDENECEK NET TUTAR:{" "}
              {hesaplaKatsayi().toFixed(2).toLocaleString(undefined)} ₺
            </h4>
          </Alert>
        )}
      </Form>
          <TabanUniversiteInfo></TabanUniversiteInfo>
    </Container>
  );
}

export default BasamakOdeme;

import React, { useState } from 'react';
import { Container, Row, Col, Form, Table , Alert,Button} from 'react-bootstrap';
import TavanIkinciInfo from './TavanIkinciInfo';
import { useNavigate } from "react-router-dom"; 
const data = [
  {
    unvan: "DOÇENT-PROFESÖR YANDAL",
    tavanBrutTutar: 18048,
    tavanKatsayisi: 11.50,
    tavanBrutTutar2: 18048,
    haziranTavanTutari: 207548,
    temmuzTavanTutari: 247625
  },
  {
    unvan: "UZMAN TABİP YANDAL",
    tavanBrutTutar: 18048,
    tavanKatsayisi: 10.50,
    tavanBrutTutar2: 18048,
    haziranTavanTutari: 189500,
    temmuzTavanTutari: 226092
  },
  {
    unvan: "DOÇENT-PROFESÖR",
    tavanBrutTutar: 24779,
    tavanKatsayisi: 9.50,
    tavanBrutTutar2: 24779,
    haziranTavanTutari: 235401,
    temmuzTavanTutari: 280857
  },
  {
    unvan: "UZMAN TABİP",
    tavanBrutTutar: 18502,
    tavanKatsayisi: 9.50,
    tavanBrutTutar2: 18502,
    haziranTavanTutari: 175767,
    temmuzTavanTutari: 209708
  },
  {
    unvan: "ASİSTAN (TABİP YANDAL)",
    tavanBrutTutar: 18048,
    tavanKatsayisi: 8.50,
    tavanBrutTutar2: 18048,
    haziranTavanTutari: 153405,
    temmuzTavanTutari: 183027
  },
  {
    unvan: "ASİSTAN (TABİP)",
    tavanBrutTutar: 15445,
    tavanKatsayisi: 6.50,
    tavanBrutTutar2: 15445,
    haziranTavanTutari: 100395,
    temmuzTavanTutari: 119781
  },
  {
    unvan: "TABİP",
    tavanBrutTutar: 15421,
    tavanKatsayisi: 6.50,
    tavanBrutTutar2: 15421,
    haziranTavanTutari: 100238,
    temmuzTavanTutari: 119594
  },
  {
    unvan: "ECZACI",
    tavanBrutTutar: 12622,
    tavanKatsayisi: 3.45,
    tavanBrutTutar2: 12622,
    haziranTavanTutari: 43545,
    temmuzTavanTutari: 51953
  },
  {
    unvan: "HEMŞİRE-EBE-SAĞLIK TEKNİKERİ(112-RİSKLİ BİRİM)",
    tavanBrutTutar: 11302,
    tavanKatsayisi: 2.70,
    tavanBrutTutar2: 11302,
    haziranTavanTutari: 30516,
    temmuzTavanTutari: 36408
  },
  {
    unvan: "HEMŞİRE-EBE-SAĞLIK TEKNİKERİ",
    tavanBrutTutar: 11302,
    tavanKatsayisi: 2.35,
    tavanBrutTutar2: 11302,
    haziranTavanTutari: 26560,
    temmuzTavanTutari: 31689
  },
  {
    unvan: "BİYOLOG-DİYETİSYEN",
    tavanBrutTutar: 11667,
    tavanKatsayisi: 2.35,
    tavanBrutTutar2: 11667,
    haziranTavanTutari: 27418,
    temmuzTavanTutari: 32713
  },
  {
    unvan: "MÜHENDİS(5 YIL FAZLASI)",
    tavanBrutTutar: 16433,
    tavanKatsayisi: 2.35,
    tavanBrutTutar2: 16433,
    haziranTavanTutari: 38618,
    temmuzTavanTutari: 46075
  },
  {
    unvan: "ŞOFÖR",
    tavanBrutTutar: 6052,
    tavanKatsayisi: 2.35,
    tavanBrutTutar2: 6052,
    haziranTavanTutari: 14221,
    temmuzTavanTutari: 16967
  },
  {
    unvan: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
    tavanBrutTutar: 7790,
    tavanKatsayisi: 2.35,
    tavanBrutTutar2: 7790,
    haziranTavanTutari: 18307,
    temmuzTavanTutari: 21842
  },
  {
    unvan: "HİZMETLİ",
    tavanBrutTutar: 5044,
    tavanKatsayisi: 2.35,
    tavanBrutTutar2: 5044,
    haziranTavanTutari: 11852,
    temmuzTavanTutari: 14141
  }
];


function TavanIkinci() {
  const [selectedUnvan, setSelectedUnvan] = useState(data[0]);
  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    const selected = data.find(item => item.unvan === event.target.value);
    setSelectedUnvan(selected);
  };

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1
        className="text-center mb-4"
        style={{ fontSize: "2.2rem", fontWeight: "bold" }}
      >
        İL SAĞLIK MÜDÜRLÜĞÜ TAVAN EK ÖDEME HESAPLAMASI
      </h1>
      <Form>
        <Form.Group
          as={Row}
          className="mb-3 border"
          style={{ fontSize: "1.5rem" }}
        >
          <Form.Label column sm={6}>
            ÜNVAN:
          </Form.Label>
          <Col sm={6}>
            <Form.Control
              as="select"
              style={{ fontSize: "1.5rem" }}
              onChange={handleSelectChange}
            >
              {data.map((item, index) => (
                <option key={index} value={item.unvan}>
                  {item.unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} className="mb-3 border align-items-center">
          <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
            TAVAN BRÜT TUTAR:
          </Form.Label>
          <Form.Label
            column
            sm="6"
            style={{ fontSize: "1.5rem", maxWidth: "25%", marginLeft: "12.5%" }}
          >
            {selectedUnvan.tavanBrutTutar.toFixed(2).toLocaleString(undefined)}
          </Form.Label>
        </Form.Group> */}
        <Form.Group as={Row} className="mb-3 border align-items-center">
          <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
            TAVAN KATSAYISI:
          </Form.Label>
          <Form.Label
            column
            sm="6"
            style={{ fontSize: "1.5rem", maxWidth: "25%", marginLeft: "12.5%" }}
          >
            {selectedUnvan.tavanKatsayisi.toFixed(2).toLocaleString(undefined)}
          </Form.Label>
        </Form.Group>
        <Form.Group as={Row} className="mb-3 border align-items-center">
          <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
            TAVAN BRÜT TUTAR 2:
          </Form.Label>
          <Form.Label
            column
            sm="6"
            style={{ fontSize: "1.5rem", maxWidth: "25%", marginLeft: "12.5%" }}
          >
            {selectedUnvan.tavanBrutTutar2.toFixed(2).toLocaleString(undefined)}
          </Form.Label>
        </Form.Group>
        {/* <Form.Group as={Row} className="mb-3 border align-items-center" >
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
              HAZİRAN TAVAN TUTARI:
              </Form.Label>
              <Form.Label column sm="6"  style={{ fontSize: "1.5rem", maxWidth:"25%", marginLeft:"12.5%" }}>
                {selectedUnvan.haziranTavanTutari.toFixed(2).toLocaleString(undefined)} 
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 border align-items-center" >
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
              TEMMUZ TAVAN TUTARI:
              </Form.Label>
              <Form.Label column sm="6"  style={{ fontSize: "1.5rem", maxWidth:"25%", marginLeft:"12.5%" }}>
                {selectedUnvan.temmuzTavanTutari.toFixed(2).toLocaleString(undefined)} 
              </Form.Label>
            </Form.Group> */}
      </Form>

      <TavanIkinciInfo></TavanIkinciInfo>
    </Container>
  );
}

export default TavanIkinci;

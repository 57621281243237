import React, { useState } from "react";
import { Container, Row, Col, Form, Alert, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import TesvikBirinciInfo from "./TesvikBirinciInfo";
import { useNavigate } from "react-router-dom"; 

const data = [
  {
    Unvani: "İ.S.M. SAĞLIK PERSONELİ",
    tekoksEk2: 0.37,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.8,
  },
  {
    Unvani: "112 SAĞLIK PERSONELİ",
    tekoksEk2: 0.518,
    eydma: 8624.062,
    netTesvikKatsayisi: 1,
  },
  {
    Unvani: "112 DOKTOR",
    tekoksEk2: 2.486,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.9,
  },
  {
    Unvani: "GENEL İDARİ HİZMETLER",
    tekoksEk2: 0.16,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.8,
  },
  {
    Unvani: "TEKNİK HİZMETLER",
    tekoksEk2: 0.16,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.8,
  },
  {
    Unvani: "İSM UZMAN TABİP",
    tekoksEk2: 4.5,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.7,
  },
  {
    Unvani: "İSM TABİP",
    tekoksEk2: 2.26,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.7,
  },
  {
    Unvani: "UZMAN DİŞ TABİBİ",
    tekoksEk2: 4.5,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.7,
  },
  {
    Unvani: "YARDIMCI HİZMETLER SINIFI",
    tekoksEk2: 0.12,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.8,
  },
  {
    Unvani: "TSM SORUMLU TABİP",
    tekoksEk2: 2.26,
    eydma: 8624.062,
    netTesvikKatsayisi: 0.7,
  },
];

function TesvikBirinci() {
  const [selectedUnvan, setSelectedUnvan] = useState("");
  const [calismaGun, setCalismaGun] = useState(30);
  const [toplamGun, setToplamGun] = useState(30);
  const [vergiDilimi, setVergiDilimi] = useState(0.15);

  const uniqueUnvanlar = Array.from(new Set(data.map((item) => item.Unvani)));
  const navigate = useNavigate();
  const selectedData = data.find((item) => item.Unvani === selectedUnvan);

  const hesaplaKatsayi = () => {
    if (selectedData && calismaGun > 0 && toplamGun > 0) {
      const toplam =
        selectedData.tekoksEk2 *
        selectedData.eydma *
        selectedData.netTesvikKatsayisi;
      const gunOran = (toplam / toplamGun) * calismaGun;
      const gelirVergisi = gunOran * vergiDilimi;
      const damgaVergisi = gunOran * 0.00759;

      const netTutar = gunOran - gelirVergisi - damgaVergisi;
      return netTutar;
    }
    return 0;
  };
  const calismaGunSet = (gun) => {
    if (gun > 31) {
      setCalismaGun(31);
    } else if (gun < 0) {
      setCalismaGun(0);
    } else {
      setCalismaGun(gun);
    }
  };
  const toplamGunSet = (gun) => {
    if (gun > 31) {
      setToplamGun(31);
    } else if (gun < 0) {
      setToplamGun(0);
    } else {
      setToplamGun(gun);
    }
  };

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1
        className="text-center mb-4"
        style={{ fontSize: "2.2rem", fontWeight: "bold" }}
      >
        1. BASAMAK TEŞVİK TUTARLARI
      </h1>
      <Form>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1.5rem" }}>
          <Form.Label column sm="6">
            Ünvan:
          </Form.Label>
          <Col sm="6">
            <Form.Control
              as="select"
              style={{ fontSize: "1.5rem" }}
              value={selectedUnvan}
              onChange={(e) => setSelectedUnvan(e.target.value)}
            >
              <option value="">Seçiniz</option>
              {uniqueUnvanlar.map((unvan) => (
                <option key={unvan} value={unvan}>
                  {unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {selectedData && (
          <>
            <Form.Group as={Row} className="mb-3 align-items-center">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Teşvik Katsayısı:
              </Form.Label>
              <Form.Label
                column
                sm="6"
                style={{
                  fontSize: "1.5rem",
                  backgroundColor: "red",
                  maxWidth: "25%",
                  marginLeft: "12.5%",
                  color: "white",
                }}
              >
                {selectedData.netTesvikKatsayisi}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Ek Ödeme Katsayısı:
              </Form.Label>
              <Form.Label
                column
                sm="6"
                style={{
                  fontSize: "1.5rem",
                  backgroundColor: "red",
                  maxWidth: "25%",
                  marginLeft: "12.5%",
                  color: "white",
                }}
              >
                {selectedData.tekoksEk2}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Çalışma Gün Sayısı:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="number"
                  value={calismaGun}
                  max={31}
                  min={0}
                  onChange={(e) => calismaGunSet(e.target.value)}
                  style={{ fontSize: "1.5rem" }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                column
                sm="6"
                className="basamak-yazi"
                style={{ fontSize: "1.5rem" }}
              >
                Aydaki Toplam Gün Sayısı:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="number"
                  value={toplamGun}
                  max={31}
                  min={0}
                  onChange={(e) => toplamGunSet(e.target.value)}
                  style={{ fontSize: "1.5rem" }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Vergi Dilimi:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  as="select"
                  value={vergiDilimi}
                  onChange={(e) => setVergiDilimi(Number(e.target.value))}
                  style={{ fontSize: "1.5rem" }}
                >
                  <option value={0.15}>%15</option>
                  <option value={0.2}>%20</option>
                  <option value={0.27}>%27</option>
                  <option value={0.35}>%35</option>
                  <option value={0.4}>%40</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </>
        )}

        {selectedData && calismaGun > 0 && toplamGun > 0 && (
          <Alert variant="success" className="basamak-sonuc">
            <h4
              className="basamak-yazi"
              style={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              ÖDENECEK NET TUTAR:{" "}
              {hesaplaKatsayi().toFixed(2).toLocaleString(undefined)} ₺
            </h4>
          </Alert>
        )}
      </Form>
      <TesvikBirinciInfo></TesvikBirinciInfo>
    </Container>
  );
}

export default TesvikBirinci;

export const data = [
    {
      Unvani: "BİLGİSAYAR İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "BİLGİSAYAR İŞLETMENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "BİLGİSAYAR İŞLETMENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 7492.4890350000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17607.349232250002,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13920.219837,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 32712.51661695,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13974.684852,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 32840.509402200005,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 14029.149867,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 32968.50218745,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 14194.692492,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 33357.5273562,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 13171.287105,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30952.52469675,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 13221.21834,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31069.863099,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 13271.149575000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31187.201501250005,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 12363.343647000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29053.857570450004,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11746.045638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27603.2072493,
    },
    {
      Unvani: "BİYOLOG",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10674.856596,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25085.9130006,
    },
    {
      Unvani: "BÜRO PERSONELİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 1,
      EkOdemeMatrahi: 4846.300614000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11388.806442900002,
    },
    {
      Unvani: "ÇOCUK GELİŞİMCİSİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "ÇOCUK GELİŞİMCİSİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "ÇOCUK GELİŞİMCİSİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "ÇOCUK GELİŞİMCİSİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "ÇOCUK GELİŞİMCİSİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "ÇOCUK GELİŞİMCİSİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "ÇOCUK GELİŞİMCİSİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "DİĞER SAĞLIK PERSONELİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "DİĞER SAĞLIK PERSONELİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "DİL VE KONUŞMA TERAPİSTİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "DİL VE KONUŞMA TERAPİSTİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "DİL VE KONUŞMA TERAPİSTİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "DİL VE KONUŞMA TERAPİSTİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "DİL VE KONUŞMA TERAPİSTİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "DİŞ PROTEZ TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.5366,
      TavanTutari: 33790.2233184714,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.5366,
      TavanTutari: 32028.666771703804,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.5366,
      TavanTutari: 29979.237396141,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.5366,
      TavanTutari: 27469.3083398646,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.56,
      TavanTutari: 27164.928407040003,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "DİYETİSYEN",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 9837.216879000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23117.459665650003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34336.937180700006,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29885.85273135,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29885.85273135,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 35778.3597860715,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13979.230563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 37743.9225201,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13979.230563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 32851.19182305,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 14033.695577999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 32979.1846083,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 9260.782545,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 21762.838980750003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 25433.038287000007,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12122.790825,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32731.5352275,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12122.790825,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32731.5352275,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 33634.6750654824,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 8847.719394,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20792.1405759,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30170.1595131,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30170.1595131,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26451.216106350006,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.3616,
      TavanTutari: 27910.970210016003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 8534.530644,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23043.2327388,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11019.805668,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29753.475303600004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11019.805668,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25896.543319800003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11019.805668,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29753.475303600004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11019.805668,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25896.543319800003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11051.577921,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25971.20811435,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11051.577921,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29839.260386700003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11051.577921,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25971.20811435,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11051.577921,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29839.260386700003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25885.8889368,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.4083,
      TavanTutari: 25555.194172919702,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 7023.06384,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18962.272368,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 8682.689802,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20404.321034700002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26897.988508200007,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "EBE",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 6941.360352000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18741.672950400003,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 15333.315546,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 52899.9386337,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 15387.780561,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 53087.842935450004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 15387.780561,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 53087.842935450004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 15387.780561,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 53087.842935450004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 14364.375174000003,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 49557.09435030001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 14464.237644,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 49901.61987180001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 14529.917799,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 50128.216406550004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 14529.917799,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 50128.216406550004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 14579.849034,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 50300.47916730001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 14579.849034,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 50300.47916730001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 14629.780269,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 50472.74192805,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 13556.431716000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 46769.689420200004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 13721.974341000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 47340.811476450006,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 13721.974341000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 47340.811476450006,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 13762.826085,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 47481.74999325001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 13762.826085,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 47481.74999325001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 13072.904079,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 45101.51907255,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 13104.676332,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 45211.1333454,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 13104.676332,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 45211.1333454,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 12212.905668000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 42134.52455460001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 12212.905668000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 42134.52455460001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 12351.209820000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 42611.67387900001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 12351.209820000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 42611.67387900001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 12351.209820000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 42611.67387900001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 12378.448293000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 42705.64661085001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 11981.420406000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41335.900400700004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 12004.113168000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41414.19042960001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 12026.80593,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41492.4804585,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 12146.963031000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41907.022456950006,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 12192.348555,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 42063.602514750004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 12192.348555,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 42063.602514750004,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 11915.477769000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41108.39830305,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 11915.477769000001,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41108.39830305,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 11929.09104,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41155.364088,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11947.250022000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41218.01257590001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11947.250022000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41218.01257590001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11947.250022000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41218.01257590001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11947.250022000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41218.01257590001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11947.250022000002,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 41218.01257590001,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 11645.538894,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 40177.1091843,
    },
    {
      Unvani: "ECZACI",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 11811.081519,
      TEKSOdemeyeEsas: 3.45,
      TavanTutari: 40748.23124055,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29741.2340976,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "FİZYOTERAPİST",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "GASSAL",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 6020.597358000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14148.403791300003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 11198.91384,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26317.447524,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 11198.91384,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30237.067368,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29885.85273135,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29885.85273135,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29885.85273135,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29885.85273135,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 12771.861087000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30013.873554450005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 12771.861087000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30013.873554450005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 12771.861087000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34484.02493490001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 12826.326102,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34631.080475400006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13168.399803,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30945.73953705,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 35633.847761772005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 35778.3597860715,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 35778.3597860715,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 35778.3597860715,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13979.230563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 37743.9225201,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13979.230563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 32851.19182305,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 14088.160593,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 33107.17739355,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 14088.160593,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 33107.17739355,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 14088.160593,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 37380.116501406905,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 14088.160593,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 33107.17739355,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 14088.160593,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 33107.17739355,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 16983.754638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 45856.1375226,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 25433.038287000007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 25433.038287000007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32461.906558500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32461.906558500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32461.906558500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32461.906558500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28371.220036500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28371.220036500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28371.220036500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28371.220036500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12122.790825,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28488.558438750002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12122.790825,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32731.5352275,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.455,
      TavanTutari: 30998.335143315006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 13230.297831000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35721.80414370001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 13230.297831000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35721.80414370001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 13230.297831000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35721.80414370001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 13230.297831000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31091.199902850007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 13280.229066000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31208.538305100006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 13330.160301,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31325.876707350002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 8766.015906,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23668.242946200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30170.1595131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30170.1595131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26259.21290955,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26355.214507950004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26451.216106350006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30390.758930700005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30390.758930700005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30390.758930700005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26451.216106350006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30390.758930700005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11597.910342000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31314.357923400006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.5716,
      TavanTutari: 30287.836488495603,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 31250.084210190304,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 31466.868074900704,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 31466.868074900704,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 31466.868074900704,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 31466.868074900704,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 12422.354373,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 33540.356807100005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 12422.354373,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 33540.356807100005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 12463.206117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 33650.6565159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 12463.206117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 33650.6565159,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 8534.530644,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20056.1470134,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 8598.07515,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20205.476602500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11019.805668,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25896.543319800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11051.577921,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29839.260386700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11051.577921,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29839.260386700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11051.577921,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29839.260386700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 30840.607094239804,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 12227.175144,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28733.861588400003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 7181.925105,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 19391.197783500003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 7209.151647000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16941.506370450003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 7209.151647000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 19464.709446900004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24605.876970750003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28270.582051500005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28270.582051500005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28270.582051500005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28270.582051500005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28270.582051500005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24605.876970750003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24605.876970750003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24605.876970750003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 10497.824418000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24669.887382300007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 10497.824418000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28344.125928600006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 10497.824418000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24669.887382300007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 10497.824418000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28344.125928600006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 10525.05096,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28417.637592000003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 10525.05096,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28417.637592000003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 10525.05096,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28417.637592000003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25885.8889368,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29741.2340976,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25885.8889368,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29741.2340976,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25885.8889368,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29741.2340976,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29741.2340976,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29741.2340976,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25885.8889368,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25949.8713105,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25949.8713105,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25949.8713105,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25949.8713105,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29814.745761000002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11618.956626,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27304.5480711,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11618.956626,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31371.1828902,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11618.956626,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31371.1828902,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11618.956626,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31371.1828902,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11646.183168000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31444.694553600006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 7086.608346,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 19133.8425342,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 7109.301108000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 19195.112991600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 7131.99387,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 19256.383449,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10311.736611,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24232.581035850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10311.736611,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27841.688849700004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10311.736611,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24232.581035850002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10334.429373,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24285.90902655,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10357.122135000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27964.229764500007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10357.122135000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27964.229764500007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10357.122135000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27964.229764500007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.3966,
      TavanTutari: 25953.2225685246,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 28733.074122117305,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 28793.28482753191,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 11455.561581,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30930.0162687,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 11455.561581,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26920.56971535,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 11455.561581,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26920.56971535,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 11455.561581,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30930.0162687,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 7036.677111000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18999.028199700002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 7050.290382000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16568.182397700002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 7050.290382000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16568.182397700002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 7068.449364,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16610.8560054,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 7068.449364,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 19084.8132828,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10080.239418000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27216.646428600005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10080.239418000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23688.562632300003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10093.852689000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27253.402260300005,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10112.011671,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23763.22742685,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10112.011671,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23763.22742685,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 28118.8426199304,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 28118.8426199304,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 28118.8426199304,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 28154.962711874705,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 28154.962711874705,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.385,
      TavanTutari: 25351.260051285,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26355.214507950004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11233.143879000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30329.488473300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11233.143879000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30329.488473300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11233.143879000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26397.888115650003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 11233.143879000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30329.488473300004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 7009.438638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18925.4843226,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 7023.06384,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16504.200024,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 26396.632837243505,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23379.221033250004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23379.221033250004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23379.221033250004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26897.988508200007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23411.212220100006,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26897.988508200007,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 9975.843168000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23443.231444800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 9975.843168000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26934.776553600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 9975.843168000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26934.776553600004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 9975.843168000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23443.231444800003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28295.0966772,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28331.884722600003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 11096.975376,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26077.892133600002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 11096.975376,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29961.833515200004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 1,
      EkOdemeMatrahi: 6959.5193340000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16354.870434900002,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 6968.586894,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16376.1792009,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 6968.586894,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16376.1792009,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 6968.586894,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18815.1846138,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 3,
      EkOdemeMatrahi: 6982.212096,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16408.1984256,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23356.2532617,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23356.2532617,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 3,
      EkOdemeMatrahi: 8664.089373,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23393.041307100004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 1,
      EkOdemeMatrahi: 6932.280861000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16290.860023350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 1,
      EkOdemeMatrahi: 6932.280861000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16290.860023350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 1,
      EkOdemeMatrahi: 6932.280861000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16290.860023350004,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 3,
      EkOdemeMatrahi: 6950.439843,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16333.53363105,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 1,
      EkOdemeMatrahi: 6905.042388,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18643.6144476,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 1,
      EkOdemeMatrahi: 6905.042388,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18643.6144476,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 1,
      EkOdemeMatrahi: 6905.042388,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18643.6144476,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 2,
      EkOdemeMatrahi: 6914.121879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16248.186415650001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 3,
      EkOdemeMatrahi: 6923.20137,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16269.5232195,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 3,
      EkOdemeMatrahi: 6923.20137,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16269.5232195,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 6895.962897,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18619.099821900003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 6895.962897,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16205.512807950001,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 6895.962897,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18619.099821900003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 6895.962897,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18619.099821900003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 6895.962897,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18619.099821900003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 6895.962897,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18619.099821900003,
    },
    {
      Unvani: "HEMŞİRE",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 3,
      EkOdemeMatrahi: 6900.508608000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16216.195228800003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 5854.076391,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13757.07951885,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 6017.483367,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14141.08591245,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13639.769154450001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 15671.224134900003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 15671.224134900003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 4,
      EkOdemeMatrahi: 5854.076391,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13757.07951885,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 4,
      EkOdemeMatrahi: 5854.076391,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13757.07951885,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 5,
      EkOdemeMatrahi: 5908.553337000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13885.100341950003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 5,
      EkOdemeMatrahi: 5908.553337000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13885.100341950003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14013.0931272,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 16100.149550400001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14013.0931272,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 16100.149550400001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 16100.149550400001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14013.0931272,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14013.0931272,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14013.0931272,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 16100.149550400001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 5963.018352,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14013.0931272,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 5622.591129,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13213.089153150002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 5663.442873,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13309.09075155,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 5735.422596,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13478.2431006,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 8,
      EkOdemeMatrahi: 5908.553337000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13885.100341950003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 5518.194879000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 12967.757965650002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 5518.194879000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 12967.757965650002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 5,
      EkOdemeMatrahi: 5622.591129,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 15180.996048300001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 6,
      EkOdemeMatrahi: 5663.442873,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13309.09075155,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 9,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13639.769154450001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 9,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 15671.224134900003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 9,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13639.769154450001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 9,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 15671.224134900003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 9,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13639.769154450001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 9,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13639.769154450001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 9,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13639.769154450001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 9,
      EkOdemeMatrahi: 5804.1570870000005,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 15671.224134900003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 4896.36309,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11506.4532615,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 4,
      EkOdemeMatrahi: 4869.124617,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11442.44284995,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 7,
      EkOdemeMatrahi: 4941.748614,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11613.1092429,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 9,
      EkOdemeMatrahi: 5005.29312,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 13514.291424000001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 9,
      EkOdemeMatrahi: 5005.29312,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11762.438832000002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 4782.8873490000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11239.785270150001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 4637.627424,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10898.4244464,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 4637.627424,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10898.4244464,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 4651.252626,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10930.443671100002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 4651.252626,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10930.443671100002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 4596.775680000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10802.422848000002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 4596.775680000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10802.422848000002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 4596.775680000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 12411.294336000003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 4596.775680000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 12411.294336000003,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 3,
      EkOdemeMatrahi: 4610.400882,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10834.4420727,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 1,
      EkOdemeMatrahi: 4560.469647,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10717.10367045,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 4569.549138,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10738.440474300001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 1,
      EkOdemeMatrahi: 4533.2311740000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10653.093258900002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 3,
      EkOdemeMatrahi: 4551.390156,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 12288.753421200001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 4524.151683,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10631.756455050001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 4524.151683,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10631.756455050001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 4524.151683,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 12215.2095441,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 2,
      EkOdemeMatrahi: 4524.151683,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 12215.2095441,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 12,
      Kademe: 3,
      EkOdemeMatrahi: 4528.697394,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 12227.482963800001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 13,
      Kademe: 1,
      EkOdemeMatrahi: 4506.004632,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10589.110885200002,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 13,
      Kademe: 1,
      EkOdemeMatrahi: 4506.004632,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 12166.212506400001,
    },
    {
      Unvani: "HİZMETLİ",
      Bransi: "YOK",
      Derece: 13,
      Kademe: 3,
      EkOdemeMatrahi: 4515.084123000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 10610.447689050001,
    },
    {
      Unvani: "İDARİ BÜRO GÖREVLİSİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 1,
      EkOdemeMatrahi: 4846.300614000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11388.806442900002,
    },
    {
      Unvani: "İŞ VE UĞRAŞI TERAPİSTİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "İŞ VE UĞRAŞI TERAPİSTİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "İŞ VE UĞRAŞI TERAPİSTİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "İŞ VE UĞRAŞI TERAPİSTİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 9140.685099,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 21480.609982650003,
    },
    {
      Unvani: "LABORANT",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13516.379349,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31763.491470150002,
    },
    {
      Unvani: "LABORANT",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10715.827650000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25182.194977500003,
    },
    {
      Unvani: "LABORANT",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8709.606138,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20467.574424299997,
    },
    {
      Unvani: "MEMUR",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 5127.717111000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 12050.135210850003,
    },
    {
      Unvani: "MEMUR",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 5154.955584,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 12114.145622400001,
    },
    {
      Unvani: "MEMUR",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 5105.024349,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11996.807220150002,
    },
    {
      Unvani: "MEMUR",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 4909.84512,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11538.136032,
    },
    {
      Unvani: "MEMUR",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 4855.368174,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11410.1152089,
    },
    {
      Unvani: "MEMUR",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 1,
      EkOdemeMatrahi: 4819.062141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11324.79603135,
    },
    {
      Unvani: "MEMUR",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 2,
      EkOdemeMatrahi: 4800.903159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11282.122423650002,
    },
    {
      Unvani: "MEMUR",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 2,
      EkOdemeMatrahi: 4800.903159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 11282.122423650002,
    },
    {
      Unvani: "MUHASEBECİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 10019.749248,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23546.410732800003,
    },
    {
      Unvani: "MUHASEBECİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 8807.857923,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20698.46611905,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 19551.783078,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 45946.6902333,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 19606.248093,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 46074.68301855,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 19606.248093,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 46074.68301855,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 19606.248093,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 46074.68301855,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 19606.248093,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 46074.68301855,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 17858.941212,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 41968.5118482,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 17899.590129,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 42064.036803150004,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 17899.590129,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 42064.036803150004,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 17899.590129,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 42064.036803150004,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 17899.590129,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 42064.036803150004,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 18065.120823,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 42453.03393405001,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 18065.120823,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 42453.03393405001,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 17323.143864,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 40709.38808040001,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 17425.130052,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 40949.0556222,
    },
    {
      Unvani: "MÜHENDİS",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 16063.647849,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 37749.57244515001,
    },
    {
      Unvani: "ODYOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "ODYOLOG",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "ODYOLOG",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "ODYOLOG",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "ODYOLOG",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "PERFÜZYONİST",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "PERFÜZYONİST",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "PERFÜZYONİST",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "PERFÜZYONİST",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "PERFÜZYONİST",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "PERFÜZYONİST",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "PERFÜZYONİST",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "PROGRAMCI",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11465.738724,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26944.486001400004,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 11042.49843,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25949.8713105,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29361.3379335,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "PSİKOLOG",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "SAĞLIK FİZİKÇİSİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "SAĞLIK FİZİKÇİSİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK FİZİKÇİSİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "SAĞLIK FİZİKÇİSİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "SAĞLIK FİZİKÇİSİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29885.85273135,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 12717.384141,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29885.85273135,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 12771.861087000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30013.873554450005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 12771.861087000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30013.873554450005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 12771.861087000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34484.02493490001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 12826.326102,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30141.866339700002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 12826.326102,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30141.866339700002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34778.1360159,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13046.333742,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30658.884293700004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13059.457842,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35260.5361734,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.385,
      TavanTutari: 32030.575853399998,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.6533,
      TavanTutari: 35778.3597860715,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13541.088450000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31821.557857500004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13650.018479999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 32077.543427999997,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 16983.754638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 45856.1375226,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 17092.684668,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 46150.248603600005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 25433.038287000007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 25433.038287000007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 25433.038287000007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 9419.643810000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22136.162953500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32461.906558500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32461.906558500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28371.220036500003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12072.85959,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32596.720893,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12122.790825,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28488.558438750002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12122.790825,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32731.5352275,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 8766.015906,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20600.1373791,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30170.1595131,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26259.21290955,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30170.1595131,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26355.214507950004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26355.214507950004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26355.214507950004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30390.758930700005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11255.836641000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30390.758930700005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11019.805668,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25896.543319800003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11330.107116000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30591.289213200005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 7209.151647000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 19464.709446900004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28270.582051500005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 10497.824418000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28344.125928600006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 10497.824418000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28344.125928600006,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 10497.824418000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24669.887382300007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 10525.05096,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24733.869756000004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 10525.05096,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28417.637592000003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 10525.05096,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24733.869756000004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29667.6902205,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 7086.608346,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 19133.8425342,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 7109.301108000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16706.857603800003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 8914.186995,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 24068.304886500002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10311.736611,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24232.581035850002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10311.736611,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27841.688849700004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10334.429373,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24285.90902655,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10334.429373,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27902.959307100005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10334.429373,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27902.959307100005,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10357.122135000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27964.229764500007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28699.539680700003,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23379.221033250004,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20328.59080185,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 3,
      EkOdemeMatrahi: 6950.439843,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16333.53363105,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 9140.685099,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 24679.849767300002,
    },
    {
      Unvani: "SAĞLIK MEMURU",
      Bransi: "YOK",
      Derece: 11,
      Kademe: 1,
      EkOdemeMatrahi: 6905.042388,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16226.8496118,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 12826.326102,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30141.866339700002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12880.791117,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30269.859124950002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31560.525474000002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.42,
      TavanTutari: 32632.4315691,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30170.1595131,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 30280.459221900008,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11214.984897000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26355.214507950004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27869.87524065,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11019.805668,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29753.475303600004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11019.805668,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29753.475303600004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11051.577921,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25971.20811435,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11330.107116000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26625.7517226,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11330.107116000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26625.7517226,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31297.6390131,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27315.202454100003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31469.209179300004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 11655.262659,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27389.86724865,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 10525.05096,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28417.637592000003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10988.033415,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25821.878525250002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25885.8889368,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 11015.271888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25885.8889368,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10311.736611,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24232.581035850002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29238.797018700006,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 29300.06747610001,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 10851.876843000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25501.910581050004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 10874.569604999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25555.238571749996,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10112.011671,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23763.22742685,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28613.754597600004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24936.55537365,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23379.221033250004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23411.212220100006,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26897.988508200007,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26897.988508200007,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23411.212220100006,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 9962.217966000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23411.212220100006,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 9975.843168000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26934.776553600004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 9975.843168000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23443.231444800003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 9975.843168000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 23443.231444800003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10241.820951,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27652.916567700002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24595.22258775,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 10466.052165,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28258.3408455,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 10479.665436,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24627.2137746,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 10493.290638,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24659.232999300002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 1,
      EkOdemeMatrahi: 8641.396611,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20307.28203585,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 1,
      EkOdemeMatrahi: 8641.396611,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23331.770849700002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 1,
      EkOdemeMatrahi: 8641.396611,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20307.28203585,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20328.59080185,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20328.59080185,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20328.59080185,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23356.2532617,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20328.59080185,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 8650.464171,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20328.59080185,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 3,
      EkOdemeMatrahi: 8664.089373,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20360.610026550003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 3,
      EkOdemeMatrahi: 8664.089373,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23393.041307100004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 3,
      EkOdemeMatrahi: 8957.305629,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 24184.725198300002,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 9167.911641,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 24753.361430700003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 2,
      EkOdemeMatrahi: 9167.911641,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 24753.361430700003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 9,
      Kademe: 3,
      EkOdemeMatrahi: 9181.536843,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 21576.61158105,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20264.608428150004,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 8623.237629000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23282.741598300003,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 3,
      EkOdemeMatrahi: 8632.31712,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 23307.256224,
    },
    {
      Unvani: "SAĞLIK TEKNİKERİ",
      Bransi: "YOK",
      Derece: 10,
      Kademe: 2,
      EkOdemeMatrahi: 9140.685099,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 21480.609982650003,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35966.885973300006,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 13321.068879,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31304.51186565,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36113.97372750001,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 13375.545825000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31432.532688750005,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 13430.01084,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36261.029268000006,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 17092.684668,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 46150.248603600005,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12022.928355,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28253.88163425,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34091.85535110001,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12626.613093000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29672.540768550007,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34226.669685600005,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 12792.155718000002,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30061.565937300005,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11174.133152999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26259.21290955,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31800.108305700003,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 11777.817891,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27677.872043850002,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31910.408014500004,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11818.669635,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27773.87364225,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 11859.521379,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32020.7077233,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 11984.21226,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28162.898811000003,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11361.879369,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26700.416517150003,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 10470.585945,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28270.582051500005,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 10525.05096,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24733.869756000004,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10311.736611,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27841.688849700004,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10080.239418000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 27216.646428600005,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 10629.459141000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24979.228981350003,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 2,
      EkOdemeMatrahi: 7009.438638,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 18925.4843226,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 9948.604695000002,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26861.232676500007,
    },
    {
      Unvani: "SAĞLIK TEKNİSYENİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 9975.843168000001,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 26934.776553600004,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 36408.0848085,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12676.544328,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29789.879170800003,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 29907.217573050002,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 12726.475563,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 34361.484020100004,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11591.718153,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27240.537659550002,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11623.490406,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 31383.424096200004,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 10829.184081000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 25448.582590350004,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 10597.686888,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 24904.564186800002,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 28650.510429300004,
    },
    {
      Unvani: "SOSYAL ÇALIŞMACI",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 10611.300159,
      TEKSOdemeyeEsas: 2.525,
      TavanTutari: 26793.532901475,
    },
    {
      Unvani: "ŞEF",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 11176.734111,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26265.32516085,
    },
    {
      Unvani: "ŞEF",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 11176.734111,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26265.32516085,
    },
    {
      Unvani: "ŞEF",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 11176.734111,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26265.32516085,
    },
    {
      Unvani: "ŞEF",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 11176.734111,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26265.32516085,
    },
    {
      Unvani: "ŞOFÖR",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 4,
      EkOdemeMatrahi: 7111.245861,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16711.427773350002,
    },
    {
      Unvani: "ŞOFÖR",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 5,
      EkOdemeMatrahi: 7165.722807000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16839.44859645,
    },
    {
      Unvani: "ŞOFÖR",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 7220.187822,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 16967.4413817,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 18290.079827999998,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 118885.51888199999,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 18399.009858,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119593.564077,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 17591.078331,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 114342.00915150001,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 16692.351894,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108500.287311,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 16115.905629,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104753.3865885,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 14640.744858000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95164.84157700001,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 14663.43762,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95312.34453,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 14663.43762,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95312.34453,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 14663.43762,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95312.34453,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 14686.130382,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95459.84748299999,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14422.872867,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93748.6736355,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14441.031849,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93866.7070185,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14441.031849,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93866.7070185,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14441.031849,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93866.7070185,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14441.031849,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93866.7070185,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14441.031849,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93866.7070185,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 14277.624873,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92804.5616745,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TABİP",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13132.117632,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30860.4764352,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13132.117632,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30860.4764352,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13132.117632,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30860.4764352,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13132.117632,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30860.4764352,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13297.648325999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31249.473566099998,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 12324.186104999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 28961.83734675,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 17448.681846,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 41004.4023381,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 12060.916659,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 32564.474979300005,
    },
    {
      Unvani: "TEKNİKER",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 11631.281349,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 27333.511170150003,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 9180.498846,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 21574.172288100002,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 12944.991828,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30420.730795800002,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13117.716915,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30826.634750250003,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13117.716915,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30826.634750250003,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13117.716915,
      TEKSOdemeyeEsas: 2.7,
      TavanTutari: 35417.835670500004,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13117.716915,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30826.634750250003,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13117.716915,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30826.634750250003,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13117.716915,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30826.634750250003,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13117.716915,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 30826.634750250003,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8672.142798,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20379.535575300004,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 11447.138295,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 26900.77499325,
    },
    {
      Unvani: "TEKNİSYEN",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 9422.996421,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 22144.04158935,
    },
    {
      Unvani: "TEKNİSYEN YARDIMCISI",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 6039.078477,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 14191.834420950001,
    },
    {
      Unvani: "TERZİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 5,
      EkOdemeMatrahi: 5822.184828,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13682.134345800001,
    },
    {
      Unvani: "TIBBİ TEKNOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "TIBBİ TEKNOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13484.475855,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 31688.518259250002,
    },
    {
      Unvani: "TIBBİ TEKNOLOG",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 13650.018479999999,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 32077.543427999997,
    },
    {
      Unvani: "UZMAN ECZACI",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 12026.80593,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 78174.238545,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 9131.056782000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 21457.983437700004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 9239.998743,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 21713.99704605,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 9294.463758,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 21841.9898313,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 8032.641198,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 18876.7068153,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 8032.641198,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 18876.7068153,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 8032.641198,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 18876.7068153,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 8082.572433,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 18994.045217550003,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8241.433698,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19367.369190300004,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 8486.532231000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19943.35074285,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 8486.532231000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 19943.35074285,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 8536.463466,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 20060.689145099997,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 7587.805794,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17831.3436159,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 7628.6575379999995,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17927.3452143,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 7860.1547310000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 18471.363617850002,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 7941.858219,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 18663.36681465,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 7388.092785000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17362.018044750002,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 7524.261288,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17682.0140268,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 7524.261288,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17682.0140268,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 7524.261288,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17682.0140268,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 7524.261288,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17682.0140268,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 7556.033541,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17756.67882135,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 7556.033541,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 17756.67882135,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 5631.527448,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13234.0895028,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 5658.765921,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13298.099914350001,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 5658.765921,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13298.099914350001,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 5658.765921,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13298.099914350001,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 5658.765921,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13298.099914350001,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 5586.1419240000005,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13127.433521400002,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 5513.517927,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 12956.76712845,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 5545.290180000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 13031.431923000002,
    },
    {
      Unvani: "VERİ HAZIRLAMA VE KONTROL İŞLETMENİ",
      Bransi: "YOK",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 5386.416984,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 12658.079912400002,
    },
    {
      Unvani: "VEZNEDAR",
      Bransi: "YOK",
      Derece: 2,
      Kademe: 6,
      EkOdemeMatrahi: 8039.919108000001,
      TEKSOdemeyeEsas: 2.35,
      TavanTutari: 18893.809903800004,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 16761.993141000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108952.95541650002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 16761.993141000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108952.95541650002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 16144.695132,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104940.518358,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 15359.444436,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99836.388834,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 14737.612647000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95794.48220550001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ACİL TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "ACİL TIP",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 22020.056427,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209190.5360565,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "ACİL TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 203007.14020350002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 203524.6711905,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 19249.4754,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163620.5409,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ACİL TIP",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ADLİ TIP",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ADLİ TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ADLİ TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ADLİ TIP",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ADLİ TIP",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14409.259596,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 93660.187374,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "AİLE HEKİMLİĞİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 16694.046096000002,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 141899.39181600002,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ALGOLOJİ",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 217608.80507099998,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANATOMİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANATOMİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANATOMİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 16112.922879000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104733.99871350001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 28565.247924000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 271369.855278,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ANESTEZİYOLOJİ VE REANİMASYON",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "BEYİN VE SİNİR CERRAHİSİ",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "CERRAHİ ONKOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "ÇOCUK ACİL",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK ACİL",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 245745.48550950002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK CERRAHİSİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK CERRAHİSİ",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 19249.4754,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163620.5409,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK CERRAHİSİ",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK ENDOKRİNOLOJİSİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 225520.20344699998,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK ENDOKRİNOLOJİSİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 237759.24397049999,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK ENDOKRİNOLOJİSİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 218133.08303850004,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK ENFEKSİYON HASTALIKLARI",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 245745.48550950002,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK GASTROENTEROLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK GASTROENTEROLOJİSİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 217084.5271035,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK GASTROENTEROLOJİSİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 217084.5271035,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK HEMATOLOJİSİ VE ONKOLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "ÇOCUK İMMÜNOLOJİSİ VE ALERJİ HASTALIKLARI",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "ÇOCUK İMMÜNOLOJİSİ VE ALERJİ HASTALIKLARI",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK İMMÜNOLOJİSİ VE ALERJİ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK İMMÜNOLOJİSİ VE ALERJİ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "ÇOCUK KARDİYOLOJİSİ",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "DOKTOR ÖĞRETİM ÜYESİ YAN DAL",
      Bransi: "ÇOCUK KARDİYOLOJİSİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 20876.231457,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 198324.1988415,
    },
    {
      Unvani: "PROFESÖR YAN DAL",
      Bransi: "ÇOCUK KARDİYOLOJİSİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 25869.104406,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 245756.491857,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK METABOLİZMA HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK METABOLİZMA HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "BAŞASİSTAN YAN DAL",
      Bransi: "ÇOCUK NEFROLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 257790.5697315,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK NEFROLOJİSİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 224376.3128565,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK NEFROLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK NÖROLOJİSİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 225520.20344699998,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK NÖROLOJİSİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 225520.20344699998,
    },
    {
      Unvani: "BAŞASİSTAN YAN DAL",
      Bransi: "ÇOCUK RADYOLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 257790.5697315,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK RADYOLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK ROMATOLOJİSİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 224376.3128565,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 18427.799361,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119780.6958465,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 17569.936599,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 114204.58789350001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 16081.150625999999,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104527.479069,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 212957.4271695,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK SAĞLIĞI VE HASTALIKLARI",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK ÜROLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 15359.444436,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99836.388834,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 19249.4754,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163620.5409,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÇOCUK VE ERGEN RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 17143.403349,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145718.92846650002,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK YOĞUN BAKIMI",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 245745.48550950002,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK YOĞUN BAKIMI",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 225520.20344699998,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ÇOCUK YOĞUN BAKIMI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 18427.799361,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119780.6958465,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 16081.150625999999,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104527.479069,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "DERİ VE ZÜHREVİ HASTALIKLARI",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 17116.164876,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145487.401446,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "EL CERRAHİSİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 225520.20344699998,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "ENDOKRİNOLOJİ VE METABOLİZMA HASTALIKLARI",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 17143.403349,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145718.92846650002,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "ENDOKRİNOLOJİ VE METABOLİZMA HASTALIKLARI",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "PROFESÖR YAN DAL",
      Bransi: "ENDOKRİNOLOJİ VE METABOLİZMA HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ENDOKRİNOLOJİ VE METABOLİZMA HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ENDOKRİNOLOJİ VE METABOLİZMA HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ENDOKRİNOLOJİ VE METABOLİZMA HASTALIKLARI",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 217608.80507099998,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 18373.334346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119426.673249,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOKTOR ÖĞRETİM ÜYESİ",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 20930.696472,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 198841.616484,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 29563.8249,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 280856.33655,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ENFEKSİYON HASTALIKLARI VE KLİNİK MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 16712.205078,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 142053.74316299998,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 22253.164305,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 189151.89659249998,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 28565.247924000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 271369.855278,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "FİZİKSEL TIP VE REHABİLİTASYON",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "FİZYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "GASTROENTEROLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "GASTROENTEROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "GASTROENTEROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "GASTROENTEROLOJİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 218133.08303850004,
    },
    {
      Unvani: "DOÇENT YAN DAL",
      Bransi: "GASTROENTEROLOJİ CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "GASTROENTEROLOJİ CERRAHİSİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 218133.08303850004,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "GELİŞİMSEL PEDİATRİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 16144.695132,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104940.518358,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GENEL CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 212957.4271695,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 28565.247924000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 271369.855278,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 29563.8249,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 280856.33655,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GENEL CERRAHİ",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "GERİATRİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "GERİATRİ",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "GERİATRİ",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "GERİATRİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 224376.3128565,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "GERİATRİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS CERRAHİSİ",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 19249.4754,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163620.5409,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 18427.799361,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119780.6958465,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 17143.403349,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145718.92846650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖĞÜS HASTALIKLARI",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 16761.993141000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108952.95541650002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 212957.4271695,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21965.591412,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 208673.11841400003,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "GÖZ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 16712.205078,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 142053.74316299998,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 16081.150625999999,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104527.479069,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HALK SAĞLIĞI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "HAVA VE UZAY HEKİMLİĞİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "HEMATOLOJİ",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "HEMATOLOJİ",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "DOKTOR ÖĞRETİM ÜYESİ YAN DAL",
      Bransi: "HEMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 20985.161487,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 199359.03412650002,
    },
    {
      Unvani: "PROFESÖR YAN DAL",
      Bransi: "HEMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "HEMATOLOJİ",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 208601.12079450002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HİSTOLOJİ VE EMBRİYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HİSTOLOJİ VE EMBRİYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "HİSTOLOJİ VE EMBRİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "HİSTOLOJİ VE EMBRİYOLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "HİSTOLOJİ VE EMBRİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "HİSTOLOJİ VE EMBRİYOLOJİ",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "HİSTOLOJİ VE EMBRİYOLOJİ",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 17116.164876,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145487.401446,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 18318.869331,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119072.65065150001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 18427.799361,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119780.6958465,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 18427.799361,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119780.6958465,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 18427.799361,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119780.6958465,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 18427.799361,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 119780.6958465,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 17569.936599,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 114204.58789350001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 16721.141397000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108687.41908050003,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 16721.141397000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108687.41908050003,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 16761.993141000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108952.95541650002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 16112.922879000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104733.99871350001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 16112.922879000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104733.99871350001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 16144.695132,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104940.518358,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 16144.695132,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104940.518358,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 1,
      EkOdemeMatrahi: 14737.612647000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95794.48220550001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 169214.8138005,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 19249.4754,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163620.5409,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 17116.164876,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145487.401446,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 17143.403349,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145718.92846650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 17143.403349,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145718.92846650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 17143.403349,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145718.92846650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "İÇ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 16911.918087000002,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 143751.30373950003,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "İMMÜNOLOJİ VE ALERJİ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "İMMÜNOLOJİ VE ALERJİ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "İŞ VE MESLEK HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "JİNEKOLOJİK ONKOLOJİ CERRAHİSİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 224948.3207895,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "JİNEKOLOJİK ONKOLOJİ CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 16144.695132,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104940.518358,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 15359.444436,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99836.388834,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 22362.106266000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 212440.00952700002,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 203524.6711905,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KADIN HASTALIKLARI VE DOĞUM",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KALP VE DAMAR CERRAHİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 16081.150625999999,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104527.479069,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 16112.922879000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104733.99871350001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 5,
      Kademe: 2,
      EkOdemeMatrahi: 15332.217894000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99659.41631100001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KARDİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 212957.4271695,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 28565.247924000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 271369.855278,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KARDİYOLOJİ",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "KLİNİK NÖROFİZYOLOJİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 218133.08303850004,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "KLİNİK NÖROFİZYOLOJİ",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 19907.625153,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 228937.6892595,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 28565.247924000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 271369.855278,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "KULAK BURUN BOĞAZ HASTALIKLARI",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "NEFROLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 246371.9703885,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "NEFROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "NEFROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "NEFROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "NEFROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "NEONATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "ASİSTAN (YAN DAL)",
      Bransi: "NEONATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "DOÇENT YAN DAL",
      Bransi: "NEONATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "NEONATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "NEONATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 16721.141397000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108687.41908050003,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 16112.922879000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104733.99871350001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14304.863346,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 92981.611749,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÖROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOKTOR ÖĞRETİM ÜYESİ",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 20985.161487,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 199359.03412650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 203007.14020350002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 19249.4754,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163620.5409,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÖROLOJİ",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÜKLEER TIP",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÜKLEER TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÜKLEER TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÜKLEER TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "NÜKLEER TIP",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "NÜKLEER TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÜKLEER TIP",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÜKLEER TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÜKLEER TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÜKLEER TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÜKLEER TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÜKLEER TIP",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "NÜKLEER TIP",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 16144.695132,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104940.518358,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOKTOR ÖĞRETİM ÜYESİ",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 20985.161487,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 199359.03412650002,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204042.088833,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 19825.921665,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168520.33415250003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ORTOPEDİ VE TRAVMATOLOJİ",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 19249.4754,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163620.5409,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "PERİNATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "PLASTİK, REKONSTRÜKTİF VE ESTETİK CERRAHİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 28565.247924000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 271369.855278,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYASYON ONKOLOJİSİ",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 19249.4754,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163620.5409,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 16721.141397000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108687.41908050003,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 3,
      Kademe: 3,
      EkOdemeMatrahi: 16802.844885000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 109218.49175250002,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 16081.150625999999,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104527.479069,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 16112.922879000002,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104733.99871350001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 2,
      EkOdemeMatrahi: 15123.425394,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98302.265061,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RADYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 22362.106266000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 212440.00952700002,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 212957.4271695,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "EĞİTİM GÖREVLİSİ",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 24637.371828000003,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 234055.03236600003,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RADYOLOJİ",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ROMATOLOJİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 224376.3128565,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ROMATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "ROMATOLOJİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 218133.08303850004,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 3,
      Kademe: 1,
      EkOdemeMatrahi: 16721.141397000003,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 108687.41908050003,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176159.508867,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 3,
      Kademe: 2,
      EkOdemeMatrahi: 19866.773409,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 168867.57397650002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "RUH SAĞLIĞI VE HASTALIKLARI",
      Derece: 4,
      Kademe: 2,
      EkOdemeMatrahi: 19217.703147,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163350.4767495,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "SPOR HEKİMLİĞİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "SUALTI HEKİMLİĞİ VE HİPERBARİK TIP",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 212957.4271695,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ BİYOKİMYA",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ FARMAKOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ FARMAKOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ FARMAKOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ FARMAKOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ GENETİK",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ GENETİK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 203524.6711905,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ GENETİK",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182101.0215915,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ GENETİK",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ GENETİK",
      Derece: 5,
      Kademe: 3,
      EkOdemeMatrahi: 17170.629891,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 145950.3540735,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "TIBBİ MİKOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 247624.6657335,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 4,
      Kademe: 3,
      EkOdemeMatrahi: 16144.695132,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 104940.518358,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 5,
      Kademe: 1,
      EkOdemeMatrahi: 15304.979421,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 99482.3662365,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ MİKROBİYOLOJİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "TIBBİ ONKOLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 224948.3207895,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "TIBBİ ONKOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "TIBBİ ONKOLOJİ",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 217608.80507099998,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "TIBBİ PARAZİTOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 7,
      Kademe: 3,
      EkOdemeMatrahi: 14901.019623,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96856.6275495,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21965.591412,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 208673.11841400003,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22074.521442,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209707.953699,
    },
    {
      Unvani: "PROFESÖR",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 25978.034436,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 246791.32714200002,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 181637.9675505,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 204559.5064755,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "TIBBİ PATOLOJİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "TIBBİ VİROLOJİ",
      Derece: 1,
      Kademe: 2,
      EkOdemeMatrahi: 21423.649599,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 224948.3207895,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 6,
      Kademe: 1,
      EkOdemeMatrahi: 15100.732632,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98154.762108,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 6,
      Kademe: 3,
      EkOdemeMatrahi: 15146.118156,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 98449.768014,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 7,
      Kademe: 1,
      EkOdemeMatrahi: 14869.247370000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96650.10790500001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 7,
      Kademe: 2,
      EkOdemeMatrahi: 14882.860641000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 96738.59416650001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "ASİSTAN (TABİP)",
      Bransi: "ÜROLOJİ",
      Derece: 8,
      Kademe: 3,
      EkOdemeMatrahi: 14764.851120000001,
      TEKSOdemeyeEsas: 6.5,
      TavanTutari: 95971.53228000001,
    },
    {
      Unvani: "BAŞASİSTAN",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 22416.571281,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 190540.8558885,
    },
    {
      Unvani: "DOÇENT",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 22020.056427,
      TEKSOdemeyeEsas: 9.5,
      TavanTutari: 209190.5360565,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 182563.974219,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 183026.9268465,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 2,
      Kademe: 1,
      EkOdemeMatrahi: 20674.716867,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 175735.09336949998,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 176583.92436450004,
    },
    {
      Unvani: "UZMAN TABİP",
      Bransi: "ÜROLOJİ",
      Derece: 4,
      Kademe: 1,
      EkOdemeMatrahi: 19185.930894,
      TEKSOdemeyeEsas: 8.5,
      TavanTutari: 163080.412599,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "YOĞUN BAKIM",
      Derece: 1,
      Kademe: 1,
      EkOdemeMatrahi: 21369.172653,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 224376.3128565,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "YOĞUN BAKIM",
      Derece: 1,
      Kademe: 3,
      EkOdemeMatrahi: 21478.114614,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 246998.31806099997,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "YOĞUN BAKIM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "YOĞUN BAKIM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "YOĞUN BAKIM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "YOĞUN BAKIM",
      Derece: 1,
      Kademe: 4,
      EkOdemeMatrahi: 21532.579629,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 226092.0861045,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "YOĞUN BAKIM",
      Derece: 2,
      Kademe: 2,
      EkOdemeMatrahi: 20724.648102,
      TEKSOdemeyeEsas: 10.5,
      TavanTutari: 217608.80507099998,
    },
    {
      Unvani: "UZMAN TABİP YAN DAL",
      Bransi: "YOĞUN BAKIM",
      Derece: 2,
      Kademe: 3,
      EkOdemeMatrahi: 20774.579337000003,
      TEKSOdemeyeEsas: 11.5,
      TavanTutari: 238907.66237550002,
    },
  ];
  
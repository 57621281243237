import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Image, Card, Button } from 'react-bootstrap';
import Hesaplama from './TayinBedeli'; // Hesaplama bileşenini import ediyoruz
import TabanEkOdeme from './TabanEkOdeme'; // Taban Ek Odeme bileşenini import ediyoruz
import BasamakTesvikOdemeleri from './BasamakTesvikOdemeleri'; // Basamak Teşvik Ödemeleri bileşenini import ediyoruz
import MaasHesabi from './MaasHesabi'; // 657 Maaş Hesaplaması bileşenini import ediyoruz
import Secim from './Secim';
import BasamakOdeme from './BasamakOdeme';
import SecimAdli from './SecimAdli';
import SecimAile from './SecimAile';
import SecimHastane from './SecimHastane';
import SecimUniversite from './SecimUniversite';
import IlkBasamakOdeme from './IlkBasamakOdeme';
import Nobet from './Nobet';
import Icap from './Icap';
import TesvikBirinci from './TesvikBirinci';
import TavanIkinci from './TavanIkinci';
import TavanUniversite from './TavanUniversite';
import TesvikBirinciBasamak from './TesvikBirinciBasamak';
import TavanHastaneler from './TavanHastaneler';
import Calisiyor from './Calisiyor';
import Maas from './Maas';


function App() {
  const cards = [
    {
      title: "Memur Maaş Hesabı",
      link: "/maas",
      linkText: "Memur Maaş Hesaplama"
    },
    {
      title: "Nöbet Ücreti Hesaplama",
      link: "/nobet",
      linkText: "NÖBET ÜCRETİ Hesaplama"
    },
    {
      title: "İcap Nöbet Ücreti Hesaplama",
      link: "/icap",
      linkText: "İCAP NÖBET ÜCRETİ HESAPLAMA"
    },
    {
      title: "İl Sağlık Müdürlüğü Taban Ek Ödeme Hesaplama",
      link: "/ilk-basamak-odeme",
      linkText: "İl Sağlık Müdürlüğü Taban Ek Ödeme Hesaplama"}
      
      
      ,

      {
        title: "İl Sağlık Müdürlüğü Tavan Ek Ödeme Hesaplama",
        link: "/tavan-ikinci",
        linkText: "İl Sağlık Müdürlüğü Tavan EK Ödeme Hesaplama"
      }
      ,

      
      {
        title: "İl Sağlık Müdürlüğü Teşvik Ek Ödeme Hesaplama",
        link: "/tesvik-birinci-basamak",
        linkText: "İL SAĞLIK MÜDÜRLÜĞÜ TEŞVİK EK ÖDEME HESAPLAMA"
      }  

      ,
      {
        title: "Hastane Taban Ek Ödeme Hesaplama",
        link: "/ilk-basamak-odeme",
        linkText: "Hastane  Taban Ek Ödeme Hesaplama"
      }  
      ,
      {
        title: "Hastane Tavan EK Ödeme Hesaplama",
        link: "/tavan-hastaneler",
        linkText: "Hastane Tavan EK Ödeme Hesaplama"
      }   

      ,
      {
        title: "Tayın (Yemek) Bedeli Hesaplama",
        link: "/Hesaplama",
        linkText: "TAYIN (YEMEK) BEDELİ HESAPLAMA"
      }  
 
      ,
      {
        title: "Üniversite Hastaneleri Taban Ek Ödeme Hesaplama",
        link: "/basamak-odeme",
        linkText: "Üniversite Hastaneleri Taban Ek Ödeme Hesaplama"
      }  
      ,
      {
        title: "Üniversite Hastaneleri Tavan EK Ödeme Hesaplama",
        link: "/tavan-universite",
        linkText: "Üniversite Hastaneleri Tavan EK Ödeme Hesaplama"
      }  

      ,


      {
        title: "Adli Tıp Kurumu Performans Ödemesi Hesaplama",
        link: "/calisiyor",
        linkText: "Adli Tıp Kurumu Performans Ödemesi Hesaplama"
      }    
      ,
      {
        title: "ASPB Performans Ödemesi Hesaplama",
        link: "/Calisiyor",
        linkText: "ASPB Performans Ödemesi Hesaplama"
      }  

      ,
      {
        title: "ASPB Ek Ders Ödemesi Hesaplama",
        link: "/Calisiyor",
        linkText: "ASPB Ek Ders Ödemesi Hesaplama"
      }  

  
    
  ];
  return (
    <Router>
      <div>
        <header className="header-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="auto">
                <Image
                  src={`${process.env.PUBLIC_URL}/ss_logo.png`}
                  alt="Sağlık-Sen Logo"
                  rounded
                  className="logo"
                  link="/"
                />
              </Col>
              <Col>
                <h1 className="header-title">
                  <Link to="/" className="text-decoration-none text-white">
                    SAĞLIK-SEN
                  </Link>
                </h1>
                <p className="header-subtitle">
                  <Link to="/" className="text-decoration-none text-white">
                    {" "}
                    SAĞLIK VE SOSYAL HİZMET ÇALIŞANLARI SENDİKASI
                  </Link>
                </p>
              </Col>
            </Row>
          </Container>
        </header>
        {/* <Navbar bg="light" variant="light" expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">
                  Ana Sayfa
                </Nav.Link>
                <Nav.Link as={Link} to="/hesaplama">
                  Tayin (Yemek) Bedeli Ödemesi
                </Nav.Link>
                <Nav.Link as={Link} to="/taban-ek-odeme">
                  Taban Ek Ödeme
                </Nav.Link>
                <Nav.Link as={Link} to="/basamak-tesvik-odemeleri">
                  1. Basamak Teşvik Ödemeleri
                </Nav.Link>
                <Nav.Link as={Link} to="/maas-hesabi">
                  657 Maaş Hesaplaması
                </Nav.Link>
                <Nav.Link as={Link} to="/basamak-odeme">
                  2. ve 3. Basamak Taban Ek Ödeme
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar> */}
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Container
                className="d-flex justify-content-center text-center"
                style={{ minHeight: "100vh", paddingTop: "2rem" }}
              >
                <div>
                  <h1 className="mb-4">SAĞLIK-SEN MAAŞ ROBOTU</h1>
                  <ul
                    style={{
                      listStyleType: "disc",
                      textAlign: "left",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {cards.map((item, index) => (
                      <li key={index} style={{ marginBottom: "1rem", fontWeight:"bold" }}>
                        <Link
                          to={item.link}
                          className="text-decoration-none text-dark"
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Container>
            }
          />
          <Route path="/secim" element={<Secim />} />
          <Route path="/TabanEkOdeme" element={<TabanEkOdeme />} />
          <Route path="/hesaplama" element={<Hesaplama />} />
          <Route path="/" element={<App />} />
          <Route
            path="/basamak-tesvik-odemeleri"
            element={<BasamakTesvikOdemeleri />}
          />
          <Route path="/MaasHesabi" element={<MaasHesabi />} />
          <Route path="/basamak-odeme" element={<BasamakOdeme />} />
          <Route path="/secim-adli" element={<SecimAdli />} />
          <Route path="/secim-hastane" element={<SecimHastane />} />
          <Route path="/secim-universite" element={<SecimUniversite />} />
          <Route path="/ilk-basamak-odeme" element={<IlkBasamakOdeme />} />
          <Route path="/nobet" element={<Nobet />} />
          <Route path="/icap" element={<Icap />} />
          <Route path="/tesvik-birinci" element={<TesvikBirinci />} />
          <Route path="/tavan-ikinci" element={<TavanIkinci />} />
          <Route path="/tavan-universite" element={<TavanUniversite />} />
          <Route
            path="/tesvik-birinci-basamak"
            element={<TesvikBirinciBasamak />}
          />
          <Route path="/tavan-hastaneler" element={<TavanHastaneler />} />
          <Route path="/calisiyor" element={<Calisiyor />} />
          <Route path="/maas" element={<Maas />} />
          <Route path="/secim-aile" element={<SecimAile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

const MaasVergiIstisnaTutari = {
    "Ocak": {
        "gelir": 2550.32,
        "damga": 151.818975
    },
    "Şubat": {
        "gelir": 2550.32,
        "damga": 151.818975
    },
    "Mart": {
        "gelir": 2550.32,
        "damga": 151.818975
    },
    "Nisan": {
        "gelir": 2550.32,
        "damga": 151.818975
    },
    "Mayıs": {
        "gelir": 2550.32,
        "damga": 151.818975
    },
    "Haziran": {
        "gelir": 2550.32,
        "damga": 151.818975
    },
    "Temmuz": {
        "gelir": 3001.06,
        "damga": 151.818975
    },
    "Ağustos": {
        "gelir": 3400.42,
        "damga": 151.818975
    },
    "Eylül": {
        "gelir": 3400.42,
        "damga": 151.818975
    },
    "Ekim": {
        "gelir": 3400.42,
        "damga": 151.818975
    },
    "Kasım": {
        "gelir": 3400.42,
        "damga": 151.818975
    },
    "Aralık": {
        "gelir": 3400.42,
        "damga": 151.818975
    }
};
export default MaasVergiIstisnaTutari;
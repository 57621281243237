import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button, Table  } from "react-bootstrap";
import MaasVergiIstisnaTutari from "./MaasVergiIstisnaTutari";
import personel from "./merged_personel";
import Maas657EkGosterge from "./Maas657EkGosterge";
import { useNavigate } from "react-router-dom";

import MaasGelistirmeOdenegi from "./MaasGelistirmeOdenegi";

const MaasHesabi = () => {
  const labels = [
    "Net Ödenen",
    "Kesinti Toplamı",
    "Gelir Toplamı",
    "Aylık Tutar",
    "Ek Gösterge Aylığı",
    "Taban Aylık",
    "Kıdem Aylık",
    "Yan Ödeme Aylığı",
    "Emekli Kesenegi Karşılığı",
    "Gen Sağ Sig Pir Devlet",
    "Aile Yardımı",
    "Çocuk Yardımı",
    "Özel Hizmet Tazminatı",
    "Dil Tazminatı",
    "Sabit Ek Od. Nisb. 40",
    "Top. So. D. İkram",
    "Fazla Çalışma Ücreti",
    "Ek Tazminat 28/B",
    "Ek Tazminat",
    "Eğitim Öğretim Tazminatı",
    "Denetim Tazminatı",
    "Kısa Vadeli Sigorta Kolu",
    "Sözleşme Ücreti",
    "İlave Ödeme 375",
    "Bölge Tazminatı",
    "İlave Ücret",
    "Sosyal Denge Tazminatı",
    "Yemek Yardımı",
    "Tazminat Tutarı",
    "Ücret Tutarı",
    "Makam Tazminatı",
    "Temsil Görev Tazminatı",
    "Üniversite Ödeneği",
    "İdari Görev Ödeneği",
    "Eğitim Öğretim Ödeneği",
    "Akademik Teşvik",
    "Geliştirme Ödeneği",
    "Yüksek Öğretim Tazminatı",
    "Gelir Vergisi",
    "Damga Vergisi",
    "Emekli Kesenegi Kişi",
    "Emekli Kesenegi Devlet",
    "Gen Sağ Sig Pir Kişi",
    "İlksan Kesintisi",
    "Polsan Kesintisi",
    "Bireysel Emeklilik",
    "Sendika Aidatı",
    "Kefalet Kesintisi",
    "Kesinti Kira İcra Nafaka",
  ];
  const [year, setYear] = useState(2024);
  const [month, setMonth] = useState(13);
  const [unvan, setUnvan] = useState("Hemşire - Lisans");
  const [kanun, setKanun] = useState("5510");
  const [gelirVergisiAGIIstisnaSiniri, setGelirVergisiAGIIstisnaSiniri] =useState(3001.06);
  const [damgaVergisiIstisnaSiniri, setDamgaVergisiIstisnaSiniri] =useState(151.82);
  const [degreeOdemeyeEsas, setDegreeOdemeyeEsas] = useState(1 || "");
  const [kademeOdemeyeEsas, setKademeOdemeyeEsas] = useState(4);
  const [degreeEmekliligeEsas, setDegreeEmekliligeEsas] = useState(1);
  const [kademeEmekliligeEsas, setKademeEmekliligeEsas] = useState(4);
  const [medeniHali, setMedeniHali] = useState("Evli / Eşi Çalışıyor");
  const [cocukSayisi, setCocukSayisi] = useState(0);
  const [buyukCocukSayisi, setBuyukCocukSayisi] = useState(2);
  const [raporluKucukCocukSayisi, setRaporluKucukCocukSayisi] = useState(0);
  const [raporluBuyukCocukSayisi, setRaporluBuyukCocukSayisi] = useState(0);
  const [sendika, setSendika] = useState("Evet - %2'den Büyük");
  const [bireyselEmeklilik, setBireyselEmeklilik] = useState("Hayır");
  const [ilksanUyelik, setIlksanUyelik] = useState("Hayır");
  const [polsanUyelik, setPolsanUyelik] = useState("Hayır");
  const [engellilikDurumu, setEngellilikDurumu] = useState("Hayır");
  const [saglikNisbiOdemeVergiDilimi, setSaglikNisbiOdemeVergiDilimi] = useState("0.27");
  const [tkysYetkisi, setTkysYetkisi] = useState("Hayır");
  const [yabanciDilTazminatPuani, setYabanciDilTazminatPuani] = useState(0.0);
  const [gorevIli, setGorevIli] = useState("Kayseri");
  const [gorevIlcesi, setGorevIlcesi] = useState("Melikgazi");
  const [gorevIlleri, setGorevIlleri] = useState([]); // List of provinces (Il)
  const [ilceler, setIlceler] = useState([]); // List of districts based on selected province
  const [akademikTesvikPuani, setAkademikTesvikPuani] = useState(0.0);
  const [bolgeTazminatiPuani, setBolgeTazminatiPuani] = useState(0);
  const [sosyalDengeTazminatiPuani, setSosyalDengeTazminatiPuani] = useState(0);
  const [yemekYardimiGunSayisi, setYemekYardimiGunSayisi] = useState(0);
  const [egitimTazminatiPuani, setEgitimTazminatiPuani] = useState(0.0);
  const [ekDersUcreti, setEkDersUcreti] = useState(0.0);
  const [raporluGunSayisi, setRaporluGunSayisi] = useState(0);
  const [ozelSaglikSigortasiVergiIndirimiTutari,setOzelSaglikSigortasiVergiIndirimiTutari] = useState(0.0);
  const navigate = useNavigate();
  const years = Array.from({ length: 10 }, (_, i) => 2024 + i);
  const months = ["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık","Seçiniz..."];
  const kadroTurleri = [    "Kadrolu",    "Sözleşmeli (Derece Kademe 0/1 Seç.)",    "Açıktan Vekil",  ];
  const kanunlar = ["5510", "5434"];
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const iller = Array.from(
      new Set(MaasGelistirmeOdenegi.map((item) => item.Il))
    );
    setGorevIlleri(iller);
  }, []);

  useEffect(() => {
    if (gorevIli) {
      const ilcelerList = MaasGelistirmeOdenegi.filter(
        (item) => item.Il === gorevIli
      ).map((item) => item.Ilce);
      setIlceler(ilcelerList);

      // If "Besni" isn't an available district under the selected province, set the district to the first in the list
      if (!ilcelerList.includes(gorevIlcesi)) {
        setGorevIlcesi(ilcelerList[0] || "");
      }
    } else {
      setIlceler([]);
    }
  }, [gorevIli]);

  //personel bilgileri
  const [kadroTuru, setKadroTuru] = useState("Kadrolu");
  const [kidemYili, setKidemYili] = useState(6);

  const [gecenAylarVergiMatrahiToplami, setGecenAylarVergiMatrahiToplami] = useState(0);
  const [ekTazminatGirdi, setEkTazminatGirdi] = useState(0.0);
  const [ilaveUcretGirdi, setIlaveUcretGirdi] = useState(0.0);
  const [kefaletKesintisi, setKefaletKesintisi] = useState(0.0);
  const [kesintiKiraIcraNafaka, setKesintiKiraIcraNafaka] = useState(0.0);

  //Tazminatlar





  //kesintiler



  

  const [aylikKatsayisi, setAylikKatsayisi] = useState(0.760871);
  const [yanOdemeKatsayisi, setYanOdemeKatsayisi] = useState(0.241297);
  const [tabanAylikKatsayisi, setTabanAylikKatsayisi] = useState(11.909083);
  const [enYuksekDevletMemuruAyligi, setEnYuksekDevletMemuruAyligi] = useState(8624.06);
  const [asgariUcretNet, setAsgariUcretNet] = useState(17002.12);
  const [asgariUcretBrut, setAsgariUcretBrut] = useState(20002.5);
  const [tabanAylikGosterge, setTabanAylikGosterge] = useState(1000);
  const [kidemAylikGosterge, setKidemAylikGosterge] = useState(20);
  const [aileYardimiGosterge, setAileYardimiGosterge] = useState(2273);
  const [cocukYardimiGosterge, setCocukYardimiGosterge] = useState(250);
  const [ilaveOd37540, setIlaveOd37540] = useState(15965);
  const [onBesYuzde, setOnBesYuzde] = useState(110000);
  const [yirmiYuzde, setYirmiYuzde] = useState(230000);
  const [yirmiYediYuzde, setYirmiYediYuzde] = useState(870000);
  const [otuzBesYuzde, setOtuzBesYuzde] = useState(3000000);
  const [kirkYuzde, setKirkYuzde] = useState(3000000); // The value is ">3000000" in both terms, but you can handle it with the same value
  const [birinciDereceEngel, setBirinciDereceEngel] = useState(6900);
  const [ikinciDereceEngel, setIkinciDereceEngel] = useState(4000);
  const [ucuncuDereceEngel, setUcuncuDereceEngel] = useState(1700);

  const [calculatedValues, setCalculatedValues] = useState(null);



  useEffect(() => {
    if (gorevIli) {
      const ilcelerList = MaasGelistirmeOdenegi.filter(
        (item) => item.Il === gorevIli
      ).map((item) => item.Ilce);
      setIlceler(ilcelerList);

      // If "Besni" isn't an available district under the selected province, set the district to the first in the list
      if (!ilcelerList.includes(gorevIlcesi)) {
        setGorevIlcesi(ilcelerList[0] || "");
      }
    } else {
      setIlceler([]);
    }
  }, [gorevIli]);



  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;

    setMonth(selectedMonth);

    if (selectedMonth <= 6) {
      // 1st Term
      setAylikKatsayisi(0.760871);
      setYanOdemeKatsayisi(0.241297);
      setTabanAylikKatsayisi(11.909083);
      setEnYuksekDevletMemuruAyligi(9500 * 0.760871);
      setAsgariUcretNet(17002.12);
      setAsgariUcretBrut(20002.5);
      setTabanAylikGosterge(1000);
      setKidemAylikGosterge(20);
      setAileYardimiGosterge(2273);
      setCocukYardimiGosterge(250);
      setIlaveOd37540(15965);
      setOnBesYuzde(110000);
      setYirmiYuzde(230000);
      setYirmiYediYuzde(870000);
      setOtuzBesYuzde(3000000);
      setKirkYuzde(3000000);
      setBirinciDereceEngel(6900);
      setIkinciDereceEngel(4000);
      setUcuncuDereceEngel(1700);
    } else {
      // 2nd Term
      setAylikKatsayisi(0.907796);
      setYanOdemeKatsayisi(0.287892);
      setTabanAylikKatsayisi(14.208727);
      setEnYuksekDevletMemuruAyligi(9500 * 0.907796);
      setAsgariUcretNet(17002.12);
      setAsgariUcretBrut(20002.5);
      setTabanAylikGosterge(1000);
      setKidemAylikGosterge(20);
      setAileYardimiGosterge(2063);
      setCocukYardimiGosterge(227);
      setIlaveOd37540(15965);
      setOnBesYuzde(110000);
      setYirmiYuzde(230000);
      setYirmiYediYuzde(870000);
      setOtuzBesYuzde(3000000);
      setKirkYuzde(3000000);
      setBirinciDereceEngel(6900);
      setIkinciDereceEngel(4000);
      setUcuncuDereceEngel(1700);
    }
  };

  useEffect(() => {

    let ay = months[month - 1].toString();
    console.log("ay", ay);
    if (month !== 13) {
    if (month) {
      let deger = MaasVergiIstisnaTutari[ay]["gelir"];
      let deger2 = MaasVergiIstisnaTutari[ay]["damga"];
      setGelirVergisiAGIIstisnaSiniri(deger);
      setDamgaVergisiIstisnaSiniri(deger2);
    }
  }
  }, [month]);



  const Yuvarla = (sayi) => {  
    return Math.round(sayi * 100) / 100;
  };

  const EkGostergeBagliOranHesapla = (EkGosterge) => {
    if (EkGosterge >= 8400) {
      return 255;
    } else if (EkGosterge >= 7800) {
      return  215;
    } else if (EkGosterge >= 7000) {
      return  195;
    } else if (EkGosterge >= 5400) {
      return  165;
    } else if (EkGosterge >= 3600) {
      return  145;
    } else if (EkGosterge >= 2800) {
      return  85;
    } else if (EkGosterge < 2800) {
      return  55;
    } else {
      return  0;
    }
  };

  const KidemAylikHesapla = (selectedPersonel) => {
    if (kadroTuru === "Açıktan Vekil") {
      return 0;
    } else if (kadroTuru === "Kadrolu" && selectedPersonel.hek === 657) {
      if (kidemYili > 25) {
        return aylikKatsayisi * kidemAylikGosterge * 25;
      } else {
        return aylikKatsayisi * kidemAylikGosterge * kidemYili;
      }
    }
    return 0;

  };

  
  const TabanAylikHesapla = (selectedPersonel) => {
    if (kadroTuru === "Kadrolu" && selectedPersonel.hek === 657) {
      return tabanAylikKatsayisi * tabanAylikGosterge;
    } else if (kadroTuru === "Açıktan Vekil" && selectedPersonel.hek === 657) {
      return tabanAylikKatsayisi * tabanAylikGosterge * (2 / 3);
    }
    return 0;
  };


  const ekGostergeAyligiHesapla = (selectedPersonel,oeEkGosterge) => {
    if (kadroTuru === "Kadrolu") {
      if (selectedPersonel.hek === 657) {return aylikKatsayisi * oeEkGosterge;}
    } else if (kadroTuru === "Açıktan Vekil") {
      if (selectedPersonel.hek === 657) {return aylikKatsayisi * oeEkGosterge * (2 / 3);}
    }
    return 0;
  }

  const TopSoDIkramHesapla = () => {
    if (sendika === "Hayır") {
      return 0;
    } else if (sendika === "Evet - %2'den Küçük") {
      return  aylikKatsayisi * 707;
    } else if (sendika === "Evet - %2'den Büyük") {
      return  aylikKatsayisi * 707;
    } else if (sendika === "Evet - %2'den Küçük Yerel Yön.") {
      return  aylikKatsayisi * 707;
    } else if (sendika === "Evet - %2'den Büyük Yerel Yön.") {
      return  aylikKatsayisi * 707;
    }

  }

  const SabitEkNisbiOdemeHesapla = (selectedPersonel) => {
    console.log("------saglikNisbiOdemeVergiDilimi: ",saglikNisbiOdemeVergiDilimi);
    if (kadroTuru === "Açıktan Vekil") {
      return 0;
    } else if (selectedPersonel.hek === 666) {
      return 0;
    } else if (selectedPersonel.hek === 657) {
      if (saglikNisbiOdemeVergiDilimi === "Hayır") {
        return (enYuksekDevletMemuruAyligi * selectedPersonel.ekOdeme) / 100;
      } else {
        return (Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ekOdeme) / 100 )-
            Yuvarla(Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ekOdeme) / 100) *
              0.00759)) /
          Yuvarla(1 - Number(saglikNisbiOdemeVergiDilimi) - 0.00759);
      }
    }
  }

  const AylikTutarHesapla = (selectedPersonel,oeGosterge) => {
    if (kadroTuru === "Kadrolu" && selectedPersonel.hek === 657) {
      return aylikKatsayisi * oeGosterge;
    } else if (
      kadroTuru === "Açıktan Vekil" &&
      selectedPersonel.hek === 657
    ) {
      return aylikKatsayisi * oeGosterge * (2 / 3);
    }
    return 0;
  }

  const YanOdemeAyligiHesapla = (selectedPersonel) => {

    let aydakigun = new Date(2024, month, 0).getDate();
    if (selectedPersonel.hek === 657) {
      return Yuvarla(yanOdemeKatsayisi * selectedPersonel.yanOdP) -
        Yuvarla((Yuvarla(yanOdemeKatsayisi * selectedPersonel.yanOdP) / aydakigun) *
          raporluGunSayisi *
          0.25);
    }
    return 0;
  }

  const AileYardimiHesapla = () => {
    if (medeniHali === "Bekar") {
      return 0;
    } else if (medeniHali === "Evli / Eşi Çalışmıyor") {
      return  aylikKatsayisi*aileYardimiGosterge;
    } else if (medeniHali === "Evli / Eşi Çalışıyor") {
      return 0;
    }
    return 0;
  }

  const OzelHizmetTazminatiHesapla = (selectedPersonel) => {
    if (selectedPersonel.hek === 666) {
      return 0;
    } else if (selectedPersonel.hek === 657) {
      let aydakigunsayisi = new Date(2024, month, 0).getDate();
      return (Yuvarla(enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) / 100) -
        Yuvarla((Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) /100) /
          aydakigunsayisi) *
          raporluGunSayisi *
          0.25);
    }
    return 0;
  }

  const CocukYardimiHesapla = () => {
    return aylikKatsayisi*(
      cocukSayisi * 2 * cocukYardimiGosterge +
      buyukCocukSayisi * cocukYardimiGosterge +
      raporluKucukCocukSayisi * 2 * 1.5 * cocukYardimiGosterge +
      raporluBuyukCocukSayisi * 1.5 * cocukYardimiGosterge);
  }

  const EmekliKesenegiMatrahiHesapla = (selectedPersonel,denetimTazminati,sozlesmeUcreti,eeGosterge,oeGosterge,oeEkGosterge,oeEkGostergeBagliOran) => {
    let matrah = 0;
    let gun = new Date(2024, month, 0).getDate();
    if (kadroTuru === "Sözleşmeli (Derece Kademe 0/1 Seç.)") {
      matrah = Number(sozlesmeUcreti);
    } else if (kadroTuru === "Açıktan Vekil") {
      if (kanun === "5434") {
        matrah =
          Yuvarla((aylikKatsayisi * eeGosterge * 2) / 3 )+
          Yuvarla((aylikKatsayisi * oeEkGosterge * 2) / 3 )+
          Yuvarla((tabanAylikKatsayisi * tabanAylikGosterge * 2) / 3 )+
          Yuvarla((enYuksekDevletMemuruAyligi * oeEkGostergeBagliOran) / 100 )+
          Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.denetimTazminati) /100 )-
          Yuvarla((Yuvarla(enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz /100) /gun) * raporluGunSayisi *0.25);
      } else if (kanun === "5510") {
        let kanunek = 0;
        if (selectedPersonel.hek === 657) {
          kanunek =
          Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) / 100);
        }

        matrah =
          Yuvarla((aylikKatsayisi * oeGosterge * 2) / 3 )+
          Yuvarla((aylikKatsayisi * oeEkGosterge * 2) / 3 )+
          Yuvarla((tabanAylikKatsayisi * tabanAylikGosterge * 2) / 3 )+
          Yuvarla((enYuksekDevletMemuruAyligi * oeEkGostergeBagliOran) / 100 )+
          Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.denetimTazminati) /100) -
          Yuvarla(Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) /100) / gun * raporluGunSayisi * 0.25 )+
          kanunek +
          Yuvarla((enYuksekDevletMemuruAyligi * denetimTazminati) / 100);
      }
    } else if (kadroTuru === "Kadrolu") {
      let kidem = 0;
      if (kidemYili > 25) {
        kidem = Yuvarla(25 * kidemAylikGosterge);
      } else {
        kidem = Yuvarla(kidemYili * kidemAylikGosterge);
      }
      if (kanun === "5434") {
        matrah =
          Yuvarla(aylikKatsayisi * eeGosterge) +
          Yuvarla(aylikKatsayisi * oeEkGosterge) +
          +kidem +
          Yuvarla((enYuksekDevletMemuruAyligi * oeEkGostergeBagliOran) / 100 )-
          Yuvarla(Yuvarla(enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) /100 /
          gun * raporluGunSayisi * 0.25);
      } else if (kanun === "5510") {
        if (selectedPersonel.akademik === "Hayır") {
          let ozhiz = 0.0;
          if (selectedPersonel.hek === 657) {ozhiz = Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) / 100);}

          matrah =
          Yuvarla(aylikKatsayisi * oeGosterge )+
          Yuvarla(aylikKatsayisi * oeEkGosterge) +
          Yuvarla(tabanAylikKatsayisi * tabanAylikGosterge)+
            kidem +
            ozhiz +
            Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.denetimTazminati) /100) -
            Yuvarla(Yuvarla(((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) /100) /
            gun) *raporluGunSayisi *0.25);
        }
        if (selectedPersonel.akademik === "Evet") {
          matrah =
            Yuvarla(aylikKatsayisi * oeGosterge) +
            Yuvarla(aylikKatsayisi * oeEkGosterge) +
            Yuvarla(tabanAylikKatsayisi * tabanAylikGosterge)+
            kidem +
            Yuvarla((enYuksekDevletMemuruAyligi * denetimTazminati) / 100 )+
            Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.universiteOdenegi) / 100) -
            Yuvarla(Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) /100) /
             gun * raporluGunSayisi * 0.25);

          if (selectedPersonel.hek === 657) {
            matrah =
              matrah +
              Yuvarla(aylikKatsayisi * selectedPersonel.makamTazminati) +
              Yuvarla(aylikKatsayisi * selectedPersonel.temsilGorevTazminati *80 /100);
          }
        }
      }
    }
    return matrah;


  };
  const EmekliKesenegiKarsiligiHesapla = (emekliKesenegiMatrahi,pekFarkiEmekliKesenegiMatrahi) => {
    let kesinti = 0.0;
    if (      kadroTuru === "Kadrolu" ||      kadroTuru === "Sözleşmeli (Derece Kademe 0/1 Seç.)"    ) {
      if (kanun === "5434") {
        kesinti = Math.round(emekliKesenegiMatrahi * 0.2 * 100) / 100;
      } else if (kanun === "5510") {
        kesinti = Math.round((emekliKesenegiMatrahi * 0.11 + pekFarkiEmekliKesenegiMatrahi*0.2) * 100) / 100;
      }
    } else if (kadroTuru === "Açıktan Vekil") {
      if (kanun === "5434") {
        kesinti = Math.round(emekliKesenegiMatrahi * 0.2 * 100) / 100;
      } else if (kanun === "5510") {
        kesinti = Math.round(emekliKesenegiMatrahi * 0.11 * 100) / 100;
      }
    } else {
      console.error("Hatalı giriş yaptınız!");
      return 0; // Exit the function if there's an error
    }
    return kesinti;
  };

  const EmekliKesenegiDevletHesapla = (emekliKesenegiMatrahi) => {
    let devlet = 0.0;
    if (
      kadroTuru === "Kadrolu" ||
      kadroTuru === "Sözleşmeli (Derece Kademe 0/1 Seç.)"
    ) {
      if (kanun === "5434") {
        devlet = Math.round(emekliKesenegiMatrahi * 0.2 * 100) / 100;
      } else if (kanun === "5510") {
        devlet = Math.round(emekliKesenegiMatrahi * 0.11 * 100) / 100;
        if (emekliKesenegiMatrahi < asgariUcretBrut) {
          devlet =
            devlet +
            Math.round((asgariUcretBrut - emekliKesenegiMatrahi) * 0.11 * 100) /
              100;
        }
      }
    } else if (kadroTuru === "Açıktan Vekil") {
      if (kanun === "5434") {
        devlet = Math.round(emekliKesenegiMatrahi * 0.2 * 100) / 100;
      } else if (kanun === "5510") {
        devlet = Math.round(emekliKesenegiMatrahi * 0.11 * 100) / 100;
      }
    } else {
      console.error("Hatalı giriş yaptınız!");
      return 0; // Exit the function if there's an error
    }
    return devlet;

  };

  const GenelSaglikSigortasiMatrahiHesapla = (selectedPersonel,sozlesmeUcreti,denetimTazminati,oeGosterge,oeEkGosterge,eeGosterge, eeEkGosterge,eeEkGostergeBagliOran ) => {
    let matrah = 0;
    let gun = new Date(2024, month, 0).getDate();
    if (kadroTuru === "Sözleşmeli (Derece Kademe 0/1 Seç.)") {
      matrah = Number(sozlesmeUcreti);
    } else if (kadroTuru === "Açıktan Vekil") {
      if (kanun === "5434") {
        matrah =
          Yuvarla((aylikKatsayisi * eeGosterge * 2) / 3 )+
          Yuvarla((aylikKatsayisi * eeEkGosterge * 2) / 3 )+
          Yuvarla((tabanAylikKatsayisi * tabanAylikGosterge * 2) / 3 )+
          Yuvarla((enYuksekDevletMemuruAyligi * eeEkGostergeBagliOran) / 100 )+
          Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.denetimTazminati) / 100 )-
          Yuvarla((Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) /100) / gun) *
            raporluGunSayisi * 0.25);
      } else if (kanun === "5510") {
        let kanunek = 0;
        if (selectedPersonel.hek === 657) {
          kanunek =
            (enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) / 100;
        }
        matrah =
          Yuvarla((aylikKatsayisi * oeGosterge * 2) / 3) +
          Yuvarla((aylikKatsayisi * oeEkGosterge * 2) / 3 )+
          Yuvarla((tabanAylikKatsayisi * tabanAylikGosterge * 2) / 3 )+
          Yuvarla(kanunek) +
          Yuvarla((enYuksekDevletMemuruAyligi * denetimTazminati) / 100) -
          Yuvarla((Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) / 100) / gun) *raporluGunSayisi * 0.25);
      }
    } else if (kadroTuru === "Kadrolu") {
      let kidem = 0;
      if (kidemYili > 25) {
        kidem = 25 * kidemAylikGosterge;
      } else {
        kidem = kidemYili * kidemAylikGosterge;
      }
      if (kanun === "5434") {
        matrah =
          Yuvarla(aylikKatsayisi * eeGosterge) +
          Yuvarla(aylikKatsayisi * eeEkGosterge) +
          Yuvarla(tabanAylikKatsayisi * tabanAylikGosterge)+
          Yuvarla(kidem) +
          Yuvarla((enYuksekDevletMemuruAyligi * eeEkGostergeBagliOran) / 100) -
          Yuvarla((Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) /100) /gun) *raporluGunSayisi *0.25);
      } else if (kanun === "5510") {
        if (selectedPersonel.akademik === "Hayır") {
          let ozhiz = 0.0;

          if (selectedPersonel.hek === 657) {
            ozhiz =
              (enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) / 100;
          }
          matrah =
            Yuvarla(aylikKatsayisi * oeGosterge )+
            Yuvarla(aylikKatsayisi * oeEkGosterge )+
            Yuvarla(tabanAylikKatsayisi * tabanAylikGosterge )+
            Yuvarla(kidem )+
            Yuvarla(ozhiz )+
            Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.denetimTazminati) / 100 )-
            Yuvarla((Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) /100) / gun) *raporluGunSayisi *0.25);
        }
        if (selectedPersonel.akademik === "Evet") {
          matrah =
            Yuvarla(aylikKatsayisi * oeGosterge )+
            Yuvarla(aylikKatsayisi * oeEkGosterge )+
            Yuvarla(tabanAylikKatsayisi * tabanAylikGosterge )+
            Yuvarla(kidem )+
            Yuvarla((enYuksekDevletMemuruAyligi * denetimTazminati) / 100 )+
            Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.universiteOdenegi) / 100 )-
            Yuvarla((Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.ozelHiz) / 100) /gun) *raporluGunSayisi *0.25);

          if (selectedPersonel.hek === 657) {
            matrah =
              matrah +
              Yuvarla(aylikKatsayisi * selectedPersonel.makamTazminati) +
              Yuvarla((aylikKatsayisi * selectedPersonel.temsilGorevTazminati * 80) /100);
          }
        }
      }
    }
    return matrah;
  };
  const EmekliKesenegiKisiHesapla = (genelSaglikSigortasiMatrahi,emekliKesenegiMatrahi) => {
    let kisi = 0.0;

    if (kanun === "5434") {
      kisi = Math.round(genelSaglikSigortasiMatrahi * 0.16 * 100) / 100;
    } else if (kanun === "5510") {
      kisi = Math.round(genelSaglikSigortasiMatrahi * 0.09 * 100) / 100;
    } else {
      return; // Exit the function if there's an error
    }
    if (genelSaglikSigortasiMatrahi > emekliKesenegiMatrahi) {
      if (kanun === "5510") {
        kisi =Math.round(
            (kisi +(genelSaglikSigortasiMatrahi - emekliKesenegiMatrahi) * 0.11) * 100) / 100;
      } else if (kanun === "5434") {
        kisi =
          Math.round(
            (kisi +
              (genelSaglikSigortasiMatrahi - emekliKesenegiMatrahi) * 0.2) *
              100
          ) / 100;
      }
    }
    return kisi;
  };

  const AylikVergiMatrahiHesapla = (
    aylikTutar,
    ekGostergeAyligi,
    tabanAylik,
    kidemAylik,
    yanOdemeAyligi,
    sozlesmeUcreti,
    ilaveUcret,
    sosyalDengeTazminati,
    ucretTutari,
    idariGorevOdenegi,
    emekliKesenegiKisi,
    genSagSigPirKisi,
    sendikaAidati,
    ekDersUcreti,
    saglikNisbiOdemeVergiDilimi,
    sabitEkOdNisb40,
    engellilikDurumu
  ) => {
    let matrah = 0;
    matrah =
      (isNaN(Number(aylikTutar)) ? 0 : Number(aylikTutar)) +
      (isNaN(Number(ekGostergeAyligi)) ? 0 : Number(ekGostergeAyligi)) +
      (isNaN(Number(tabanAylik)) ? 0 : Number(tabanAylik)) +
      (isNaN(Number(kidemAylik)) ? 0 : Number(kidemAylik)) +
      (isNaN(Number(yanOdemeAyligi)) ? 0 : Number(yanOdemeAyligi)) +
      (isNaN(Number(sabitEkOdNisb40)) ? 0 : Number(sabitEkOdNisb40)) +
      (isNaN(Number(sozlesmeUcreti)) ? 0 : Number(sozlesmeUcreti)) +
      (isNaN(Number(ilaveUcret)) ? 0 : Number(ilaveUcret)) +
      (isNaN(Number(sosyalDengeTazminati)) ? 0 : Number(sosyalDengeTazminati)) +
      (isNaN(Number(ucretTutari)) ? 0 : Number(ucretTutari)) +
      (isNaN(Number(idariGorevOdenegi)) ? 0 : Number(idariGorevOdenegi)) -
      (isNaN(Number(emekliKesenegiKisi)) ? 0 : Number(emekliKesenegiKisi)) -
      (isNaN(Number(genSagSigPirKisi)) ? 0 : Number(genSagSigPirKisi)) -
      (isNaN(Number(sendikaAidati)) ? 0 : Number(sendikaAidati));


    if (engellilikDurumu === "1. Derece %80") {
      matrah = matrah - birinciDereceEngel;
    } else if (engellilikDurumu === "2. Derece %70") {
      matrah = matrah - ikinciDereceEngel;
    } else if (engellilikDurumu === "3. Derece %40") {
      matrah = matrah - ucuncuDereceEngel;
    }
    return matrah;
  };



  const HesaplananGelirVergisiHesapla = (    aylikVergiMatrahi) => {

   const toplamMatrah = Number(aylikVergiMatrahi) + Number(gecenAylarVergiMatrahiToplami);
    let vergi = 0.0;
    if (toplamMatrah <= onBesYuzde) {
      vergi = Math.round(aylikVergiMatrahi*0.15*100) / 100;
    } else if (
      toplamMatrah > onBesYuzde &&
      gecenAylarVergiMatrahiToplami <= onBesYuzde
    ) {
      vergi =
        Math.round(
          (onBesYuzde - gecenAylarVergiMatrahiToplami) * 0.15 +
            (toplamMatrah - onBesYuzde) * 0.2 * 100
        ) / 100;
    } else if (toplamMatrah <= yirmiYuzde) {
      vergi = Math.round(aylikVergiMatrahi * 0.2 * 100) / 100;
    } else if (
      toplamMatrah > yirmiYuzde &&
      gecenAylarVergiMatrahiToplami <= yirmiYuzde
    ) {
      vergi =
        Math.round(
          ((yirmiYuzde - gecenAylarVergiMatrahiToplami) * 0.2 +
            (toplamMatrah - yirmiYuzde) * 0.27) *
            100
        ) / 100;
    } else if (toplamMatrah <= yirmiYediYuzde) {
      vergi = Math.round(aylikVergiMatrahi * 0.27 * 100) / 100;
    } else if (
      toplamMatrah > yirmiYediYuzde &&
      gecenAylarVergiMatrahiToplami <= yirmiYediYuzde
    ) {
      vergi =
        Math.round(
          ((yirmiYediYuzde - gecenAylarVergiMatrahiToplami) * 0.27 +
            (toplamMatrah - yirmiYediYuzde) * 0.35) *
            100
        ) / 100;
    } else if (toplamMatrah <= otuzBesYuzde) {
      vergi = Math.round(aylikVergiMatrahi * 0.35 * 100) / 100;
    } else if (
      toplamMatrah > otuzBesYuzde &&
      gecenAylarVergiMatrahiToplami <= otuzBesYuzde
    ) {
      vergi =
        Math.round(
          ((otuzBesYuzde - gecenAylarVergiMatrahiToplami) * 0.35 +
            (toplamMatrah - otuzBesYuzde) * 0.4) *
            100
        ) / 100;
    } else if (toplamMatrah <= kirkYuzde) {
      vergi = Math.round(aylikVergiMatrahi * 0.4 * 100) / 100;
    }

    return vergi;
  };

  const GenSagSigPirDevletHesapla = (genelSaglikSigortasiMatrahi) => {
    let pirDevlet = 0.0;

    if (
      kadroTuru === "Kadrolu" ||
      kadroTuru === "Sözleşmeli (Derece Kademe 0/1 Seç.)"
    ) {
      if (kanun === "5434") {
        pirDevlet = Math.round(genelSaglikSigortasiMatrahi * 0.12 * 100) / 100;
      } else if (kanun === "5510") {
        pirDevlet = Math.round(genelSaglikSigortasiMatrahi * 0.075 * 100) / 100;
      }
    } else if (kadroTuru === "Açıktan Vekil") {
      if (kanun === "5434") {
        pirDevlet = Math.round(genelSaglikSigortasiMatrahi * 0.12 * 100) / 100;
      } else if (kanun === "5510") {
        if (genelSaglikSigortasiMatrahi > asgariUcretBrut) {
          pirDevlet =
            Math.round(
              (genelSaglikSigortasiMatrahi * 0.075 +
                (genelSaglikSigortasiMatrahi - asgariUcretBrut) * 0.125) *
                100
            ) / 100;
        } else {
          pirDevlet =
            Math.round(genelSaglikSigortasiMatrahi * 0.075 * 100) / 100;
        }
      }
    }

    return pirDevlet;
  };
  const GenSagSigPirKisiHesapla = (emekliKesenegiMatrahi) => {
    let pirKisi = 0.0;

    if (kanun === "5510") {
      pirKisi = Math.round(emekliKesenegiMatrahi * 0.05 * 100) / 100;
    }

    return pirKisi;
  };

  
  const damgaVergisiMatrahiHesapla = (aylikTutar,ekGostergeAyligi,tabanAylik,kidemAylik,yanOdemeAyligi,ozelHizmetTazminati,dilTazminati,sabitEkOdNisb40,topSoDIkram,fazlaCalismaUcreti,ekTazminat28B,ekTazminat,egitimOgretimTazminati,denetimTazminati,sozlesmeUcreti,ilaveOdeme375,bolgeTazminati,ilaveUcret,sosyalDengeTazminati,yemekYardimi,tazminatTutari,ucretTutari,makamTazminati,temGorTazminati,universiteOdenegi,idariGorevOdenegi,egitimOgretimOdenegi,akademikTesvikPuani,yuksekOgretimTazminati) => {
    let matrah = 0;
    matrah =
      (isNaN(Number(aylikTutar)) ? 0 : Number(aylikTutar)) +
      (isNaN(Number(ekGostergeAyligi)) ? 0 : Number(ekGostergeAyligi)) +
      (isNaN(Number(tabanAylik)) ? 0 : Number(tabanAylik)) +
      (isNaN(Number(kidemAylik)) ? 0 : Number(kidemAylik)) +
      (isNaN(Number(yanOdemeAyligi)) ? 0 : Number(yanOdemeAyligi)) +
      (isNaN(Number(ozelHizmetTazminati)) ? 0 : Number(ozelHizmetTazminati)) +
      (isNaN(Number(dilTazminati)) ? 0 : Number(dilTazminati)) +
      (isNaN(Number(sabitEkOdNisb40)) ? 0 : Number(sabitEkOdNisb40)) +
      (isNaN(Number(topSoDIkram)) ? 0 : Number(topSoDIkram)) +
      (isNaN(Number(fazlaCalismaUcreti)) ? 0 : Number(fazlaCalismaUcreti)) +
      (isNaN(Number(ekTazminat28B)) ? 0 : Number(ekTazminat28B)) +
      (isNaN(Number(ekTazminat)) ? 0 : Number(ekTazminat)) +
      (isNaN(Number(egitimOgretimTazminati))? 0: Number(egitimOgretimTazminati)) +
      (isNaN(Number(denetimTazminati)) ? 0 : Number(denetimTazminati)) +
      (isNaN(Number(sozlesmeUcreti)) ? 0 : Number(sozlesmeUcreti)) +
      (isNaN(Number(ilaveOdeme375)) ? 0 : Number(ilaveOdeme375)) +
      (isNaN(Number(bolgeTazminati)) ? 0 : Number(bolgeTazminati)) +
      (isNaN(Number(ilaveUcret)) ? 0 : Number(ilaveUcret)) +
      (isNaN(Number(sosyalDengeTazminati)) ? 0 : Number(sosyalDengeTazminati)) +
      (isNaN(Number(yemekYardimi)) ? 0 : Number(yemekYardimi)) +
      (isNaN(Number(tazminatTutari)) ? 0 : Number(tazminatTutari)) +
      (isNaN(Number(ucretTutari)) ? 0 : Number(ucretTutari)) +
      (isNaN(Number(makamTazminati)) ? 0 : Number(makamTazminati)) +
      (isNaN(Number(temGorTazminati)) ? 0 : Number(temGorTazminati)) +
      (isNaN(Number(universiteOdenegi)) ? 0 : Number(universiteOdenegi)) +
      (isNaN(Number(idariGorevOdenegi)) ? 0 : Number(idariGorevOdenegi)) +
      (isNaN(Number(egitimOgretimOdenegi)) ? 0 : Number(egitimOgretimOdenegi)) +
      (isNaN(Number(akademikTesvikPuani)) ? 0 : Number(akademikTesvikPuani)) +
      (isNaN(Number(yuksekOgretimTazminati))
        ? 0
        : Number(yuksekOgretimTazminati));

    return matrah;
  };


  const DamgaVergisiHesapla = (damgaVergisiMatrahi) => {
    const damgaVergisiHesap =
      Math.round(((damgaVergisiMatrahi * 7.59) / 1000) * 100) / 100;
    if (damgaVergisiHesap <= damgaVergisiIstisnaSiniri) {
      return 0.0;
    } else {
      const vergi =
        Math.round((damgaVergisiHesap - damgaVergisiIstisnaSiniri) * 100) / 100;
      return vergi;
    }
  };


  const KesintiToplamiHesapla = (gelirVergisi,damgaVergisi,emekliKesenegiKisi,emekliKesenegiDevlet,genSagSigPirKisi,genSagSigPirDevlet,kisaVadeliSigortaKol,ilksanKesintisi,polsanKesintisi,bireyselEmek,sendikaAidati,kefaletKesintisi,kesintiKiraIcraNafaka) => {
    let kesinti = 0;
    kesinti =
      (isNaN(Number(gelirVergisi)) ? 0 : Number(gelirVergisi)) +
      (isNaN(Number(damgaVergisi)) ? 0 : Number(damgaVergisi)) +
      (isNaN(Number(emekliKesenegiKisi)) ? 0 : Number(emekliKesenegiKisi)) +
      (isNaN(Number(emekliKesenegiDevlet)) ? 0 : Number(emekliKesenegiDevlet)) +

      (isNaN(Number(genSagSigPirKisi)) ? 0 : Number(genSagSigPirKisi)) +
      (isNaN(Number(genSagSigPirDevlet)) ? 0 : Number(genSagSigPirDevlet)) +
      (isNaN(Number(kisaVadeliSigortaKol)) ? 0 : Number(kisaVadeliSigortaKol)) +
      (isNaN(Number(ilksanKesintisi)) ? 0 : Number(ilksanKesintisi)) +
      (isNaN(Number(polsanKesintisi)) ? 0 : Number(polsanKesintisi)) +
      (isNaN(Number(bireyselEmek)) ? 0 : Number(bireyselEmek)) +
      (isNaN(Number(sendikaAidati)) ? 0 : Number(sendikaAidati)) +
      (isNaN(Number(kefaletKesintisi)) ? 0 : Number(kefaletKesintisi)) +
      (isNaN(Number(kesintiKiraIcraNafaka))? 0: Number(kesintiKiraIcraNafaka));
    return kesinti;
  };

  const SendikaAidatiHesapla = (sendikaKesintiOrani,damgaVergisiMatrahi) => {
    let aidat = 0.0;

    if (
      sendika === "Evet - %2'den Küçük" ||
      sendika === "Evet - %2'den Büyük"
    ) {
      aidat = Math.round(sendikaKesintiOrani * damgaVergisiMatrahi); // Calculating based on Sendika Kesinti Oranı
    } else if (
      sendika === "Evet - %2'den Küçük Yerel Yön." ||
      sendika === "Evet - %2'den Büyük Yerel Yön."
    ) {
      aidat = Math.round(
        (sendikaKesintiOrani *
          Math.round(tabanAylikKatsayisi * tabanAylikGosterge),
        2), // Calculating based on Sendika Kesinti Oranı
        2
      ); // Applying the calculation for Yerel Yönetim
    } else aidat = 0.0;

    return aidat;

  };



  const GelirToplamiHesapla = (aylikTutar,ekGostergeAyligi,tabanAylik,kidemAylik,yanOdemeAyligi,emekliKesenegiKarsiligi,genSagSigPirDevlet,aileYardimi,cocukYardimi,ozelHizmetTazminati,dilTazminati,sabitEkOdNisb40,topSoDIkram,fazlaCalismaUcreti,ekTazminat28B,ekTazminat,egitimOgretimTazminati,denetimTazminati,kisaVadeliSigortaKol,sozlesmeUcreti,ilaveOdeme375,bolgeTazminati,ilaveUcret,sosyalDengeTazminati,yemekYardimi,tazminatTutari,ucretTutari,makamTazminati,temGorTazminati,universiteOdenegi,idariGorevOdenegi,egitimOgretimOdenegi,akademikTesvik,gelistirmeOdenegi,yuksekOgretimTazminati) => {
    let gelirtoplamis = 0;

    gelirtoplamis =

    (isNaN(Number(aylikTutar))?0 : Number(aylikTutar)) +
    (isNaN(Number(ekGostergeAyligi))?0 : Number(ekGostergeAyligi)) +
    (isNaN(Number(tabanAylik))?0 : Number(tabanAylik)) +
    (isNaN(Number(kidemAylik))?0 : Number(kidemAylik)) +
    (isNaN(Number(yanOdemeAyligi))?0 : Number(yanOdemeAyligi)) +
    (isNaN(Number(emekliKesenegiKarsiligi))?0 : Number(emekliKesenegiKarsiligi)) +
    (isNaN(Number(genSagSigPirDevlet))?0 : Number(genSagSigPirDevlet)) +
    (isNaN(Number(aileYardimi))?0 : Number(aileYardimi)) +
    (isNaN(Number(cocukYardimi))?0 : Number(cocukYardimi)) +        
    (isNaN(Number(ozelHizmetTazminati))?0 : Number(ozelHizmetTazminati)) +
    (isNaN(Number(dilTazminati))?0 : Number(dilTazminati)) +
    (isNaN(Number(sabitEkOdNisb40))?0 : Number(sabitEkOdNisb40)) +
    (isNaN(Number(topSoDIkram))?0 : Number(topSoDIkram)) +
    (isNaN(Number(fazlaCalismaUcreti))?0 : Number(fazlaCalismaUcreti)) +
    (isNaN(Number(ekTazminat28B))?0 : Number(ekTazminat28B)) +
    (isNaN(Number(ekTazminat))?0 : Number(ekTazminat)) +
    (isNaN(Number(egitimOgretimTazminati))?0 : Number(egitimOgretimTazminati)) +
    (isNaN(Number(denetimTazminati))?0 : Number(denetimTazminati)) +
    (isNaN(Number(kisaVadeliSigortaKol))?0 : Number(kisaVadeliSigortaKol)) +
    (isNaN(Number(sozlesmeUcreti))?0 : Number(sozlesmeUcreti)) +
    (isNaN(Number(ilaveOdeme375))?0 : Number(ilaveOdeme375)) +
    (isNaN(Number(bolgeTazminati))?0 : Number(bolgeTazminati)) +
    (isNaN(Number(ilaveUcret))?0 : Number(ilaveUcret)) +
    (isNaN(Number(sosyalDengeTazminati))?0 : Number(sosyalDengeTazminati)) +
    (isNaN(Number(yemekYardimi))?0 : Number(yemekYardimi)) +
    (isNaN(Number(tazminatTutari))?0 : Number(tazminatTutari)) +
    (isNaN(Number(ucretTutari))?0 : Number(ucretTutari)) +
    (isNaN(Number(makamTazminati))?0 : Number(makamTazminati)) +
    (isNaN(Number(temGorTazminati))?0 : Number(temGorTazminati)) +
    (isNaN(Number(universiteOdenegi))?0 : Number(universiteOdenegi)) +
    (isNaN(Number(idariGorevOdenegi))?0 : Number(idariGorevOdenegi)) +
    (isNaN(Number(egitimOgretimOdenegi))?0 : Number(egitimOgretimOdenegi)) +
    (isNaN(Number(akademikTesvik))?0 : Number(akademikTesvik)) +
    (isNaN(Number(gelistirmeOdenegi))?0 : Number(gelistirmeOdenegi)) +
    (isNaN(Number(yuksekOgretimTazminati))?0 : Number(yuksekOgretimTazminati)) ;
    return gelirtoplamis;
  };


  const SendikaKesintiOraniHesapla = () => {
    let kesinti = 0.0;
    if (
      sendika === "Evet - %2'den Küçük" ||
      sendika === "Evet - %2'den Büyük"
    ) {
      kesinti = 0.005;
    } else if (
      sendika === "Evet - %2'den Küçük Yerel Yön." ||
      sendika === "Evet - %2'den Büyük Yerel Yön."
    ) {
      kesinti = 0.015;
    }
    return kesinti;

  }

  const Hesapla = () => {

    let selectedPersonel = personel.find((p) => p.Unvan === unvan && p.derece ===  Number(degreeOdemeyeEsas));
    //Tazminatlar 
    let yuksekOgretimTazminati = Yuvarla((selectedPersonel.yuksekOgretim * enYuksekDevletMemuruAyligi) / 100) || 0;
    let akademikTesvik =  Yuvarla((((selectedPersonel.akademikTesvik * enYuksekDevletMemuruAyligi) /100) *akademikTesvikPuani) /100) || 0;
    let bolgeTazminati = Yuvarla((enYuksekDevletMemuruAyligi * bolgeTazminatiPuani) / 100) || 0;
    let dilTazminati = Yuvarla(aylikKatsayisi * yabanciDilTazminatPuani) || 0;
    let ekTazminat = Yuvarla((aylikKatsayisi * 9500 * selectedPersonel.ekTazminat) / 100)|| 0;
    let egitimOgretimTazminati = Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.egitimOgretimTazminati) / 100)|| 0;
    
    let universiteOdenegi=Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.universiteOdenegi) / 100)|| 0;
    let egitimOgretimOdenegi = Yuvarla(enYuksekDevletMemuruAyligi * selectedPersonel.egitimOgretimOdenegi)|| 0;
    let gelistirmeOdenegiPuani =  MaasGelistirmeOdenegi.find((item) => item.Il === gorevIli && item.Ilce === gorevIlcesi)?.GelistirmeOdenegiPuani || 0; 
    let ekTazminat28B = ekTazminatGirdi;
    let fazlaCalismaUcreti = Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.fazlaCalismaUcretiOrani) / 100)|| 0;

    //----
    let sozlesmeUcreti =0;
    if (selectedPersonel.kadroTuru !== "Sözleşmeli (Derece Kademe 0/1 Seç.)") {if (month <= 6) {sozlesmeUcreti = selectedPersonel.birinciDonem;} else {sozlesmeUcreti = selectedPersonel.ikinciDonem;}}
    //----


    let temGorTazminati = 0;
    let makamTazminati = 0;
    let denetimTazminati = 0;
    if (selectedPersonel.hek === 657) {
      makamTazminati = Yuvarla(aylikKatsayisi * selectedPersonel.makamTazminati);
      denetimTazminati = Yuvarla((enYuksekDevletMemuruAyligi * selectedPersonel.denetimTazminati) / 100)|| 0;
      if (selectedPersonel.akademik === "Evet") {
        temGorTazminati = Yuvarla(aylikKatsayisi * selectedPersonel.temsilGorevTazminati);
      }
    }
    let tazminatTutari = 0;
    let ucretTutari = 0;
    if (selectedPersonel.hek === 666) {
      tazminatTutari = Yuvarla(aylikKatsayisi * selectedPersonel.tazminatGostergesi);
      ucretTutari = Yuvarla(aylikKatsayisi * selectedPersonel.ucretGostergesi);
    }
    //Odemeye Esas Gösterge
    let oeGosterge = Maas657EkGosterge[degreeOdemeyeEsas.toString()][kademeOdemeyeEsas.toString()];
    let eeGosterge = Maas657EkGosterge[degreeEmekliligeEsas.toString()][kademeEmekliligeEsas.toString()];

    let oeEkGosterge = 0;
    if (kadroTuru !== "Açıktan Vekil" && degreeOdemeyeEsas>3 ) {oeEkGosterge = selectedPersonel.ekGos;}

    let eeEkGosterge = 0;
    if (kadroTuru !== "Açıktan Vekil" && degreeEmekliligeEsas>3 ) {eeEkGosterge = selectedPersonel.ekGos;}
    if (eeEkGosterge <= oeEkGosterge) {eeEkGosterge = oeEkGosterge;} // Eğer Emekliğe esas ek gösterge ödemeye esas ek göstergeden büyükse ödemeye esas olanı al


    let oeEkGostergeBagliOran =  EkGostergeBagliOranHesapla(oeEkGosterge);
    let eeEkGostergeBagliOran =  EkGostergeBagliOranHesapla(eeEkGosterge);

    let ekGostergeAyligi = ekGostergeAyligiHesapla(selectedPersonel,oeEkGosterge);
    let tabanAylik = Yuvarla(TabanAylikHesapla(selectedPersonel));
    let kidemAylik = Yuvarla(KidemAylikHesapla(selectedPersonel));
    let topSoDIkram =TopSoDIkramHesapla();
    let ilaveUcret = Yuvarla(ilaveUcretGirdi);
    let yemekYardimi = Yuvarla(aylikKatsayisi * yemekYardimiGunSayisi * 100);
    let idariGorevOdenegi = Yuvarla(((oeGosterge * aylikKatsayisi + aylikKatsayisi * oeEkGosterge) *selectedPersonel.idariGorevOdenegi) /100);
    let sosyalDengeTazminati = Yuvarla((enYuksekDevletMemuruAyligi * sosyalDengeTazminatiPuani) / 100);

    let IlaveOdeme375 = 0;

    if (kadroTuru !== "Açıktan Vekil") {IlaveOdeme375 = Yuvarla(aylikKatsayisi*ilaveOd37540);}


    let sabitEkOdNisb40 = Yuvarla(SabitEkNisbiOdemeHesapla(selectedPersonel));
    let aylikTutar = Yuvarla(AylikTutarHesapla(selectedPersonel,oeGosterge));
    let yanOdemeAyligi = Yuvarla(YanOdemeAyligiHesapla(selectedPersonel));
    let aileYardimi = Yuvarla(AileYardimiHesapla());

    let gelistirmeOdenegi = 0;
    if (selectedPersonel.akademik  === "Evet") {gelistirmeOdenegi = Yuvarla(((Yuvarla(aylikKatsayisi * oeGosterge) + Yuvarla(aylikKatsayisi * oeEkGosterge)) * gelistirmeOdenegiPuani) /100);}
    
    //kullanılmıyor
    let asgariGecIndYarCocukSayisi = Number(cocukSayisi) +Number(buyukCocukSayisi) +Number(raporluKucukCocukSayisi) +Number(raporluBuyukCocukSayisi) || 0;
    //--


    let ozelHizmetTazminati = Yuvarla(OzelHizmetTazminatiHesapla(selectedPersonel));
    let cocukYardimi = Yuvarla(CocukYardimiHesapla());
    let kisaVadeliSigortaKol = 0;
    if (kadroTuru === "Sözleşmeli (Derece Kademe 0/1 Seç.)") {kisaVadeliSigortaKol = Yuvarla(Math.max(sozlesmeUcreti, asgariUcretBrut) * 0.02);}
    let emekliKesenegiMatrahi = Yuvarla(EmekliKesenegiMatrahiHesapla(selectedPersonel,denetimTazminati,sozlesmeUcreti,eeGosterge,oeGosterge,oeEkGosterge,oeEkGostergeBagliOran));
    let ilksanKesintisi = 0;
    if (ilksanUyelik=== "Evet") {ilksanKesintisi = Yuvarla(emekliKesenegiMatrahi * 0.02);}
    let polsanKesintisi = 0;
    if (polsanUyelik === "Evet") {polsanKesintisi = Yuvarla(emekliKesenegiMatrahi * 0.09);}
    let bireyselEmek =0;
    if (bireyselEmeklilik!== "Hayır") {bireyselEmek = Yuvarla(emekliKesenegiMatrahi * (bireyselEmeklilik / 100));}


    let pekFarkiEmekliKesenegiMatrahi = 0.0;
    if (emekliKesenegiMatrahi<asgariUcretBrut) {pekFarkiEmekliKesenegiMatrahi = asgariUcretBrut - emekliKesenegiMatrahi;}
    let emekliKesenegiKarsiligi =EmekliKesenegiKarsiligiHesapla(emekliKesenegiMatrahi,pekFarkiEmekliKesenegiMatrahi);
    let emekliKesenegiDevlet = Yuvarla(EmekliKesenegiDevletHesapla(emekliKesenegiMatrahi));

    let genelSaglikSigortasiMatrahi = Yuvarla(GenelSaglikSigortasiMatrahiHesapla(selectedPersonel,sozlesmeUcreti,denetimTazminati,oeGosterge,oeEkGosterge,eeGosterge, eeEkGosterge,eeEkGostergeBagliOran));
    let genSagSigPirDevlet = Yuvarla(GenSagSigPirDevletHesapla(genelSaglikSigortasiMatrahi));

    let emekliKesenegiKisi = Yuvarla(EmekliKesenegiKisiHesapla(genelSaglikSigortasiMatrahi,emekliKesenegiMatrahi));
    let genSagSigPirKisi = Yuvarla(GenSagSigPirKisiHesapla(emekliKesenegiMatrahi));
    
    let damgaVergisiMatrahi = Yuvarla(damgaVergisiMatrahiHesapla(aylikTutar,ekGostergeAyligi,tabanAylik,kidemAylik,yanOdemeAyligi,ozelHizmetTazminati,dilTazminati,sabitEkOdNisb40,topSoDIkram,fazlaCalismaUcreti,ekTazminat28B,ekTazminat,egitimOgretimTazminati,denetimTazminati,sozlesmeUcreti,IlaveOdeme375,bolgeTazminati,ilaveUcret,sosyalDengeTazminati,yemekYardimi,tazminatTutari,ucretTutari,makamTazminati,temGorTazminati,universiteOdenegi,idariGorevOdenegi,egitimOgretimOdenegi,akademikTesvikPuani,yuksekOgretimTazminati));
    let sendikaKesintiOrani = SendikaKesintiOraniHesapla()
    let sendikaAidati = Yuvarla(SendikaAidatiHesapla(sendikaKesintiOrani,damgaVergisiMatrahi));
    let aylikVergiMatrahi = Yuvarla(AylikVergiMatrahiHesapla( aylikTutar, ekGostergeAyligi, tabanAylik, kidemAylik, yanOdemeAyligi, sozlesmeUcreti, ilaveUcret, sosyalDengeTazminati, ucretTutari, idariGorevOdenegi, emekliKesenegiKisi, genSagSigPirKisi, sendikaAidati, ekDersUcreti, saglikNisbiOdemeVergiDilimi, sabitEkOdNisb40, engellilikDurumu));
    let hesaplananGelirVergisi = Yuvarla(HesaplananGelirVergisiHesapla(aylikVergiMatrahi));
    let gelirVergisi =0.0;
    if (hesaplananGelirVergisi - gelirVergisiAGIIstisnaSiniri >0) {gelirVergisi = Yuvarla(hesaplananGelirVergisi - gelirVergisiAGIIstisnaSiniri);}


    let damgaVergisi = Yuvarla(DamgaVergisiHesapla(damgaVergisiMatrahi));

    let kesintiToplami = Yuvarla(
      KesintiToplamiHesapla(
        gelirVergisi,
        damgaVergisi,
        emekliKesenegiKisi,
        emekliKesenegiDevlet,
        genSagSigPirKisi,
        genSagSigPirDevlet,
        kisaVadeliSigortaKol,
        ilksanKesintisi,
        polsanKesintisi,
        bireyselEmek,
        sendikaAidati,
        kefaletKesintisi,
        kesintiKiraIcraNafaka
      )
    );


    let gelirToplami = Yuvarla(
      GelirToplamiHesapla(
        aylikTutar,
        ekGostergeAyligi,
        tabanAylik,
        kidemAylik,
        yanOdemeAyligi,
        emekliKesenegiKarsiligi,
        genSagSigPirDevlet,
        aileYardimi,
        cocukYardimi,
        ozelHizmetTazminati,
        dilTazminati,
        sabitEkOdNisb40,
        topSoDIkram,
        fazlaCalismaUcreti,
        ekTazminat28B,
        ekTazminat,
        egitimOgretimTazminati,
        denetimTazminati,
        kisaVadeliSigortaKol,
        sozlesmeUcreti,
        IlaveOdeme375,
        bolgeTazminati,
        ilaveUcret,
        sosyalDengeTazminati,
        yemekYardimi,
        tazminatTutari,
        ucretTutari,
        makamTazminati,
        temGorTazminati,
        universiteOdenegi,
        idariGorevOdenegi,
        egitimOgretimOdenegi,
        akademikTesvik,
        gelistirmeOdenegi,
        yuksekOgretimTazminati
      )
    );


    let netOdenen = Yuvarla(gelirToplami - kesintiToplami);

    const result = {
      netOdenen: netOdenen,
      kesintiToplami: kesintiToplami,
      gelirToplami: gelirToplami,
      aylikTutar: aylikTutar,
      ekGostergeAyligi: ekGostergeAyligi,
      tabanAylik: tabanAylik,
      kidemAylik: kidemAylik,
      yanOdemeAyligi: yanOdemeAyligi,
      emekliKesenegiKarsiligi: emekliKesenegiKarsiligi,
      genSagSigPirDevlet: genSagSigPirDevlet,
      aileYardimi: aileYardimi,
      cocukYardimi: cocukYardimi,
      ozelHizmetTazminati: ozelHizmetTazminati,
      dilTazminati: dilTazminati,
      sabitEkOdNisb40: sabitEkOdNisb40,
      topSoDIkram: topSoDIkram,
      fazlaCalismaUcreti: fazlaCalismaUcreti,
      ekTazminat28B: ekTazminat28B,
      ekTazminat: ekTazminat,
      egitimOgretimTazminati: egitimOgretimTazminati,
      denetimTazminati: denetimTazminati,
      kisaVadeliSigortaKol: kisaVadeliSigortaKol,
      sozlesmeUcreti: sozlesmeUcreti,
      IlaveOdeme375: IlaveOdeme375,
      bolgeTazminati: bolgeTazminati,
      ilaveUcret: ilaveUcret,
      sosyalDengeTazminati: sosyalDengeTazminati,
      yemekYardimi: yemekYardimi,
      tazminatTutari: tazminatTutari,
      ucretTutari: ucretTutari,
      makamTazminati: makamTazminati,
      temGorTazminati: temGorTazminati,
      universiteOdenegi: universiteOdenegi,
      idariGorevOdenegi: idariGorevOdenegi,
      egitimOgretimOdenegi: egitimOgretimOdenegi,
      akademikTesvik: akademikTesvik,
      gelistirmeOdenegi: gelistirmeOdenegi,
      yuksekOgretimTazminati: yuksekOgretimTazminati,
      gelirVergisi: gelirVergisi,
      damgaVergisi: damgaVergisi,
      emekliKesenegiKisi: emekliKesenegiKisi,
      emekliKesenegiDevlet: emekliKesenegiDevlet,
      genSagSigPirKisi: genSagSigPirKisi,
      ilksanKesintisi: ilksanKesintisi,
      polsanKesintisi: polsanKesintisi,
      bireyselEmek: bireyselEmek,
      sendikaAidati: sendikaAidati,
      kefaletKesintisi: kefaletKesintisi,
      kesintiKiraIcraNafaka: kesintiKiraIcraNafaka,
    };

    setCalculatedValues(result);
    console.log("netOdenen : ",netOdenen);
    console.log("kesintiToplami : ",kesintiToplami);
    console.log("gelirToplami : ",gelirToplami);
    console.log("aylikTutar: ",aylikTutar);
    console.log("ekGostergeAyligi: ",ekGostergeAyligi);
    console.log("tabanAylik: ",tabanAylik);
    console.log("kidemAylik: ",kidemAylik);
    console.log("yanOdemeAyligi: ",yanOdemeAyligi);
    console.log("emekliKesenegiKarsiligi: ",emekliKesenegiKarsiligi);
    console.log("genSagSigPirDevlet: ",genSagSigPirDevlet);
    console.log("aileYardimi: ",aileYardimi);
    console.log("cocukYardimi: ",cocukYardimi);
    console.log("ozelHizmetTazminati: ",ozelHizmetTazminati);
    console.log("dilTazminati: ",dilTazminati);
    console.log("sabitEkOdNisb40: ",sabitEkOdNisb40);
    console.log("topSoDIkram: ",topSoDIkram);
    console.log("fazlaCalismaUcreti: ",fazlaCalismaUcreti);
    console.log("ekTazminat28B: ",ekTazminat28B);
    console.log("ekTazminat: ",ekTazminat);
    console.log("egitimOgretimTazminati: ",egitimOgretimTazminati);
    console.log("denetimTazminati: ",denetimTazminati);
    console.log("kisaVadeliSigortaKol: ",kisaVadeliSigortaKol);
    console.log("sozlesmeUcreti: ",sozlesmeUcreti);
    console.log("IlaveOdeme375: ",IlaveOdeme375);
    console.log("bolgeTazminati: ",bolgeTazminati);
    console.log("ilaveUcret: ",ilaveUcret);
    console.log("sosyalDengeTazminati: ",sosyalDengeTazminati);
    console.log("yemekYardimi: ",yemekYardimi);
    console.log("tazminatTutari: ",tazminatTutari);
    console.log("ucretTutari: ",ucretTutari);
    console.log("makamTazminati: ",makamTazminati);
    console.log("temGorTazminati: ",temGorTazminati);
    console.log("universiteOdenegi: ",universiteOdenegi);
    console.log("idariGorevOdenegi: ",idariGorevOdenegi);
    console.log("egitimOgretimOdenegi: ",egitimOgretimOdenegi);
    console.log("akademikTesvik: ",akademikTesvik);
    console.log("gelistirmeOdenegi: ",gelistirmeOdenegi);
    console.log("yuksekOgretimTazminati: ",yuksekOgretimTazminati);
    console.log("gelirVergisi: ",gelirVergisi);
    console.log("damgaVergisi: ",damgaVergisi);
    console.log("emekliKesenegiKisi: ",emekliKesenegiKisi);
    console.log("emekliKesenegiDevlet: ",emekliKesenegiDevlet);
    console.log("genSagSigPirKisi: ",genSagSigPirKisi);
    console.log("genSagSigPirDevlet: ",genSagSigPirDevlet);
    console.log("kisaVadeliSigortaKol: ",kisaVadeliSigortaKol);
    console.log("ilksanKesintisi: ",ilksanKesintisi);
    console.log("polsanKesintisi: ",polsanKesintisi);
    console.log("bireyselEmek: ",bireyselEmek);
    console.log("sendikaAidati: ",sendikaAidati);
    console.log("kefaletKesintisi: ",kefaletKesintisi);
    console.log("kesintiKiraIcraNafaka: ",kesintiKiraIcraNafaka);
  }

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{
          fontWeight: "bold",
          marginBottom: "15px",
          fontSize: "1.2rem",
          backgroundColor: "#17b6cd",
          borderColor: "#17b6cd",
        }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1>Maaş Hesaplama</h1>
      <Form>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Hesaplanacak Yıl:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((y, index) => (
                <option key={index} value={y}>
                  {y}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Hesaplanacak Ay:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={month}
              onChange={handleMonthChange}
            >
              {months.map((m, index) => (
                <option key={index} value={index + 1}>
                  {m}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Kadro Türü:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={kadroTuru}
              onChange={(e) => setKadroTuru(e.target.value)}
            >
              {kadroTurleri.map((k, index) => (
                <option key={index} value={k}>
                  {k}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Unvanı Seçiniz:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={unvan}
              onChange={(e) => setUnvan(e.target.value)}
            >
              {Array.from(new Set(personel.map((p) => p.Unvan))).map(
                (u, index) => (
                  <option key={index} value={u}>
                    {u}
                  </option>
                )
              )}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Tabi Olduğu Kanun:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={kanun}
              onChange={(e) => setKanun(e.target.value)}
            >
              {kanunlar.map((k, index) => (
                <option key={index} value={k}>
                  {k}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Derece - Ödemeye Esas:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              inputmode="numeric"
              value={degreeOdemeyeEsas}
              min="-1"
              max="150"
              step="1"
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "" && value >= 0 && value <= 15) {
                  setDegreeOdemeyeEsas(value);
                  console.log("degreeOdemeyeEsas", degreeOdemeyeEsas);
                }
                if (value === "") {
                  setDegreeOdemeyeEsas("");
                }
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Kademe - Ödemeye Esas:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              inputmode="numeric"
              value={kademeOdemeyeEsas}
              min="-1"
              max="150"
              step="1"
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "" && value >= 0 && value <= 9) {
                  setKademeOdemeyeEsas(value);
                }
                if (value === "") {
                  setKademeOdemeyeEsas("");
                }
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Derece - Emekliliğe Esas:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              inputmode="numeric"
              value={degreeEmekliligeEsas}
              min="-1"
              max="150"
              step="1"
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "" && value >= 0 && value <= 15) {
                  setDegreeEmekliligeEsas(value);
                }
                if (value === "") {
                  setDegreeEmekliligeEsas("");
                }
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Kademe - Emekliliğe Esas:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              inputmode="numeric"
              value={kademeEmekliligeEsas}
              min="-1"
              max="150"
              step="1"
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "" && value >= 0 && value <= 9) {
                  setKademeEmekliligeEsas(value);
                }
                if (value === "") {
                  setKademeEmekliligeEsas("");
                }
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            İşe Başlama Tarihi:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => {
                const startDate = new Date(e.target.value);
                const currentDate = new Date();
                const yearsOfService =
                  currentDate.getFullYear() - startDate.getFullYear();

                // Eğer işe başlama tarihinin bu yıl içerisindeki ay/gün kombinasyonu henüz gelmediyse 1 yıl çıkarırız
                const hasCompletedYear =
                  currentDate.getMonth() > startDate.getMonth() ||
                  (currentDate.getMonth() === startDate.getMonth() &&
                    currentDate.getDate() >= startDate.getDate());
                const finalYearsOfService = hasCompletedYear
                  ? yearsOfService
                  : yearsOfService - 1;

                setKidemYili(finalYearsOfService);
                setStartDate(e.target.value);
                console.log("kidemYili", finalYearsOfService);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Medeni Hali / Eş Yardımı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={medeniHali}
              onChange={(e) => setMedeniHali(e.target.value)}
            >
              <option value="Evli / Eşi Çalışıyor">Evli / Eşi Çalışıyor</option>
              <option value="Evli / Eşi Çalışmıyor">
                Evli / Eşi Çalışmıyor
              </option>
              <option value="Bekar">Bekar</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            72 Aydan Küçük Çocuk Sayısı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={cocukSayisi}
              onChange={(e) => setCocukSayisi(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            72 Aydan Büyük Çocuk Sayısı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={buyukCocukSayisi}
              onChange={(e) => setBuyukCocukSayisi(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Raporlu 72 Aydan Küçük Çocuk Sayısı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={raporluKucukCocukSayisi}
              onChange={(e) => setRaporluKucukCocukSayisi(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Raporlu 72 Aydan Büyük Çocuk Sayısı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={raporluBuyukCocukSayisi}
              onChange={(e) => setRaporluBuyukCocukSayisi(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Sendika Üyeliği:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={sendika}
              onChange={(e) => setSendika(e.target.value)}
            >
              <option value="Evet - %2'den Büyük">Evet - %2'den Büyük</option>
              <option value="Evet - %2'den Küçük">Evet - %2'den Küçük</option>
              <option value="Evet - %2'den Küçük Yerel Yön.">
                Evet - %2'den Küçük Yerel Yön.
              </option>
              <option value="Evet - %2'den Büyük Yerel Yön.">
                Evet - %2'den Büyük Yerel Yön.
              </option>
              <option value="Hayır">Hayır</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Bireysel Emeklilik:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={bireyselEmeklilik}
              onChange={(e) => setBireyselEmeklilik(e.target.value)}
            >
              <option value="Hayır">Hayır</option>
              {[...Array(25).keys()].map((i) => (
                <option key={i + 1} value={(i + 1).toString()}>{`${
                  i + 1
                }%`}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            İlksan Üyeliği:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={ilksanUyelik}
              onChange={(e) => setIlksanUyelik(e.target.value)}
            >
              <option value="Hayır">Hayır</option>
              <option value="Evet">Evet</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Polsan Üyeliği:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={polsanUyelik}
              onChange={(e) => setPolsanUyelik(e.target.value)}
            >
              <option value="Hayır">Hayır</option>
              <option value="Evet">Evet</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Engellilik Durumu:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={engellilikDurumu}
              onChange={(e) => setEngellilikDurumu(e.target.value)}
            >
              <option value="Hayır">Hayır</option>
              <option value="Evet">Evet</option>
            </Form.Control>
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Sağlık Taban Ödeme:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={saglikTabanOdeme}
              onChange={(e) => setSaglikTabanOdeme(e.target.value)}
            >
              <option value="Hayır">Hayır</option>
              <option value="Evet">Evet</option>
            </Form.Control>
          </Col>
        </Form.Group> */}
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Sağlık Nisbi Ödeme Vergi Dilimi:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={saglikNisbiOdemeVergiDilimi}
              onChange={(e) => setSaglikNisbiOdemeVergiDilimi(e.target.value)}
            >
              <option value="Hayır">Hayır</option>
              <option value="0.15">15%</option>
              <option value="0.20">20%</option>
              <option value="0.27">27%</option>
              <option value="0.35">35%</option>
              <option value="0.40">40%</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            TKYS Yetkisi:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={tkysYetkisi}
              onChange={(e) => setTkysYetkisi(e.target.value)}
            >
              <option value="Hayır">Hayır</option>
              <option value="Evet">Evet</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Kefalet Kesintisi:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={kefaletKesintisi}
              onChange={(e) => {
                const value = e.target.value;

                let tutar = 0.0;
                if (value === "Hayır") {
                  tutar = 0.0;
                } else if (value === "Evet - İlk 4 Ay") {
                  tutar = aylikKatsayisi * 375;
                } else if (value === "Evet - İlk 4 Ay Sonrası") {
                  tutar = aylikKatsayisi * 100;
                }
                setKefaletKesintisi(tutar);
              }}
            >
              <option value="Hayır">Hayır</option>
              <option value="Evet - İlk 4 Ay">Evet - İlk 4 Ay</option>
              <option value="Evet - İlk 4 Ay Sonrası">
                Evet - İlk 4 Ay Sonrası
              </option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Yabancı Dil Tazminat Puanı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={yabanciDilTazminatPuani}
              onChange={(e) => setYabanciDilTazminatPuani(e.target.value)}
            >
              <option value="0">0</option>
              <option value="250">250</option>
              <option value="300">300</option>
              <option value="500">500</option>
              <option value="600">600</option>
              <option value="750">750</option>
              <option value="900">900</option>
              <option value="1200">1200</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Görev İli:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={gorevIli}
              onChange={(e) => setGorevIli(e.target.value)}
            >
              <option value="">Seçiniz</option>
              {gorevIlleri.map((il, index) => (
                <option key={index} value={il}>
                  {il}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Görev İlçesi:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              value={gorevIlcesi}
              onChange={(e) => setGorevIlcesi(e.target.value)}
              disabled={!gorevIli}
            >
              <option value="">Seçiniz</option>
              {ilceler.map((ilce, index) => (
                <option key={index} value={ilce}>
                  {ilce}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Akademik Teşvik Puanı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={akademikTesvikPuani}
              onChange={(e) => setAkademikTesvikPuani(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Bölge Tazminatı Puanı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={bolgeTazminatiPuani}
              onChange={(e) => setBolgeTazminatiPuani(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Sosyal Denge Tazminatı Puanı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={sosyalDengeTazminatiPuani}
              onChange={(e) => setSosyalDengeTazminatiPuani(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Yemek Yardımı / Tayin Bedeli Gün Sayısı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={yemekYardimiGunSayisi}
              onChange={(e) => setYemekYardimiGunSayisi(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Eğitim-Öğretim Tazminatı Puanı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={egitimTazminatiPuani}
              onChange={(e) => setEgitimTazminatiPuani(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Ek Tazminat (28/B):
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={ekTazminatGirdi}
              onChange={(e) => setEkTazminatGirdi(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            İlave Ücret (Gelir):
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={ilaveUcretGirdi}
              onChange={(e) => setIlaveUcretGirdi(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Kesinti (Kira, İcra, Nafaka):
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={kesintiKiraIcraNafaka}
              onChange={(e) => setKesintiKiraIcraNafaka(e.target.value)}
            />
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Ek Ders Ücreti:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={ekDersUcreti}
              onChange={(e) => setEkDersUcreti(e.target.value)}
            />
          </Col>
        </Form.Group> */}
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Raporlu Gün Sayısı (7 Günden Fazla):
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={raporluGunSayisi}
              onChange={(e) => setRaporluGunSayisi(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Özel Sağ. Sig. Vergi İndirimi Tutarı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={ozelSaglikSigortasiVergiIndirimiTutari}
              onChange={(e) =>
                setOzelSaglikSigortasiVergiIndirimiTutari(e.target.value)
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1rem" }}>
          <Form.Label column sm={4}>
            Geçen Aylar Vergi Matrahı Toplamı:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={gecenAylarVergiMatrahiToplami}
              onChange={(e) => setGecenAylarVergiMatrahiToplami(e.target.value)}
            />
          </Col>
        </Form.Group>
      </Form>
      <Button
        variant="primary"
        className="mt-2"
        style={{
          fontWeight: "bold",
          marginBottom: "15px",
          fontSize: "1.2rem",
          backgroundColor: "#17b6cd",
          borderColor: "#17b6cd",
        }}
        onClick={() => Hesapla()}
      >
        Hesapla
      </Button>
      <div>
        <h2>Hesaplanan Maaş Bilgileri</h2>
        <Container>
          {calculatedValues && (
            <Table striped bordered hover>
              <tbody>
                {labels.map((label, index) => (
                  <tr key={index}>
                    <td>{label}</td>
                    <td>
                      {calculatedValues[Object.keys(calculatedValues)[index]]} ₺
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Container>
      </div>
    </Container>
  );
};

export default MaasHesabi;

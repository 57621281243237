const personel = [
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 130.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 2,
        "ekGos": 3300.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 117.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 3,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 110.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 4,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 104.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 5,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 104.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 6,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 98.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 7,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 98.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 8,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 98.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 9,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 98.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Araştırma Görevlisi",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 77.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Aşçı",
        "derece": 1,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 2,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 3,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 4,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Aşçı",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1550.0,
        "ozelHiz": 135.0,
        "ekOdeme": 160.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1550.0,
        "ozelHiz": 135.0,
        "ekOdeme": 160.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1550.0,
        "ozelHiz": 135.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1550.0,
        "ozelHiz": 135.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Avukat",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "AH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 875.0,
        "ozelHiz": 155.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 875.0,
        "ozelHiz": 120.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 875.0,
        "ozelHiz": 100.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 875.0,
        "ozelHiz": 88.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Ayniyat Saymanı",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 80.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 1,
        "ekGos": 600.0,
        "yanOdP": 675.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 2,
        "ekGos": 600.0,
        "yanOdP": 675.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 3,
        "ekGos": 600.0,
        "yanOdP": 675.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 4,
        "ekGos": 600.0,
        "yanOdP": 675.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 675.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 89.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 89.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 89.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 89.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 89.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 89.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 89.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bekçi - Çarşı Mahalle Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1025.0,
        "ozelHiz": 90.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 28.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Bilgisayar İşletmeni - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daire Başkanı - Kamu",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 220.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 34575.0,
        "ucretGostergesi": 54180.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daire Başkanı - Üniversite",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1700.0,
        "ozelHiz": 195.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Daktilograf - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 1,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 2,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 3,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 4,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzman Yardımcısı",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 2025.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 2025.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 2025.0,
        "ozelHiz": 125.0,
        "ekOdeme": 135.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 2025.0,
        "ozelHiz": 125.0,
        "ekOdeme": 135.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1575.0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1575.0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1575.0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1575.0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Defterdarlık Uzmanı",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Destek Personeli 0-5 Yıl",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 16030.94,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DES",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Destek Personeli 11-15 Yıl",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 16519.24,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DES",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Destek Personeli 15-20 Yıl",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 16766.95,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DES",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Destek Personeli 20 Yıldan Fazla",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 17011.83,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DES",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Destek Personeli 6-10 Yıl",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 16275.88,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DES",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 900.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 900.0,
        "ozelHiz": 80.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 900.0,
        "ozelHiz": 80.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 900.0,
        "ozelHiz": 68.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Din Hizmetleri Uzmanı",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doçent",
        "derece": 1,
        "ekGos": 5400.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 57.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 8000.0,
        "makamTazminati": 2000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 175.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 90.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Doçent",
        "derece": 2,
        "ekGos": 5400.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 68.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 175.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 90.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Doçent",
        "derece": 3,
        "ekGos": 5400.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 68.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 175.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 90.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Doçent (Dekan Yrd. Özel Durum)",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 67.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 175.0,
        "idariGorevOdenegi": 20.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 60.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Doktor - Asistan",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 5000.0,
        "ozelHiz": 145.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Asistan",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 5000.0,
        "ozelHiz": 145.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Asistan",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 5000.0,
        "ozelHiz": 145.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Asistan",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 5000.0,
        "ozelHiz": 145.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Asistan",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 5000.0,
        "ozelHiz": 140.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Asistan",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 5000.0,
        "ozelHiz": 140.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Asistan",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 5000.0,
        "ozelHiz": 140.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Asistan",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 5000.0,
        "ozelHiz": 140.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Pratisyen",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 5000.0,
        "ozelHiz": 145.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Pratisyen",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 5000.0,
        "ozelHiz": 145.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Pratisyen",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 5000.0,
        "ozelHiz": 145.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Pratisyen",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 5000.0,
        "ozelHiz": 145.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Pratisyen",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 5000.0,
        "ozelHiz": 135.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Pratisyen",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 5000.0,
        "ozelHiz": 135.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Pratisyen",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 5000.0,
        "ozelHiz": 135.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Pratisyen",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 5000.0,
        "ozelHiz": 135.0,
        "ekOdeme": 265.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 226.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Uzman",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 5400.0,
        "ozelHiz": 180.0,
        "ekOdeme": 335.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 450.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Uzman",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 5400.0,
        "ozelHiz": 180.0,
        "ekOdeme": 335.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 450.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Uzman",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 5400.0,
        "ozelHiz": 180.0,
        "ekOdeme": 335.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 450.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Uzman",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 5400.0,
        "ozelHiz": 180.0,
        "ekOdeme": 335.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 450.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Uzman",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 5400.0,
        "ozelHiz": 160.0,
        "ekOdeme": 335.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 450.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Uzman",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 5400.0,
        "ozelHiz": 160.0,
        "ekOdeme": 335.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 450.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Uzman",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 5400.0,
        "ozelHiz": 160.0,
        "ekOdeme": 335.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 450.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor - Uzman",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 5400.0,
        "ozelHiz": 160.0,
        "ekOdeme": 335.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 450.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Doktor Öğretim Üyesi",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 67.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 175.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1800.0,
        "ozelHiz": 110.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 1800.0,
        "ozelHiz": 110.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 1800.0,
        "ozelHiz": 110.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 1800.0,
        "ozelHiz": 110.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 1800.0,
        "ozelHiz": 106.0,
        "ekOdeme": 120.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 1800.0,
        "ozelHiz": 106.0,
        "ekOdeme": 120.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 1800.0,
        "ozelHiz": 106.0,
        "ekOdeme": 120.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 1800.0,
        "ozelHiz": 106.0,
        "ekOdeme": 120.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Diğer Kurumlar",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1800.0,
        "ozelHiz": 106.0,
        "ekOdeme": 120.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1950.0,
        "ozelHiz": 110.0,
        "ekOdeme": 168.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 1950.0,
        "ozelHiz": 110.0,
        "ekOdeme": 168.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 1950.0,
        "ozelHiz": 110.0,
        "ekOdeme": 156.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 1950.0,
        "ozelHiz": 110.0,
        "ekOdeme": 156.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 1950.0,
        "ozelHiz": 106.0,
        "ekOdeme": 144.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 1950.0,
        "ozelHiz": 106.0,
        "ekOdeme": 144.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 1950.0,
        "ozelHiz": 106.0,
        "ekOdeme": 144.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 1950.0,
        "ozelHiz": 106.0,
        "ekOdeme": 144.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eczacı - Yataklı Tedavi Kurumları",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1950.0,
        "ozelHiz": 106.0,
        "ekOdeme": 144.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 900.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 900.0,
        "ozelHiz": 80.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 900.0,
        "ozelHiz": 80.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 900.0,
        "ozelHiz": 68.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Eğitim Hizmetleri Uzmanı",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 900.0,
        "ozelHiz": 50.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Enstitü Sekreteri",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1300.0,
        "ozelHiz": 155.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Enstitü Sekreteri",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1300.0,
        "ozelHiz": 120.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Fakülte Sekreteri",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1300.0,
        "ozelHiz": 165.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Fakülte Sekreteri",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 1300.0,
        "ozelHiz": 120.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 1,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 2,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 3,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 4,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzman Yardımcısı",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1000.0,
        "ozelHiz": 92.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzmanı",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzmanı",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1550.0,
        "ozelHiz": 130.0,
        "ekOdeme": 140.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzmanı",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1550.0,
        "ozelHiz": 125.0,
        "ekOdeme": 135.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzmanı",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1550.0,
        "ozelHiz": 125.0,
        "ekOdeme": 135.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzmanı",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1550.0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzmanı",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1550.0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzmanı",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1550.0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Gelir Uzmanı",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 1550.0,
        "ozelHiz": 125.0,
        "ekOdeme": 130.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Genel Müdür",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 1950.0,
        "ozelHiz": 330.0,
        "ekOdeme": 195.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 17000.0,
        "makamTazminati": 7000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 52750.0,
        "ucretGostergesi": 69580.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Genel Sekreter - Üniversite",
        "derece": 1,
        "ekGos": 4400.0,
        "yanOdP": 1700.0,
        "ozelHiz": 200.0,
        "ekOdeme": 185.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 8000.0,
        "makamTazminati": 2000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Genel Sekreter Yrd. - Üniversite",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1700.0,
        "ozelHiz": 175.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lisans",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 1200.0,
        "ozelHiz": 72.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 1200.0,
        "ozelHiz": 72.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 1200.0,
        "ozelHiz": 72.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Sağlık Memuru - Lise",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1550.0,
        "ozelHiz": 97.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 94.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1975.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1975.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1975.0,
        "ozelHiz": 97.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1975.0,
        "ozelHiz": 97.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1975.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1975.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1975.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1975.0,
        "ozelHiz": 94.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Laboratuvarda Çalışan",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1975.0,
        "ozelHiz": 94.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1700.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1700.0,
        "ozelHiz": 97.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1700.0,
        "ozelHiz": 97.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1700.0,
        "ozelHiz": 97.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1700.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1700.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1700.0,
        "ozelHiz": 94.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1700.0,
        "ozelHiz": 94.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lisans Yataklı Tedavi Kurumları",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1700.0,
        "ozelHiz": 94.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 1200.0,
        "ozelHiz": 72.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 1200.0,
        "ozelHiz": 72.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 1200.0,
        "ozelHiz": 72.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 1200.0,
        "ozelHiz": 72.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Lise",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 70.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1550.0,
        "ozelHiz": 90.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1550.0,
        "ozelHiz": 90.0,
        "ekOdeme": 138.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1550.0,
        "ozelHiz": 90.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1550.0,
        "ozelHiz": 90.0,
        "ekOdeme": 126.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1550.0,
        "ozelHiz": 88.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1550.0,
        "ozelHiz": 88.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1550.0,
        "ozelHiz": 88.0,
        "ekOdeme": 114.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1550.0,
        "ozelHiz": 88.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 88.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hemşire - Önlisans",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1550.0,
        "ozelHiz": 88.0,
        "ekOdeme": 108.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 32.0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 1,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 2,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 3,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 4,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hizmetli",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Hukuk Müşaviri - (Özel Durum)",
        "derece": 1,
        "ekGos": 6400.0,
        "yanOdP": 1550.0,
        "ozelHiz": 290.0,
        "ekOdeme": 455.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 11500.0,
        "makamTazminati": 4500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "MİA",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 950.0,
        "ozelHiz": 170.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 950.0,
        "ozelHiz": 155.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 950.0,
        "ozelHiz": 140.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 950.0,
        "ozelHiz": 130.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftü Yardımcısı",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Ankara-İstanbul-İzmir)",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1375.0,
        "ozelHiz": 200.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 9000.0,
        "makamTazminati": 3000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 33950.0,
        "ucretGostergesi": 53030.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Ankara-İstanbul-İzmir)",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 1375.0,
        "ozelHiz": 200.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 9000.0,
        "makamTazminati": 3000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 33950.0,
        "ucretGostergesi": 53030.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Ankara-İstanbul-İzmir)",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 1375.0,
        "ozelHiz": 200.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 9000.0,
        "makamTazminati": 3000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 33950.0,
        "ucretGostergesi": 53030.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Ankara-İstanbul-İzmir)",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 1375.0,
        "ozelHiz": 200.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 9000.0,
        "makamTazminati": 3000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 33950.0,
        "ucretGostergesi": 53030.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Diğer Büyükşehir)",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1200.0,
        "ozelHiz": 175.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 7500.0,
        "makamTazminati": 1500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 30775.0,
        "ucretGostergesi": 49130.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Diğer Büyükşehir)",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 1200.0,
        "ozelHiz": 175.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 7500.0,
        "makamTazminati": 1500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 30775.0,
        "ucretGostergesi": 49130.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Diğer Büyükşehir)",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 1200.0,
        "ozelHiz": 175.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 7500.0,
        "makamTazminati": 1500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 30775.0,
        "ucretGostergesi": 49130.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Diğer Büyükşehir)",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 1200.0,
        "ozelHiz": 175.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 7500.0,
        "makamTazminati": 1500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 30775.0,
        "ucretGostergesi": 49130.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Diğer İller)",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1200.0,
        "ozelHiz": 175.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 7000.0,
        "makamTazminati": 1000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 29200.0,
        "ucretGostergesi": 48080.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Diğer İller)",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 1200.0,
        "ozelHiz": 175.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 7000.0,
        "makamTazminati": 1000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 29200.0,
        "ucretGostergesi": 48080.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Diğer İller)",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 1200.0,
        "ozelHiz": 175.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 7000.0,
        "makamTazminati": 1000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 29200.0,
        "ucretGostergesi": 48080.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İl Müftüsü (Diğer İller)",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 1200.0,
        "ozelHiz": 175.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 7000.0,
        "makamTazminati": 1000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 29200.0,
        "ucretGostergesi": 48080.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1100.0,
        "ozelHiz": 155.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1100.0,
        "ozelHiz": 120.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1100.0,
        "ozelHiz": 10.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1100.0,
        "ozelHiz": 88.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müdürü",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 950.0,
        "ozelHiz": 170.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 950.0,
        "ozelHiz": 155.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 950.0,
        "ozelHiz": 140.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 950.0,
        "ozelHiz": 130.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İlçe Müftüsü",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 105.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 18722.1,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19429.74,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - İmam Hatip Lisesi",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19838.01,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Baş İmam-Hatip)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 25.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 25.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İmam-Hatip (Uzman İmam-Hatip)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 3,
        "ekGos": 2100.0,
        "yanOdP": 2100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 4,
        "ekGos": 1700.0,
        "yanOdP": 2100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2100.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2100.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1675.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1675.0,
        "ozelHiz": 93.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Lisans Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1675.0,
        "ozelHiz": 93.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 3,
        "ekGos": 2100.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 4,
        "ekGos": 1700.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "İstidlalci - Önlisans Mezunu",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Baş Öğreticisi",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 18722.1,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19429.74,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - İmam Hatip Lisesi",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19838.01,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Öğreticisi - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 25.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 25.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "K.K. Uzman Öğreticisi",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 1,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 2,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 3,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 4,
        "ekGos": 600.0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 45.0,
        "ekOdeme": 80.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kaloriferci",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 44.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "YH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Koruma ve Güvenlik Görevlisi 0-5 Yıl",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19141.63,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "KGG",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Koruma ve Güvenlik Görevlisi 11-15 Yıl",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19535.71,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "KGG",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Koruma ve Güvenlik Görevlisi 16-20 Yıl",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19796.73,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "KGG",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Koruma ve Güvenlik Görevlisi 20 Yıldan Fazla",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 20313.97,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "KGG",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Koruma ve Güvenlik Görevlisi 6-10 Yıl",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19274.58,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "KGG",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphane Başkanı",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1700.0,
        "ozelHiz": 195.0,
        "ekOdeme": 180.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 3,
        "ekGos": 2100.0,
        "yanOdP": 2100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 4,
        "ekGos": 1700.0,
        "yanOdP": 2100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2100.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2100.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2100.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2100.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Dolduran",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2100.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 1675.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 1675.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 3,
        "ekGos": 2100.0,
        "yanOdP": 1675.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 4,
        "ekGos": 1700.0,
        "yanOdP": 1675.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1675.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1675.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1675.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1675.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Lisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1675.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 3,
        "ekGos": 2100.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 4,
        "ekGos": 1700.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 5.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Dolduran",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 3,
        "ekGos": 2100.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 4,
        "ekGos": 1700.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 5.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Kütüphaneci - Önlisans Mezunu - 4 Yılını Doldurmayan",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Memur - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 25.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 25.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - (Baş Müezzin-Kayım)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 18722.1,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 89.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19429.74,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 93.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 92.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - İmam Hatip Lisesi",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 91.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 19838.01,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 95.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müezzin-Kayyım - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş - (Baş Müfettiş Diğer Kurumlar)",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 2700.0,
        "ozelHiz": 195.0,
        "ekOdeme": 175.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 8000.0,
        "makamTazminati": 2000.0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 25560.0,
        "ucretGostergesi": 48730.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş - (Baş Müfettiş Diğer Kurumlar)",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 2700.0,
        "ozelHiz": 195.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 23870.0,
        "ucretGostergesi": 45680.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş - (Baş Müfettiş Diğer Kurumlar)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 2700.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 22475.0,
        "ucretGostergesi": 43130.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş - (Baş Müfettiş Diğer Kurumlar)",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 2700.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 21625.0,
        "ucretGostergesi": 41580.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş - (Baş Müfettiş Diğer Kurumlar)",
        "derece": 5,
        "ekGos": 2100.0,
        "yanOdP": 2250.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 21000.0,
        "ucretGostergesi": 40230.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş - (Baş Müfettiş Diğer Kurumlar)",
        "derece": 6,
        "ekGos": 2100.0,
        "yanOdP": 2250.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 20250.0,
        "ucretGostergesi": 39080.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş - (Baş Müfettiş Diğer Kurumlar)",
        "derece": 7,
        "ekGos": 1750.0,
        "yanOdP": 2250.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 19550.0,
        "ucretGostergesi": 37830.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş (Diğer Kurumlar)",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 2700.0,
        "ozelHiz": 195.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 8000.0,
        "makamTazminati": 2000.0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 25560.0,
        "ucretGostergesi": 48730.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş (Diğer Kurumlar)",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 2700.0,
        "ozelHiz": 195.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 23870.0,
        "ucretGostergesi": 45680.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş (Diğer Kurumlar)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 2700.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 22475.0,
        "ucretGostergesi": 43130.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş (Diğer Kurumlar)",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 2700.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 21625.0,
        "ucretGostergesi": 41580.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş (Diğer Kurumlar)",
        "derece": 5,
        "ekGos": 2100.0,
        "yanOdP": 2250.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 21000.0,
        "ucretGostergesi": 40230.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş (Diğer Kurumlar)",
        "derece": 6,
        "ekGos": 2100.0,
        "yanOdP": 2250.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 20250.0,
        "ucretGostergesi": 39080.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş (Diğer Kurumlar)",
        "derece": 7,
        "ekGos": 1750.0,
        "yanOdP": 2250.0,
        "ozelHiz": 185.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 30.0,
        "tazminatGostergesi": 19550.0,
        "ucretGostergesi": 37830.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Müfettiş Yardımcısı (Diğer Kurumlar)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 125.0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 20.0,
        "tazminatGostergesi": 16800.0,
        "ucretGostergesi": 32855.0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 666,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1775.0,
        "ozelHiz": 160.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 1775.0,
        "ozelHiz": 160.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 1775.0,
        "ozelHiz": 160.0,
        "ekOdeme": 160.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 1775.0,
        "ozelHiz": 160.0,
        "ekOdeme": 160.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (1-4 Yıl Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1775.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 2200.0,
        "ozelHiz": 160.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 2200.0,
        "ozelHiz": 160.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 2200.0,
        "ozelHiz": 160.0,
        "ekOdeme": 160.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 2200.0,
        "ozelHiz": 160.0,
        "ekOdeme": 160.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mühendis (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2200.0,
        "ozelHiz": 152.0,
        "ekOdeme": 150.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 700.0,
        "ozelHiz": 134.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 700.0,
        "ozelHiz": 134.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 700.0,
        "ozelHiz": 134.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 700.0,
        "ozelHiz": 134.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 134.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 134.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 134.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 133.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 133.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 133.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 133.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 133.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 133.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 133.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 133.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 700.0,
        "ozelHiz": 138.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 700.0,
        "ozelHiz": 138.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 700.0,
        "ozelHiz": 138.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 700.0,
        "ozelHiz": 138.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 137.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 137.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 137.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 137.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 137.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 136.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 136.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 136.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 136.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 136.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - İmam Hatip Lisesi",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 136.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 700.0,
        "ozelHiz": 140.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 700.0,
        "ozelHiz": 140.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 700.0,
        "ozelHiz": 140.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 700.0,
        "ozelHiz": 140.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Murakıp - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 139.0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Mutemet - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 130.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 2,
        "ekGos": 3300.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 117.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 3,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 110.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 4,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 104.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 5,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 104.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 6,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 98.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 7,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 98.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 8,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 98.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 9,
        "ekGos": 2900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 98.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretim Görevlisi, Okutman, Uzman",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 70.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 22466.87,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 750.0,
        "ozelHiz": 120.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 750.0,
        "ozelHiz": 120.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 750.0,
        "ozelHiz": 115.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 750.0,
        "ozelHiz": 115.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 750.0,
        "ozelHiz": 120.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 120.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 750.0,
        "ozelHiz": 120.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 120.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 750.0,
        "ozelHiz": 115.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 750.0,
        "ozelHiz": 115.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Baş Öğretmen)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 100.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 2175.0,
        "ozelHiz": 130.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 2,
        "ekGos": 2800.0,
        "yanOdP": 2175.0,
        "ozelHiz": 130.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 2175.0,
        "ozelHiz": 130.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 2175.0,
        "ozelHiz": 130.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Teknik Öğretmen)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 122.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 750.0,
        "ozelHiz": 120.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 60.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 750.0,
        "ozelHiz": 120.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 60.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 750.0,
        "ozelHiz": 115.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 750.0,
        "ozelHiz": 115.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Öğretmen (Uzman Öğretmen)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 750.0,
        "ozelHiz": 105.56,
        "ekOdeme": 65.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EÖH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 875.0,
        "ozelHiz": 65.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 875.0,
        "ozelHiz": 65.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 875.0,
        "ozelHiz": 65.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 875.0,
        "ozelHiz": 65.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 59.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 59.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 59.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 875.0,
        "ozelHiz": 65.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 875.0,
        "ozelHiz": 65.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 875.0,
        "ozelHiz": 65.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 875.0,
        "ozelHiz": 65.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 59.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 59.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 59.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Orman Muhafaza Memuru - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 875.0,
        "ozelHiz": 58.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Baş Komiser",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 1300.0,
        "ozelHiz": 173.0,
        "ekOdeme": 74.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Baş Komiser",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 1300.0,
        "ozelHiz": 173.0,
        "ekOdeme": 74.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Baş Komiser",
        "derece": 3,
        "ekGos": 3000.0,
        "yanOdP": 1300.0,
        "ozelHiz": 173.0,
        "ekOdeme": 74.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Baş Komiser",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 1300.0,
        "ozelHiz": 173.0,
        "ekOdeme": 74.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Baş Komiser",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1300.0,
        "ozelHiz": 166.0,
        "ekOdeme": 74.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Baş Komiser",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1300.0,
        "ozelHiz": 166.0,
        "ekOdeme": 74.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Baş Komiser",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1300.0,
        "ozelHiz": 166.0,
        "ekOdeme": 74.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Baş Komiser",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1300.0,
        "ozelHiz": 166.0,
        "ekOdeme": 74.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 1200.0,
        "ozelHiz": 148.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 1200.0,
        "ozelHiz": 148.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 1200.0,
        "ozelHiz": 148.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 1200.0,
        "ozelHiz": 148.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 139.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 139.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 139.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1200.0,
        "ozelHiz": 148.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1200.0,
        "ozelHiz": 148.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1200.0,
        "ozelHiz": 148.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1200.0,
        "ozelHiz": 148.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 1200.0,
        "ozelHiz": 139.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 1200.0,
        "ozelHiz": 139.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 1200.0,
        "ozelHiz": 139.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Polis - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1200.0,
        "ozelHiz": 128.0,
        "ekOdeme": 75.0,
        "fazlaCalismaUcretiOrani": 52.0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "EH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Profesör (3 Yılını Dolduran)",
        "derece": 1,
        "ekGos": 5900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 15000.0,
        "makamTazminati": 6000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (3 Yılını Doldurmayan)",
        "derece": 1,
        "ekGos": 5900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 63.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 11500.0,
        "makamTazminati": 4500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 215.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (4 Yılını Dolduran)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 15000.0,
        "makamTazminati": 6000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Bölüm Başkanı 3 Yılını Dolduran)",
        "derece": 1,
        "ekGos": 5900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 15000.0,
        "makamTazminati": 6000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 20.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Bölüm Başkanı 3 Yılını Doldurmayan)",
        "derece": 1,
        "ekGos": 5900.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 63.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 11500.0,
        "makamTazminati": 4500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 215.0,
        "idariGorevOdenegi": 20.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Bölüm Başkanı 4 Yılını Dolduran)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 15000.0,
        "makamTazminati": 6000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 20.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Dekan 3 Yılını Dolduran)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 72.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 15000.0,
        "makamTazminati": 6000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 30.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Dekan 3 Yılını Doldurmayan)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 72.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 11500.0,
        "makamTazminati": 4500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 30.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Dekan Yrd.- 3 Yılını Dolduran)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 15000.0,
        "makamTazminati": 6000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 20.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Dekan Yrd.- 3 Yılını Doldurmayan)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 11500.0,
        "makamTazminati": 4500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 20.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Rekör Yrd. 3 Yılını Dolduran)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 72.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 15000.0,
        "makamTazminati": 6000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 30.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Rekör Yrd. 3 Yılını Doldurmayan)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 72.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 11500.0,
        "makamTazminati": 4500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 30.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Rektör)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 72.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 17000.0,
        "makamTazminati": 7000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 70.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Y.O Müdürü 3 Yılını Dolduran)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 15000.0,
        "makamTazminati": 6000.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 20.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Profesör (Y.O Müdürü 3 Yılını Doldurmayan)",
        "derece": 1,
        "ekGos": 7000.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 70.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 11500.0,
        "makamTazminati": 4500.0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 245.0,
        "idariGorevOdenegi": 20.0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 100.0,
        "yuksekOgretim": 100.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 700.0,
        "ozelHiz": 82.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 700.0,
        "ozelHiz": 82.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 700.0,
        "ozelHiz": 82.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 700.0,
        "ozelHiz": 82.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 2250.0,
        "ozelHiz": 82.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 2250.0,
        "ozelHiz": 82.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 2250.0,
        "ozelHiz": 82.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 2250.0,
        "ozelHiz": 82.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Diğer Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 700.0,
        "ozelHiz": 82.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 700.0,
        "ozelHiz": 82.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 700.0,
        "ozelHiz": 82.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 700.0,
        "ozelHiz": 82.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 700.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2250.0,
        "ozelHiz": 82.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2250.0,
        "ozelHiz": 82.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 2250.0,
        "ozelHiz": 82.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 2250.0,
        "ozelHiz": 82.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şef - Yüksek Öğrenim (Bilgisayar İşl. / V.H.K.İ'Den Gelen)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 72.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şoför - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 500.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1100.0,
        "ozelHiz": 155.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1100.0,
        "ozelHiz": 120.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1100.0,
        "ozelHiz": 88.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Milli Eğitim Bakanlığı Merkez Teşkilatı",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1300.0,
        "ozelHiz": 155.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Milli Eğitim Bakanlığı Merkez Teşkilatı",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1300.0,
        "ozelHiz": 120.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Milli Eğitim Bakanlığı Merkez Teşkilatı",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1300.0,
        "ozelHiz": 100.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Milli Eğitim Bakanlığı Merkez Teşkilatı",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1300.0,
        "ozelHiz": 88.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Milli Eğitim Bakanlığı Merkez Teşkilatı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1300.0,
        "ozelHiz": 80.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1100.0,
        "ozelHiz": 155.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1100.0,
        "ozelHiz": 120.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 1100.0,
        "ozelHiz": 100.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 1100.0,
        "ozelHiz": 88.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Şube Müdürü - Sağlık Bakanlığı",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 80.0,
        "ekOdeme": 204.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (1-4 Yıl Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 1750.0,
        "ozelHiz": 130.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 1750.0,
        "ozelHiz": 130.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 1750.0,
        "ozelHiz": 130.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 1750.0,
        "ozelHiz": 130.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 1-4 Yıl Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1750.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2175.0,
        "ozelHiz": 130.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2175.0,
        "ozelHiz": 130.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 2175.0,
        "ozelHiz": 130.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 2175.0,
        "ozelHiz": 130.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Tekniker (Tekn. Eğ. Fak. 5 Yıl ve Üzeri Yıl Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2175.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 950.0,
        "ozelHiz": 64.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 950.0,
        "ozelHiz": 64.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 950.0,
        "ozelHiz": 64.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 950.0,
        "ozelHiz": 64.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 1-4 Yıl Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 950.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 1050.0,
        "ozelHiz": 64.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 1050.0,
        "ozelHiz": 64.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 1050.0,
        "ozelHiz": 64.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 1050.0,
        "ozelHiz": 64.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (EML 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1050.0,
        "ozelHiz": 61.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MÜH. 1-4 Yıl Hüzmeti Olan)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 1625.0,
        "ozelHiz": 160.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MÜH. 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2050.0,
        "ozelHiz": 160.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 1625.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 1-4 Yıl Hüzmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 2050.0,
        "ozelHiz": 100.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen (MYO 5 Yıl ve Üzeri Hizmeti Olan)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 93.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "TH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2050.0,
        "ozelHiz": 160.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 2050.0,
        "ozelHiz": 160.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 2050.0,
        "ozelHiz": 160.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2050.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 1625.0,
        "ozelHiz": 160.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 1625.0,
        "ozelHiz": 160.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 1625.0,
        "ozelHiz": 160.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 95.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "Teknisyen Mühendislik Fakültesi Mezunu",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 1625.0,
        "ozelHiz": 152.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 0,
        "sinifi": 0,
        "akademik": NaN
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Diğer Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 1,
        "ekGos": 2800.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 2,
        "ekGos": 2200.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 110.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 3,
        "ekGos": 1700.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 4,
        "ekGos": 1400.0,
        "yanOdP": 2250.0,
        "ozelHiz": 55.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 49.0,
        "ekOdeme": 90.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "V.H.K.İ. - Yüksek Öğrenim",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 2250.0,
        "ozelHiz": 48.0,
        "ekOdeme": 85.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 0,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 650.0,
        "ozelHiz": 145.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 650.0,
        "ozelHiz": 141.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 650.0,
        "ozelHiz": 139.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 650.0,
        "ozelHiz": 135.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 120.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 650.0,
        "ozelHiz": 140.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 650.0,
        "ozelHiz": 136.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 50.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 650.0,
        "ozelHiz": 134.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 650.0,
        "ozelHiz": 130.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Baş Vaiz)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 40.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 650.0,
        "ozelHiz": 140.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 25.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 650.0,
        "ozelHiz": 136.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 25.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 650.0,
        "ozelHiz": 134.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 650.0,
        "ozelHiz": 130.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Vaiz (Uzman Vaiz)",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 650.0,
        "ozelHiz": 115.0,
        "ekOdeme": 66.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 20.0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "DH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 4350.0,
        "ozelHiz": 135.0,
        "ekOdeme": 185.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 4350.0,
        "ozelHiz": 135.0,
        "ekOdeme": 185.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 4350.0,
        "ozelHiz": 135.0,
        "ekOdeme": 175.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 4350.0,
        "ozelHiz": 135.0,
        "ekOdeme": 175.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 4350.0,
        "ozelHiz": 130.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 4350.0,
        "ozelHiz": 130.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 4350.0,
        "ozelHiz": 130.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 4350.0,
        "ozelHiz": 130.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 4350.0,
        "ozelHiz": 130.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Lisans",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 4450.0,
        "ozelHiz": 145.0,
        "ekOdeme": 185.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 2,
        "ekGos": 3600.0,
        "yanOdP": 4450.0,
        "ozelHiz": 145.0,
        "ekOdeme": 185.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 3,
        "ekGos": 2800.0,
        "yanOdP": 4450.0,
        "ozelHiz": 145.0,
        "ekOdeme": 175.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 4,
        "ekGos": 2200.0,
        "yanOdP": 4450.0,
        "ozelHiz": 145.0,
        "ekOdeme": 175.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 5,
        "ekGos": 1900.0,
        "yanOdP": 4450.0,
        "ozelHiz": 135.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 6,
        "ekGos": 1750.0,
        "yanOdP": 4450.0,
        "ozelHiz": 135.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 7,
        "ekGos": 1550.0,
        "yanOdP": 4450.0,
        "ozelHiz": 135.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 8,
        "ekGos": 1450.0,
        "yanOdP": 4450.0,
        "ozelHiz": 135.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 4450.0,
        "ozelHiz": 135.0,
        "ekOdeme": 165.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 13,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 14,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Hekim - Uzman",
        "derece": 15,
        "ekGos": 0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 3500.0,
        "ozelHiz": 97.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 3500.0,
        "ozelHiz": 97.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 3500.0,
        "ozelHiz": 97.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 3500.0,
        "ozelHiz": 97.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lisans Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 3425.0,
        "ozelHiz": 72.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 3425.0,
        "ozelHiz": 72.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 3425.0,
        "ozelHiz": 72.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 3425.0,
        "ozelHiz": 72.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Lise Mezunu",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 3500.0,
        "ozelHiz": 90.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 3500.0,
        "ozelHiz": 90.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 3500.0,
        "ozelHiz": 90.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 3500.0,
        "ozelHiz": 90.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknikeri - Önlisans Mezunu",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 3500.0,
        "ozelHiz": 97.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 3500.0,
        "ozelHiz": 97.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 3500.0,
        "ozelHiz": 97.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 3500.0,
        "ozelHiz": 97.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lisans Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 3500.0,
        "ozelHiz": 94.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 1,
        "ekGos": 2100.0,
        "yanOdP": 3425.0,
        "ozelHiz": 72.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 2,
        "ekGos": 1700.0,
        "yanOdP": 3425.0,
        "ozelHiz": 72.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 3,
        "ekGos": 1400.0,
        "yanOdP": 3425.0,
        "ozelHiz": 72.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 4,
        "ekGos": 1250.0,
        "yanOdP": 3425.0,
        "ozelHiz": 72.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 5,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 6,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 7,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 8,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 11,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Lise Mezunu",
        "derece": 12,
        "ekGos": 0,
        "yanOdP": 3425.0,
        "ozelHiz": 70.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 3500.0,
        "ozelHiz": 90.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 3500.0,
        "ozelHiz": 90.0,
        "ekOdeme": 125.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 3,
        "ekGos": 2200.0,
        "yanOdP": 3500.0,
        "ozelHiz": 90.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 4,
        "ekGos": 2100.0,
        "yanOdP": 3500.0,
        "ozelHiz": 90.0,
        "ekOdeme": 115.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 5,
        "ekGos": 1800.0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 6,
        "ekGos": 1700.0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 7,
        "ekGos": 1500.0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 105.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 8,
        "ekGos": 1400.0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 9,
        "ekGos": 0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Veteriner Sağlık Teknisyeni - Önlisans Mezunu",
        "derece": 10,
        "ekGos": 0,
        "yanOdP": 3500.0,
        "ozelHiz": 88.0,
        "ekOdeme": 100.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "SH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Yrd.Doç.",
        "derece": 1,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 67.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 165.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 80.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Yrd.Doç.",
        "derece": 2,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 67.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 165.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 80.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Yrd.Doç.",
        "derece": 3,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 67.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 165.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 80.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Yrd.Doç.",
        "derece": 4,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 67.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 165.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 80.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Yrd.Doç.",
        "derece": 5,
        "ekGos": 4200.0,
        "yanOdP": 0,
        "ozelHiz": 0,
        "ekOdeme": 67.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 165.0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0.16666666666666666,
        "akademikTesvik": 80.0,
        "yuksekOgretim": 115.0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "ÖEH",
        "akademik": "Evet"
    },
    {
        "Unvan": "Yüksek Okul Sekreteri",
        "derece": 1,
        "ekGos": 3600.0,
        "yanOdP": 1300.0,
        "ozelHiz": 165.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    },
    {
        "Unvan": "Yüksek Okul Sekreteri",
        "derece": 2,
        "ekGos": 3000.0,
        "yanOdP": 1300.0,
        "ozelHiz": 120.0,
        "ekOdeme": 170.0,
        "fazlaCalismaUcretiOrani": 0,
        "saglikTabanOdemeOrani": 0,
        "ekTazminat": 0,
        "temsilGorevTazminati": 0,
        "makamTazminati": 0,
        "denetimTazminati": 0,
        "tazminatGostergesi": 0,
        "ucretGostergesi": 0,
        "universiteOdenegi": 0,
        "idariGorevOdenegi": 0,
        "egitimOgretimOdenegi": 0,
        "akademikTesvik": 0,
        "yuksekOgretim": 0,
        "birinciDonem": 0,
        "ikinciDonem": 0,
        "yedek1": 0,
        "yedek2": 0,
        "yedek3": 0,
        "yedek4": 0,
        "yedek5": 0,
        "hek": 657,
        "sinifi": "GİH",
        "akademik": "Hayır"
    }
];
export default personel;
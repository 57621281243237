import React from 'react';
import './InfoComponent.css';

const InfoComponent = () => {
  return (
    <div className="info-container">
      <h2>NÖBET ÖDEMELERİNİN YAPILMASININ AMACI NEDİR?</h2>
      <p>
        Sağlık Profesyonellerinin haftalık çalışma süresi dışında normal, acil veya branş nöbeti tutarak, bu nöbet karşılığında kurumunca izin kullanmasına müsaade edilmeyen memurlar ile sözleşmeli personele, izin suretiyle karşılanamayan her bir nöbet saati için nöbet ücreti ödenmesidir.
      </p>
      
      <h2>NÖBET ÖDEMELERİ KİMLERİ KAPSAR?</h2>
      <p>
        Yataklı tedavi kurumları, seyyar hastaneler, ağız ve diş sağlığı merkezleri, aile sağlığı merkezleri, toplum sağlığı merkezleri ve 112 acil sağlık hizmetlerinde görev yapan memurları kapsar.
      </p>

      <h2>NÖBET ÖDEMELERİNİN RESMİ DAYANAĞI NEDİR?</h2>
      <p>
        657 sayılı Devlet Memurları Kanunu, 7.Dönem Toplu Sözleşme ve Yataklı Tedavi Kurumu Uygulama yönetmeliğine dayanır.
      </p>

      <h2>NÖBET ÖDEMELERİNİN NASIL HESAPLANIR?</h2>
      <p>
        *Nöbet Ücretleri (nöbet süresi kesintisiz 6 saatten az olmamak üzere), aşağıda gösterilen gösterge rakamlarının aylık katsayısı ile çarpılması sonucu hesaplanacak tutarda nöbet ücreti ödenir. <br/>
        *Bu ücret yoğun bakım, acil servis ve 112 acil sağlık hizmetlerinde tutulan söz konusu nöbetler için yüzde elli oranında artırımlı ödenir. Ancak ayda aile sağlığı ve toplum sağlığı merkezlerinde 60 saatten, diğer yerlerde ve hiçbir şekilde 130 saatten fazlası için ödeme yapılmaz. Bu ücret damga vergisi hariç herhangi bir vergi ve kesintiye tabi tutulmaz.
      </p>

      <table>
        <thead>
          <tr>
            <th>Unvan</th>
            <th>Gösterge</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Eğitim görevlisi, başasistan, uzman tabip</td>
            <td>160</td>
          </tr>
          <tr>
            <td>Tabip, tıpta uzmanlık mevzuatında belirtilen dallarda, bu mevzuat hükümlerine göre uzmanlık belgesi alan tabip dışı personel, aynı dallarda doktora belgesi alanlar</td>
            <td>145</td>
          </tr>
          <tr>
            <td>Diş tabibi ve eczacılar</td>
            <td>130</td>
          </tr>
          <tr>
            <td>Mesleki yükseköğrenim görmüş sağlık personeli</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Lise dengi mesleki öğrenim görmüş sağlık personeli</td>
            <td>85</td>
          </tr>
          <tr>
            <td>Diğer personel</td>
            <td>65</td>
          </tr>
        </tbody>
      </table>

      <h2>BAYRAM NÖBET ÖDEMELERİ NASIL HESAPLANIR?</h2>
      <p>
        Bayram Nöbet ödemeleri 7.Dönem Toplu Sözleşmesi 7(2) maddesine istinaden 657 sayılı Kanunun ek 33 üncü maddesi uyarınca ödenen nöbet ücretleri resmi ve dini bayram günleri için %25 artırımlı ödenir.
      </p>

      <h2>İDARİ İZİNLERDE NÖBET ÖDEMESİ NASIL HESAPLANIR?</h2>
      <p>
        İdari izinlerde nöbet ödemeleri 7.Dönem Toplu Sözleşmesi 42(1) maddesine istinaden 657 sayılı Kanunun ek 33 üncü maddesi kapsamında sayılan yerlerde resmi ve dini bayram tatili sebebiyle verilen genel idari izin günlerinde fiilen görev yapanlara, çalıştıkları süre ile orantılı olarak izin kullandırılır. İzin kullandırılmasının mümkün olmaması halinde ise bu çalışılan süreler karşılığında ilgililere 657 sayılı Kanunun ek 33 üncü maddesi kapsamında nöbet ücreti ödenir.
      </p>
    </div>
  );
}

export default InfoComponent;

import React, { useState } from "react";
import { Container, Row, Col, Form, Table, Alert,Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; 
const data = [
  {
    unvan: "Sağlık Memuru (Lisans)",
    kadro: "7 / 1",
    donem: "2024/7",
    gelirToplam: 58207.88,
    kesintiToplam: 10554.94,
    netOdenen: 47652.94,
  },
  {
    unvan: "Sağlık Teknikeri (Ön Lisans)",
    kadro: "4 / 3",
    donem: "2024/7",
    gelirToplam: 59766.81,
    kesintiToplam: 11910.13,
    netOdenen: 47856.68,
  },
  {
    unvan: "Uzman Doktor",
    kadro: "4 / 2",
    donem: "2024/7",
    gelirToplam: 89324.4,
    kesintiToplam: 20002.4,
    netOdenen: 69322.0,
  },
  {
    unvan: "Hemşire (Ön Lisans)",
    kadro: "4 / 1",
    donem: "2024/7",
    gelirToplam: 58227.65,
    kesintiToplam: 10566.8,
    netOdenen: 47570.85,
  },
  {
    unvan: "Hemşire (Lisans)",
    kadro: "3 / 1",
    donem: "2024/7",
    gelirToplam: 61020.75,
    kesintiToplam: 11428.42,
    netOdenen: 49592.33,
  },
  {
    unvan: "Diş Tabibi",
    kadro: "8 / 3",
    donem: "2024/7",
    gelirToplam: 71063.88,
    kesintiToplam: 13814.0,
    netOdenen: 57249.88,
  },
];

function MaasHesabi() {
  const [selectedUnvan, setSelectedUnvan] = useState(data[0]);
  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    const selected = data.find((item) => item.unvan === event.target.value);
    setSelectedUnvan(selected);
  };

  return (
    <Container className=" BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1 id="maas-hesabi" className="my-4">
        657 MAAŞ HESAPLAMASI
      </h1>
      <Form>
        <Form.Group as={Row} controlId="formUnvanSelect">
          <Form.Label column sm={2}>
            Ünvan Seçin
          </Form.Label>
          <Col sm={10}>
            <Form.Control as="select" onChange={handleSelectChange}>
              {data.map((item, index) => (
                <option key={index} value={item.unvan}>
                  {item.unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      </Form>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Kadro Derece/Kademe</th>
            <th>Ait Olduğu Dönem</th>
            <th>Gelir Toplamı</th>
            <th>Kesinti Toplamı</th>
            <th>Net Ödenen</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selectedUnvan.kadro}</td>
            <td>{selectedUnvan.donem}</td>
            <td>{selectedUnvan.gelirToplam}</td>
            <td>{selectedUnvan.kesintiToplam}</td>
            <td>{selectedUnvan.netOdenen}</td>
          </tr>
        </tbody>
      </Table>
      <Alert variant="info" className="mt-4">
        <h3>Bilgilendirme</h3>
        <h4>---------------------</h4>
      </Alert>
    </Container>
  );
}

export default MaasHesabi;

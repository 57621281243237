const MaasGelistirmeOdenegi = [
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 1,
        "Ilce": "Aladağ",
        "GelistirmeOdenegiPuani": 65
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 2,
        "Ilce": "Ceyhan",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 3,
        "Ilce": "Çukurova",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 4,
        "Ilce": "Feke",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 5,
        "Ilce": "İmamoğlu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 6,
        "Ilce": "Karaisalı",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 7,
        "Ilce": "Karataş",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 8,
        "Ilce": "Kozan",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 9,
        "Ilce": "Pozantı",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 10,
        "Ilce": "Saimbeyli",
        "GelistirmeOdenegiPuani": 65
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 11,
        "Ilce": "Sarıçam",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 12,
        "Ilce": "Seyhan",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 13,
        "Ilce": "Tufanbeyli",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 14,
        "Ilce": "Yumurtalık",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 1,
        "Il": "Adana",
        "No": 15,
        "Ilce": "Yüreğir",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 1,
        "Ilce": "Besni",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 2,
        "Ilce": "Çelikhan",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 3,
        "Ilce": "Gerger",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 4,
        "Ilce": "Gölbaşı",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 5,
        "Ilce": "Kahta",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 6,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 7,
        "Ilce": "Samsat",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 8,
        "Ilce": "Sincik",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 2,
        "Il": "Adıyaman",
        "No": 9,
        "Ilce": "Tut",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 1,
        "Ilce": "Başmakçı",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 2,
        "Ilce": "Bayat",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 3,
        "Ilce": "Bolvadin",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 4,
        "Ilce": "Çay",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 5,
        "Ilce": "Çobanlar",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 6,
        "Ilce": "Dazkırı",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 7,
        "Ilce": "Dinar",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 8,
        "Ilce": "Emirdağ",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 9,
        "Ilce": "Evciler",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 10,
        "Ilce": "Hocalar",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 11,
        "Ilce": "İhsaniye",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 12,
        "Ilce": "İscehisar",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 13,
        "Ilce": "Kızılören",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 14,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 15,
        "Ilce": "Sandıklı",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 16,
        "Ilce": "Sinanpaşa",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 17,
        "Ilce": "Şuhut",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 3,
        "Il": "Afyonkarahisar",
        "No": 18,
        "Ilce": "Sultandağı",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 4,
        "Il": "Ağrı",
        "No": 1,
        "Ilce": "Diyadin",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 4,
        "Il": "Ağrı",
        "No": 2,
        "Ilce": "Doğubayazıt",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 4,
        "Il": "Ağrı",
        "No": 3,
        "Ilce": "Eleşkirt",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 4,
        "Il": "Ağrı",
        "No": 4,
        "Ilce": "Hamur",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 4,
        "Il": "Ağrı",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 4,
        "Il": "Ağrı",
        "No": 6,
        "Ilce": "Patnos",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 4,
        "Il": "Ağrı",
        "No": 7,
        "Ilce": "Taşlıçay",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 4,
        "Il": "Ağrı",
        "No": 8,
        "Ilce": "Tutak",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 68,
        "Il": "Aksaray",
        "No": 1,
        "Ilce": "Ağaçören",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 68,
        "Il": "Aksaray",
        "No": 2,
        "Ilce": "Eskil",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 68,
        "Il": "Aksaray",
        "No": 3,
        "Ilce": "Gülağaç",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 68,
        "Il": "Aksaray",
        "No": 4,
        "Ilce": "Güzelyurt",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 68,
        "Il": "Aksaray",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 68,
        "Il": "Aksaray",
        "No": 6,
        "Ilce": "Ortaköy",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 68,
        "Il": "Aksaray",
        "No": 7,
        "Ilce": "Sarıyahşi",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 68,
        "Il": "Aksaray",
        "No": 8,
        "Ilce": "Sultanhanı",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 5,
        "Il": "Amasya",
        "No": 1,
        "Ilce": "Göynücek",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 5,
        "Il": "Amasya",
        "No": 2,
        "Ilce": "Gümüşhacıköy",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 5,
        "Il": "Amasya",
        "No": 3,
        "Ilce": "Hamamözü",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 5,
        "Il": "Amasya",
        "No": 4,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 5,
        "Il": "Amasya",
        "No": 5,
        "Ilce": "Merzifon",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 5,
        "Il": "Amasya",
        "No": 6,
        "Ilce": "Suluova",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 5,
        "Il": "Amasya",
        "No": 7,
        "Ilce": "Taşova",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 1,
        "Ilce": "Akyurt",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 2,
        "Ilce": "Altındağ",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 3,
        "Ilce": "Ayaş",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 4,
        "Ilce": "Bala",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 5,
        "Ilce": "Beypazarı",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 6,
        "Ilce": "Çamlıdere",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 7,
        "Ilce": "Çankaya",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 8,
        "Ilce": "Çubuk",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 9,
        "Ilce": "Elmadağ",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 10,
        "Ilce": "Etimesgut",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 11,
        "Ilce": "Evren",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 12,
        "Ilce": "Gölbaşı",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 13,
        "Ilce": "Güdül",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 14,
        "Ilce": "Haymana",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 15,
        "Ilce": "Kahramankazan",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 16,
        "Ilce": "Kalecik",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 17,
        "Ilce": "Keçiören",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 18,
        "Ilce": "Kızılcahamam",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 19,
        "Ilce": "Mamak",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 20,
        "Ilce": "Nallıhan",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 21,
        "Ilce": "Polatlı",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 22,
        "Ilce": "Pursaklar",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 23,
        "Ilce": "Şereflikoçhisar",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 24,
        "Ilce": "Sincan",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 6,
        "Il": "Ankara",
        "No": 25,
        "Ilce": "Yenimahalle",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 1,
        "Ilce": "Akseki",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 2,
        "Ilce": "Aksu",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 3,
        "Ilce": "Alanya",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 4,
        "Ilce": "Demre",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 5,
        "Ilce": "Döşemealtı",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 6,
        "Ilce": "Elmalı",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 7,
        "Ilce": "Finike",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 8,
        "Ilce": "Gazipaşa",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 9,
        "Ilce": "Gündoğmuş",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 10,
        "Ilce": "İbradı",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 11,
        "Ilce": "Kaş",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 12,
        "Ilce": "Kemer",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 13,
        "Ilce": "Kepez",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 14,
        "Ilce": "Konyaaltı",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 15,
        "Ilce": "Korkuteli",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 16,
        "Ilce": "Kumluca",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 17,
        "Ilce": "Manavgat",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 18,
        "Ilce": "Muratpaşa",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 7,
        "Il": "Antalya",
        "No": 19,
        "Ilce": "Serik",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 75,
        "Il": "Ardahan",
        "No": 1,
        "Ilce": "Çıldır",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 75,
        "Il": "Ardahan",
        "No": 2,
        "Ilce": "Damal",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 75,
        "Il": "Ardahan",
        "No": 3,
        "Ilce": "Göle",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 75,
        "Il": "Ardahan",
        "No": 4,
        "Ilce": "Hanak",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 75,
        "Il": "Ardahan",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 75,
        "Il": "Ardahan",
        "No": 6,
        "Ilce": "Posof",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 1,
        "Ilce": "Ardanuç",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 2,
        "Ilce": "Arhavi",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 3,
        "Ilce": "Borçka",
        "GelistirmeOdenegiPuani": 310
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 4,
        "Ilce": "Hopa",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 5,
        "Ilce": "Kemalpaşa",
        "GelistirmeOdenegiPuani": 300
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 6,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 300
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 7,
        "Ilce": "Murgul",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 8,
        "Ilce": "Şavşat",
        "GelistirmeOdenegiPuani": 370
    },
    {
        "Plaka": 8,
        "Il": "Artvin",
        "No": 9,
        "Ilce": "Yusufeli",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 1,
        "Ilce": "Bozdoğan",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 2,
        "Ilce": "Buharkent",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 3,
        "Ilce": "Çine",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 4,
        "Ilce": "Didim",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 5,
        "Ilce": "Efeler",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 6,
        "Ilce": "Germencik",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 7,
        "Ilce": "İncirliova",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 8,
        "Ilce": "Karacasu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 9,
        "Ilce": "Karpuzlu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 10,
        "Ilce": "Koçarlı",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 11,
        "Ilce": "Köşk",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 12,
        "Ilce": "Kuşadası",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 13,
        "Ilce": "Kuyucak",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 14,
        "Ilce": "Nazilli",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 15,
        "Ilce": "Söke",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 16,
        "Ilce": "Sultanhisar",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 9,
        "Il": "Aydın",
        "No": 17,
        "Ilce": "Yenipazar",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 1,
        "Ilce": "Altıeylül",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 2,
        "Ilce": "Ayvalık",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 3,
        "Ilce": "Balya",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 4,
        "Ilce": "Bandırma",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 5,
        "Ilce": "Bigadiç",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 6,
        "Ilce": "Burhaniye",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 7,
        "Ilce": "Dursunbey",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 8,
        "Ilce": "Edremit",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 9,
        "Ilce": "Erdek",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 10,
        "Ilce": "Gömeç",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 11,
        "Ilce": "Gönen",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 12,
        "Ilce": "Havran",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 13,
        "Ilce": "İvrindi",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 14,
        "Ilce": "Karesi",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 15,
        "Ilce": "Kepsut",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 16,
        "Ilce": "Manyas",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 17,
        "Ilce": "Marmara",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 18,
        "Ilce": "Savaştepe",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 19,
        "Ilce": "Sındırgı",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 10,
        "Il": "Balıkesir",
        "No": 20,
        "Ilce": "Susurluk",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 74,
        "Il": "Bartın",
        "No": 1,
        "Ilce": "Amasra",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 74,
        "Il": "Bartın",
        "No": 2,
        "Ilce": "Kurucaşile",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 74,
        "Il": "Bartın",
        "No": 3,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 74,
        "Il": "Bartın",
        "No": 4,
        "Ilce": "Ulus",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 72,
        "Il": "Batman",
        "No": 1,
        "Ilce": "Beşiri",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 72,
        "Il": "Batman",
        "No": 2,
        "Ilce": "Gercüş",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 72,
        "Il": "Batman",
        "No": 3,
        "Ilce": "Hasankeyf",
        "GelistirmeOdenegiPuani": 450
    },
    {
        "Plaka": 72,
        "Il": "Batman",
        "No": 4,
        "Ilce": "Kozluk",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 72,
        "Il": "Batman",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 430
    },
    {
        "Plaka": 72,
        "Il": "Batman",
        "No": 6,
        "Ilce": "Sason",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 69,
        "Il": "Bayburt",
        "No": 1,
        "Ilce": "Aydıntepe",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 69,
        "Il": "Bayburt",
        "No": 2,
        "Ilce": "Demirözü",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 69,
        "Il": "Bayburt",
        "No": 3,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 11,
        "Il": "Bilecik",
        "No": 1,
        "Ilce": "Bozüyük",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 11,
        "Il": "Bilecik",
        "No": 2,
        "Ilce": "Gölpazarı",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 11,
        "Il": "Bilecik",
        "No": 3,
        "Ilce": "İnhisar",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 11,
        "Il": "Bilecik",
        "No": 4,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 11,
        "Il": "Bilecik",
        "No": 5,
        "Ilce": "Osmaneli",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 11,
        "Il": "Bilecik",
        "No": 6,
        "Ilce": "Pazaryeri",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 11,
        "Il": "Bilecik",
        "No": 7,
        "Ilce": "Söğüt",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 11,
        "Il": "Bilecik",
        "No": 8,
        "Ilce": "Yenipazar",
        "GelistirmeOdenegiPuani": 85
    },
    {
        "Plaka": 12,
        "Il": "Bingöl",
        "No": 1,
        "Ilce": "Adaklı",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 12,
        "Il": "Bingöl",
        "No": 2,
        "Ilce": "Genç",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 12,
        "Il": "Bingöl",
        "No": 3,
        "Ilce": "Karlıova",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 12,
        "Il": "Bingöl",
        "No": 4,
        "Ilce": "Kiğı",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 12,
        "Il": "Bingöl",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 12,
        "Il": "Bingöl",
        "No": 6,
        "Ilce": "Solhan",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 12,
        "Il": "Bingöl",
        "No": 7,
        "Ilce": "Yayladere",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 12,
        "Il": "Bingöl",
        "No": 8,
        "Ilce": "Yedisu",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 13,
        "Il": "Bitlis",
        "No": 1,
        "Ilce": "Adilcevaz",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 13,
        "Il": "Bitlis",
        "No": 2,
        "Ilce": "Ahlat",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 13,
        "Il": "Bitlis",
        "No": 3,
        "Ilce": "Güroymak",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 13,
        "Il": "Bitlis",
        "No": 4,
        "Ilce": "Hizan",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 13,
        "Il": "Bitlis",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 13,
        "Il": "Bitlis",
        "No": 6,
        "Ilce": "Mutki",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 13,
        "Il": "Bitlis",
        "No": 7,
        "Ilce": "Tatvan",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 1,
        "Ilce": "Dörtdivan",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 2,
        "Ilce": "Gerede",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 3,
        "Ilce": "Göynük",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 4,
        "Ilce": "Kıbrıscık",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 5,
        "Ilce": "Mengen",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 6,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 7,
        "Ilce": "Mudurnu",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 8,
        "Ilce": "Seben",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 14,
        "Il": "Bolu",
        "No": 9,
        "Ilce": "Yeniçağa",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 1,
        "Ilce": "Ağlasun",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 2,
        "Ilce": "Altınyayla",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 3,
        "Ilce": "Bucak",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 4,
        "Ilce": "Çavdır",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 5,
        "Ilce": "Çeltikçi",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 6,
        "Ilce": "Gölhisar",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 7,
        "Ilce": "Karamanlı",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 8,
        "Ilce": "Kemer",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 9,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 10,
        "Ilce": "Tefenni",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 15,
        "Il": "Burdur",
        "No": 11,
        "Ilce": "Yeşilova",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 1,
        "Ilce": "Büyükorhan",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 2,
        "Ilce": "Gemlik",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 3,
        "Ilce": "Gürsu",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 4,
        "Ilce": "Harmancık",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 5,
        "Ilce": "İnegöl",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 6,
        "Ilce": "İznik",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 7,
        "Ilce": "Karacabey",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 8,
        "Ilce": "Keles",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 9,
        "Ilce": "Kestel",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 10,
        "Ilce": "Mudanya",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 11,
        "Ilce": "Mustafakemalpaşa",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 12,
        "Ilce": "Nilüfer",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 13,
        "Ilce": "Orhaneli",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 14,
        "Ilce": "Orhangazi",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 15,
        "Ilce": "Osmangazi",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 16,
        "Ilce": "Yenişehir",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 16,
        "Il": "Bursa",
        "No": 17,
        "Ilce": "Yıldırım",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 1,
        "Ilce": "Ayvacık",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 2,
        "Ilce": "Bayramiç",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 3,
        "Ilce": "Biga",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 4,
        "Ilce": "Bozcaada",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 5,
        "Ilce": "Çan",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 6,
        "Ilce": "Eceabat",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 7,
        "Ilce": "Ezine",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 8,
        "Ilce": "Gelibolu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 9,
        "Ilce": "Gökçeada",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 10,
        "Ilce": "Lapseki",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 11,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 17,
        "Il": "Çanakkale",
        "No": 12,
        "Ilce": "Yenice",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 1,
        "Ilce": "Atkaracalar",
        "GelistirmeOdenegiPuani": 115
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 2,
        "Ilce": "Bayramören",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 3,
        "Ilce": "Çerkeş",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 4,
        "Ilce": "Eldivan",
        "GelistirmeOdenegiPuani": 115
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 5,
        "Ilce": "Ilgaz",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 6,
        "Ilce": "Kızılırmak",
        "GelistirmeOdenegiPuani": 125
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 7,
        "Ilce": "Korgun",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 8,
        "Ilce": "Kurşunlu",
        "GelistirmeOdenegiPuani": 115
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 9,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 10,
        "Ilce": "Orta",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 11,
        "Ilce": "Şabanözü",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 18,
        "Il": "Çankırı",
        "No": 12,
        "Ilce": "Yapraklı",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 1,
        "Ilce": "Alaca",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 2,
        "Ilce": "Bayat",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 3,
        "Ilce": "Boğazkale",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 4,
        "Ilce": "Dodurga",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 5,
        "Ilce": "İskilip",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 6,
        "Ilce": "Kargı",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 7,
        "Ilce": "Laçin",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 8,
        "Ilce": "Mecitözü",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 9,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 10,
        "Ilce": "Oğuzlar",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 11,
        "Ilce": "Ortaköy",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 12,
        "Ilce": "Osmancık",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 13,
        "Ilce": "Sungurlu",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 19,
        "Il": "Çorum",
        "No": 14,
        "Ilce": "Uğurludağ",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 1,
        "Ilce": "Acıpayam",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 2,
        "Ilce": "Babadağ",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 3,
        "Ilce": "Baklan",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 4,
        "Ilce": "Bekilli",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 5,
        "Ilce": "Beyağaç",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 6,
        "Ilce": "Bozkurt",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 7,
        "Ilce": "Buldan",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 8,
        "Ilce": "Çal",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 9,
        "Ilce": "Çameli",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 10,
        "Ilce": "Çardak",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 11,
        "Ilce": "Çivril",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 12,
        "Ilce": "Güney",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 13,
        "Ilce": "Honaz",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 14,
        "Ilce": "Kale",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 15,
        "Ilce": "Merkezefendi",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 16,
        "Ilce": "Pamukkale",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 17,
        "Ilce": "Sarayköy",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 18,
        "Ilce": "Serinhisar",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 20,
        "Il": "Denizli",
        "No": 19,
        "Ilce": "Tavas",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 1,
        "Ilce": "Bağlar",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 2,
        "Ilce": "Bismil",
        "GelistirmeOdenegiPuani": 260
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 3,
        "Ilce": "Çermik",
        "GelistirmeOdenegiPuani": 270
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 4,
        "Ilce": "Çınar",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 5,
        "Ilce": "Çüngüş",
        "GelistirmeOdenegiPuani": 270
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 6,
        "Ilce": "Dicle",
        "GelistirmeOdenegiPuani": 270
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 7,
        "Ilce": "Eğil",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 8,
        "Ilce": "Ergani",
        "GelistirmeOdenegiPuani": 260
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 9,
        "Ilce": "Hani",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 10,
        "Ilce": "Hazro",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 11,
        "Ilce": "Kayapınar",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 12,
        "Ilce": "Kocaköy",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 13,
        "Ilce": "Kulp",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 14,
        "Ilce": "Lice",
        "GelistirmeOdenegiPuani": 270
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 15,
        "Ilce": "Silvan",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 16,
        "Ilce": "Sur",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 21,
        "Il": "Diyarbakır",
        "No": 17,
        "Ilce": "Yenişehir",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 81,
        "Il": "Düzce",
        "No": 1,
        "Ilce": "Akçakoca",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 81,
        "Il": "Düzce",
        "No": 2,
        "Ilce": "Çilimli",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 81,
        "Il": "Düzce",
        "No": 3,
        "Ilce": "Cumayeri",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 81,
        "Il": "Düzce",
        "No": 4,
        "Ilce": "Gölyaka",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 81,
        "Il": "Düzce",
        "No": 5,
        "Ilce": "Gümüşova",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 81,
        "Il": "Düzce",
        "No": 6,
        "Ilce": "Kaynaşlı",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 81,
        "Il": "Düzce",
        "No": 7,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 81,
        "Il": "Düzce",
        "No": 8,
        "Ilce": "Yığılca",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 1,
        "Ilce": "Enez",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 2,
        "Ilce": "Havsa",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 3,
        "Ilce": "İpsala",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 4,
        "Ilce": "Keşan",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 5,
        "Ilce": "Lalapaşa",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 6,
        "Ilce": "Meriç",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 7,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 8,
        "Ilce": "Süloğlu",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 22,
        "Il": "Edirne",
        "No": 9,
        "Ilce": "Uzunköprü",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 1,
        "Ilce": "Ağın",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 2,
        "Ilce": "Alacakaya",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 3,
        "Ilce": "Arıcak",
        "GelistirmeOdenegiPuani": 260
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 4,
        "Ilce": "Baskil",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 5,
        "Ilce": "Karakoçan",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 6,
        "Ilce": "Keban",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 7,
        "Ilce": "Kovancılar",
        "GelistirmeOdenegiPuani": 240
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 8,
        "Ilce": "Maden",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 9,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 10,
        "Ilce": "Palu",
        "GelistirmeOdenegiPuani": 240
    },
    {
        "Plaka": 23,
        "Il": "Elazığ",
        "No": 11,
        "Ilce": "Sivrice",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 1,
        "Ilce": "Çayırlı",
        "GelistirmeOdenegiPuani": 370
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 2,
        "Ilce": "İliç",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 3,
        "Ilce": "Kemah",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 4,
        "Ilce": "Kemaliye",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 6,
        "Ilce": "Otlukbeli",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 7,
        "Ilce": "Refahiye",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 8,
        "Ilce": "Tercan",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 24,
        "Il": "Erzincan",
        "No": 9,
        "Ilce": "Üzümlü",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 1,
        "Ilce": "Aşkale",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 2,
        "Ilce": "Aziziye",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 3,
        "Ilce": "Çat",
        "GelistirmeOdenegiPuani": 260
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 4,
        "Ilce": "Hınıs",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 5,
        "Ilce": "Horasan",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 6,
        "Ilce": "İspir",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 7,
        "Ilce": "Karaçoban",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 8,
        "Ilce": "Karayazı",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 9,
        "Ilce": "Köprüköy",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 10,
        "Ilce": "Narman",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 11,
        "Ilce": "Oltu",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 12,
        "Ilce": "Olur",
        "GelistirmeOdenegiPuani": 270
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 13,
        "Ilce": "Palandöken",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 14,
        "Ilce": "Pasinler",
        "GelistirmeOdenegiPuani": 210
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 15,
        "Ilce": "Pazaryolu",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 16,
        "Ilce": "Şenkaya",
        "GelistirmeOdenegiPuani": 280
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 17,
        "Ilce": "Tekman",
        "GelistirmeOdenegiPuani": 260
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 18,
        "Ilce": "Tortum",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 19,
        "Ilce": "Uzundere",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 25,
        "Il": "Erzurum",
        "No": 20,
        "Ilce": "Yakutiye",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 1,
        "Ilce": "Alpu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 2,
        "Ilce": "Beylikova",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 3,
        "Ilce": "Çifteler",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 4,
        "Ilce": "Günyüzü",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 5,
        "Ilce": "Han",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 6,
        "Ilce": "İnönü",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 7,
        "Ilce": "Mahmudiye",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 8,
        "Ilce": "Mihalgazi",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 9,
        "Ilce": "Mihalıççık",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 10,
        "Ilce": "Odunpazarı",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 11,
        "Ilce": "Sarıcakaya",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 12,
        "Ilce": "Seyitgazi",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 13,
        "Ilce": "Sivrihisar",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 26,
        "Il": "Eskişehir",
        "No": 14,
        "Ilce": "Tepebaşı",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 1,
        "Ilce": "Araban",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 2,
        "Ilce": "İslahiye",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 3,
        "Ilce": "Karkamış",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 4,
        "Ilce": "Nizip",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 5,
        "Ilce": "Nurdağı",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 6,
        "Ilce": "Oğuzeli",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 7,
        "Ilce": "Şahinbey",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 8,
        "Ilce": "Şehitkamil",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 27,
        "Il": "Gaziantep",
        "No": 9,
        "Ilce": "Yavuzeli",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 1,
        "Ilce": "Alucra",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 2,
        "Ilce": "Bulancak",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 3,
        "Ilce": "Çamoluk",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 4,
        "Ilce": "Çanakçı",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 5,
        "Ilce": "Dereli",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 6,
        "Ilce": "Doğankent",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 7,
        "Ilce": "Espiye",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 8,
        "Ilce": "Eynesil",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 9,
        "Ilce": "Görele",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 10,
        "Ilce": "Güce",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 11,
        "Ilce": "Keşap",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 12,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 13,
        "Ilce": "Piraziz",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 14,
        "Ilce": "Şebinkarahisar",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 15,
        "Ilce": "Tirebolu",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 28,
        "Il": "Giresun",
        "No": 16,
        "Ilce": "Yağlıdere",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 29,
        "Il": "Gümüşhane",
        "No": 1,
        "Ilce": "Kelkit",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 29,
        "Il": "Gümüşhane",
        "No": 2,
        "Ilce": "Köse",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 29,
        "Il": "Gümüşhane",
        "No": 3,
        "Ilce": "Kürtün",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 29,
        "Il": "Gümüşhane",
        "No": 4,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 300
    },
    {
        "Plaka": 29,
        "Il": "Gümüşhane",
        "No": 5,
        "Ilce": "Şiran",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 29,
        "Il": "Gümüşhane",
        "No": 6,
        "Ilce": "Torul",
        "GelistirmeOdenegiPuani": 320
    },
    {
        "Plaka": 30,
        "Il": "Hakkari",
        "No": 1,
        "Ilce": "Çukurca",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 30,
        "Il": "Hakkari",
        "No": 2,
        "Ilce": "Derecik",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 30,
        "Il": "Hakkari",
        "No": 3,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 30,
        "Il": "Hakkari",
        "No": 4,
        "Ilce": "Şemdinli",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 30,
        "Il": "Hakkari",
        "No": 5,
        "Ilce": "Yüksekova",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 1,
        "Ilce": "Altınözü",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 2,
        "Ilce": "Antakya",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 3,
        "Ilce": "Arsuz",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 4,
        "Ilce": "Belen",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 5,
        "Ilce": "Defne",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 6,
        "Ilce": "Dörtyol",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 7,
        "Ilce": "Erzin",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 8,
        "Ilce": "Hassa",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 9,
        "Ilce": "İskenderun",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 10,
        "Ilce": "Kırıkhan",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 11,
        "Ilce": "Kumlu",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 12,
        "Ilce": "Payas",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 13,
        "Ilce": "Reyhanlı",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 14,
        "Ilce": "Samandağ",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 31,
        "Il": "Hatay",
        "No": 15,
        "Ilce": "Yayladağı",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 76,
        "Il": "Iğdır",
        "No": 1,
        "Ilce": "Aralık",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 76,
        "Il": "Iğdır",
        "No": 2,
        "Ilce": "Karakoyunlu",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 76,
        "Il": "Iğdır",
        "No": 3,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 76,
        "Il": "Iğdır",
        "No": 4,
        "Ilce": "Tuzluca",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 1,
        "Ilce": "Aksu",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 2,
        "Ilce": "Atabey",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 3,
        "Ilce": "Eğirdir",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 4,
        "Ilce": "Gelendost",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 5,
        "Ilce": "Gönen",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 6,
        "Ilce": "Keçiborlu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 7,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 8,
        "Ilce": "Şarkikaraağaç",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 9,
        "Ilce": "Senirkent",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 10,
        "Ilce": "Sütçüler",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 11,
        "Ilce": "Uluborlu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 12,
        "Ilce": "Yalvaç",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 32,
        "Il": "Isparta",
        "No": 13,
        "Ilce": "Yenişarbademli",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 1,
        "Ilce": "Adalar",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 2,
        "Ilce": "Arnavutköy",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 3,
        "Ilce": "Ataşehir",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 4,
        "Ilce": "Avcılar",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 5,
        "Ilce": "Bağcılar",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 6,
        "Ilce": "Bahçelievler",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 7,
        "Ilce": "Bakırköy",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 8,
        "Ilce": "Başakşehir",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 9,
        "Ilce": "Bayrampaşa",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 10,
        "Ilce": "Beşiktaş",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 11,
        "Ilce": "Beykoz",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 12,
        "Ilce": "Beylikdüzü",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 13,
        "Ilce": "Beyoğlu",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 14,
        "Ilce": "Büyükçekmece",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 15,
        "Ilce": "Çatalca",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 16,
        "Ilce": "Çekmeköy",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 17,
        "Ilce": "Esenler",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 18,
        "Ilce": "Esenyurt",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 19,
        "Ilce": "Eyüpsultan",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 20,
        "Ilce": "Fatih",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 21,
        "Ilce": "Gaziosmanpaşa",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 22,
        "Ilce": "Güngören",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 23,
        "Ilce": "Kadıköy",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 24,
        "Ilce": "Kağıthane",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 25,
        "Ilce": "Kartal",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 26,
        "Ilce": "Küçükçekmece",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 27,
        "Ilce": "Maltepe",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 28,
        "Ilce": "Pendik",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 29,
        "Ilce": "Sancaktepe",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 30,
        "Ilce": "Sarıyer",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 31,
        "Ilce": "Şile",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 32,
        "Ilce": "Silivri",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 33,
        "Ilce": "Şişli",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 34,
        "Ilce": "Sultanbeyli",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 35,
        "Ilce": "Sultangazi",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 36,
        "Ilce": "Tuzla",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 37,
        "Ilce": "Ümraniye",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 38,
        "Ilce": "Üsküdar",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 34,
        "Il": "İstanbul",
        "No": 39,
        "Ilce": "Zeytinburnu",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 1,
        "Ilce": "Aliağa",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 2,
        "Ilce": "Balçova",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 3,
        "Ilce": "Bayındır",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 4,
        "Ilce": "Bayraklı",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 5,
        "Ilce": "Bergama",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 6,
        "Ilce": "Beydağ",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 7,
        "Ilce": "Bornova",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 8,
        "Ilce": "Buca",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 9,
        "Ilce": "Çeşme",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 10,
        "Ilce": "Çiğli",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 11,
        "Ilce": "Dikili",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 12,
        "Ilce": "Foça",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 13,
        "Ilce": "Gaziemir",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 14,
        "Ilce": "Güzelbahçe",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 15,
        "Ilce": "Karabağlar",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 16,
        "Ilce": "Karaburun",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 17,
        "Ilce": "Karşıyaka",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 18,
        "Ilce": "Kemalpaşa",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 19,
        "Ilce": "Kınık",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 20,
        "Ilce": "Kiraz",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 21,
        "Ilce": "Konak",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 22,
        "Ilce": "Menderes",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 23,
        "Ilce": "Menemen",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 24,
        "Ilce": "Narlıdere",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 25,
        "Ilce": "Ödemiş",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 26,
        "Ilce": "Seferihisar",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 27,
        "Ilce": "Selçuk",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 28,
        "Ilce": "Tire",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 29,
        "Ilce": "Torbalı",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 35,
        "Il": "İzmir",
        "No": 30,
        "Ilce": "Urla",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 1,
        "Ilce": "Afşin",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 2,
        "Ilce": "Andırın",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 3,
        "Ilce": "Çağlayancerit",
        "GelistirmeOdenegiPuani": 180
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 4,
        "Ilce": "Dulkadiroğlu",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 5,
        "Ilce": "Ekinözü",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 6,
        "Ilce": "Elbistan",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 7,
        "Ilce": "Göksun",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 8,
        "Ilce": "Nurhak",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 9,
        "Ilce": "Onikişubat",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 10,
        "Ilce": "Pazarcık",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 46,
        "Il": "Kahramanmaraş",
        "No": 11,
        "Ilce": "Türkoğlu",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 78,
        "Il": "Karabük",
        "No": 1,
        "Ilce": "Eflani",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 78,
        "Il": "Karabük",
        "No": 2,
        "Ilce": "Eskipazar",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 78,
        "Il": "Karabük",
        "No": 3,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 78,
        "Il": "Karabük",
        "No": 4,
        "Ilce": "Ovacık",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 78,
        "Il": "Karabük",
        "No": 5,
        "Ilce": "Safranbolu",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 78,
        "Il": "Karabük",
        "No": 6,
        "Ilce": "Yenice",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 70,
        "Il": "Karaman",
        "No": 1,
        "Ilce": "Ayrancı",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 70,
        "Il": "Karaman",
        "No": 2,
        "Ilce": "Başyayla",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 70,
        "Il": "Karaman",
        "No": 3,
        "Ilce": "Ermenek",
        "GelistirmeOdenegiPuani": 105
    },
    {
        "Plaka": 70,
        "Il": "Karaman",
        "No": 4,
        "Ilce": "Kazımkarabekir",
        "GelistirmeOdenegiPuani": 95
    },
    {
        "Plaka": 70,
        "Il": "Karaman",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 70,
        "Il": "Karaman",
        "No": 6,
        "Ilce": "Sarıveliler",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 36,
        "Il": "Kars",
        "No": 1,
        "Ilce": "Akyaka",
        "GelistirmeOdenegiPuani": 370
    },
    {
        "Plaka": 36,
        "Il": "Kars",
        "No": 2,
        "Ilce": "Arpaçay",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 36,
        "Il": "Kars",
        "No": 3,
        "Ilce": "Digor",
        "GelistirmeOdenegiPuani": 370
    },
    {
        "Plaka": 36,
        "Il": "Kars",
        "No": 4,
        "Ilce": "Kağızman",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 36,
        "Il": "Kars",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 36,
        "Il": "Kars",
        "No": 6,
        "Ilce": "Sarıkamış",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 36,
        "Il": "Kars",
        "No": 7,
        "Ilce": "Selim",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 36,
        "Il": "Kars",
        "No": 8,
        "Ilce": "Susuz",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 1,
        "Ilce": "Abana",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 2,
        "Ilce": "Ağlı",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 3,
        "Ilce": "Araç",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 4,
        "Ilce": "Azdavay",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 5,
        "Ilce": "Bozkurt",
        "GelistirmeOdenegiPuani": 135
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 6,
        "Ilce": "Çatalzeytin",
        "GelistirmeOdenegiPuani": 145
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 7,
        "Ilce": "Cide",
        "GelistirmeOdenegiPuani": 145
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 8,
        "Ilce": "Daday",
        "GelistirmeOdenegiPuani": 145
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 9,
        "Ilce": "Devrekani",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 10,
        "Ilce": "Doğanyurt",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 11,
        "Ilce": "Hanönü",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 12,
        "Ilce": "İhsangazi",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 13,
        "Ilce": "İnebolu",
        "GelistirmeOdenegiPuani": 135
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 14,
        "Ilce": "Küre",
        "GelistirmeOdenegiPuani": 145
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 15,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 16,
        "Ilce": "Pınarbaşı",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 17,
        "Ilce": "Şenpazar",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 18,
        "Ilce": "Seydiler",
        "GelistirmeOdenegiPuani": 135
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 19,
        "Ilce": "Taşköprü",
        "GelistirmeOdenegiPuani": 135
    },
    {
        "Plaka": 37,
        "Il": "Kastamonu",
        "No": 20,
        "Ilce": "Tosya",
        "GelistirmeOdenegiPuani": 135
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 1,
        "Ilce": "Akkışla",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 2,
        "Ilce": "Bünyan",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 3,
        "Ilce": "Develi",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 4,
        "Ilce": "Felahiye",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 5,
        "Ilce": "Hacılar",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 6,
        "Ilce": "İncesu",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 7,
        "Ilce": "Kocasinan",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 8,
        "Ilce": "Melikgazi",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 9,
        "Ilce": "Özvatan",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 10,
        "Ilce": "Pınarbaşı",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 11,
        "Ilce": "Sarıoğlan",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 12,
        "Ilce": "Sarız",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 13,
        "Ilce": "Talas",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 14,
        "Ilce": "Tomarza",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 15,
        "Ilce": "Yahyalı",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 38,
        "Il": "Kayseri",
        "No": 16,
        "Ilce": "Yeşilhisar",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 79,
        "Il": "Kilis",
        "No": 1,
        "Ilce": "Elbeyli",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 79,
        "Il": "Kilis",
        "No": 2,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 79,
        "Il": "Kilis",
        "No": 3,
        "Ilce": "Musabeyli",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 79,
        "Il": "Kilis",
        "No": 4,
        "Ilce": "Polateli",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 1,
        "Ilce": "Bahşılı",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 2,
        "Ilce": "Balışeyh",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 3,
        "Ilce": "Çelebi",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 4,
        "Ilce": "Delice",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 5,
        "Ilce": "Karakeçili",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 6,
        "Ilce": "Keskin",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 7,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 8,
        "Ilce": "Sulakyurt",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 71,
        "Il": "Kırıkkale",
        "No": 9,
        "Ilce": "Yahşihan",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 39,
        "Il": "Kırklareli",
        "No": 1,
        "Ilce": "Babaeski",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 39,
        "Il": "Kırklareli",
        "No": 2,
        "Ilce": "Demirköy",
        "GelistirmeOdenegiPuani": 85
    },
    {
        "Plaka": 39,
        "Il": "Kırklareli",
        "No": 3,
        "Ilce": "Kofçaz",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 39,
        "Il": "Kırklareli",
        "No": 4,
        "Ilce": "Lüleburgaz",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 39,
        "Il": "Kırklareli",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 39,
        "Il": "Kırklareli",
        "No": 6,
        "Ilce": "Pehlivanköy",
        "GelistirmeOdenegiPuani": 85
    },
    {
        "Plaka": 39,
        "Il": "Kırklareli",
        "No": 7,
        "Ilce": "Pınarhisar",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 39,
        "Il": "Kırklareli",
        "No": 8,
        "Ilce": "Vize",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 40,
        "Il": "Kırşehir",
        "No": 1,
        "Ilce": "Akçakent",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 40,
        "Il": "Kırşehir",
        "No": 2,
        "Ilce": "Akpınar",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 40,
        "Il": "Kırşehir",
        "No": 3,
        "Ilce": "Boztepe",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 40,
        "Il": "Kırşehir",
        "No": 4,
        "Ilce": "Çiçekdağı",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 40,
        "Il": "Kırşehir",
        "No": 5,
        "Ilce": "Kaman",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 40,
        "Il": "Kırşehir",
        "No": 6,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 40,
        "Il": "Kırşehir",
        "No": 7,
        "Ilce": "Mucur",
        "GelistirmeOdenegiPuani": 105
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 1,
        "Ilce": "Başiskele",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 2,
        "Ilce": "Çayırova",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 3,
        "Ilce": "Darıca",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 4,
        "Ilce": "Derince",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 5,
        "Ilce": "Dilovası",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 6,
        "Ilce": "Gebze",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 7,
        "Ilce": "Gölcük",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 8,
        "Ilce": "İzmit",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 9,
        "Ilce": "Kandıra",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 10,
        "Ilce": "Karamürsel",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 11,
        "Ilce": "Kartepe",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 41,
        "Il": "Kocaeli",
        "No": 12,
        "Ilce": "Körfez",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 1,
        "Ilce": "Ahırlı",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 2,
        "Ilce": "Akören",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 3,
        "Ilce": "Akşehir",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 4,
        "Ilce": "Altınekin",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 5,
        "Ilce": "Beyşehir",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 6,
        "Ilce": "Bozkır",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 7,
        "Ilce": "Çeltik",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 8,
        "Ilce": "Cihanbeyli",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 9,
        "Ilce": "Çumra",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 10,
        "Ilce": "Derbent",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 11,
        "Ilce": "Derebucak",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 12,
        "Ilce": "Doğanhisar",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 13,
        "Ilce": "Emirgazi",
        "GelistirmeOdenegiPuani": 45
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 14,
        "Ilce": "Ereğli",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 15,
        "Ilce": "Güneysınır",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 16,
        "Ilce": "Hadim",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 17,
        "Ilce": "Halkapınar",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 18,
        "Ilce": "Hüyük",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 19,
        "Ilce": "Ilgın",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 20,
        "Ilce": "Kadınhanı",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 21,
        "Ilce": "Karapınar",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 22,
        "Ilce": "Karatay",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 23,
        "Ilce": "Kulu",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 24,
        "Ilce": "Meram",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 25,
        "Ilce": "Sarayönü",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 26,
        "Ilce": "Selçuklu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 27,
        "Ilce": "Seydişehir",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 28,
        "Ilce": "Taşkent",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 29,
        "Ilce": "Tuzlukçu",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 30,
        "Ilce": "Yalıhüyük",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 42,
        "Il": "Konya",
        "No": 31,
        "Ilce": "Yunak",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 1,
        "Ilce": "Altıntaş",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 2,
        "Ilce": "Aslanapa",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 3,
        "Ilce": "Çavdarhisar",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 4,
        "Ilce": "Domaniç",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 5,
        "Ilce": "Dumlupınar",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 6,
        "Ilce": "Emet",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 7,
        "Ilce": "Gediz",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 8,
        "Ilce": "Hisarcık",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 9,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 10,
        "Ilce": "Pazarlar",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 11,
        "Ilce": "Şaphane",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 12,
        "Ilce": "Simav",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 43,
        "Il": "Kütahya",
        "No": 13,
        "Ilce": "Tavşanlı",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 1,
        "Ilce": "Akçadağ",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 2,
        "Ilce": "Arapgir",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 3,
        "Ilce": "Arguvan",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 4,
        "Ilce": "Battalgazi",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 5,
        "Ilce": "Darende",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 6,
        "Ilce": "Doğanşehir",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 7,
        "Ilce": "Doğanyol",
        "GelistirmeOdenegiPuani": 240
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 8,
        "Ilce": "Hekimhan",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 9,
        "Ilce": "Kale",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 10,
        "Ilce": "Kuluncak",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 11,
        "Ilce": "Pütürge",
        "GelistirmeOdenegiPuani": 240
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 12,
        "Ilce": "Yazıhan",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 44,
        "Il": "Malatya",
        "No": 13,
        "Ilce": "Yeşilyurt",
        "GelistirmeOdenegiPuani": 190
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 1,
        "Ilce": "Ahmetli",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 2,
        "Ilce": "Akhisar",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 3,
        "Ilce": "Alaşehir",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 4,
        "Ilce": "Demirci",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 5,
        "Ilce": "Gölmarmara",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 6,
        "Ilce": "Gördes",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 7,
        "Ilce": "Kırkağaç",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 8,
        "Ilce": "Köprübaşı",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 9,
        "Ilce": "Kula",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 10,
        "Ilce": "Salihli",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 11,
        "Ilce": "Sarıgöl",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 12,
        "Ilce": "Saruhanlı",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 13,
        "Ilce": "Şehzadeler",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 14,
        "Ilce": "Selendi",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 15,
        "Ilce": "Soma",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 16,
        "Ilce": "Turgutlu",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 45,
        "Il": "Manisa",
        "No": 17,
        "Ilce": "Yunusemre",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 1,
        "Ilce": "Artuklu",
        "GelistirmeOdenegiPuani": 450
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 2,
        "Ilce": "Dargeçit",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 3,
        "Ilce": "Derik",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 4,
        "Ilce": "Kızıltepe",
        "GelistirmeOdenegiPuani": 470
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 5,
        "Ilce": "Mazıdağı",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 6,
        "Ilce": "Midyat",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 7,
        "Ilce": "Nusaybin",
        "GelistirmeOdenegiPuani": 470
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 8,
        "Ilce": "Ömerli",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 9,
        "Ilce": "Savur",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 47,
        "Il": "Mardin",
        "No": 10,
        "Ilce": "Yeşilli",
        "GelistirmeOdenegiPuani": 450
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 1,
        "Ilce": "Akdeniz",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 2,
        "Ilce": "Anamur",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 3,
        "Ilce": "Aydıncık",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 4,
        "Ilce": "Bozyazı",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 5,
        "Ilce": "Çamlıyayla",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 6,
        "Ilce": "Erdemli",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 7,
        "Ilce": "Gülnar",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 8,
        "Ilce": "Mezitli",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 9,
        "Ilce": "Mut",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 10,
        "Ilce": "Silifke",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 11,
        "Ilce": "Tarsus",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 12,
        "Ilce": "Toroslar",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 33,
        "Il": "Mersin",
        "No": 13,
        "Ilce": "Yenişehir",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 1,
        "Ilce": "Bodrum",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 2,
        "Ilce": "Dalaman",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 3,
        "Ilce": "Datça",
        "GelistirmeOdenegiPuani": 10
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 4,
        "Ilce": "Fethiye",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 5,
        "Ilce": "Kavaklıdere",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 6,
        "Ilce": "Köyceğiz",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 7,
        "Ilce": "Marmaris",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 8,
        "Ilce": "Menteşe",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 9,
        "Ilce": "Milas",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 10,
        "Ilce": "Ortaca",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 11,
        "Ilce": "Seydikemer",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 12,
        "Ilce": "Ula",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 48,
        "Il": "Muğla",
        "No": 13,
        "Ilce": "Yatağan",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 49,
        "Il": "Muş",
        "No": 1,
        "Ilce": "Bulanık",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 49,
        "Il": "Muş",
        "No": 2,
        "Ilce": "Hasköy",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 49,
        "Il": "Muş",
        "No": 3,
        "Ilce": "Korkut",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 49,
        "Il": "Muş",
        "No": 4,
        "Ilce": "Malazgirt",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 49,
        "Il": "Muş",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 49,
        "Il": "Muş",
        "No": 6,
        "Ilce": "Varto",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 50,
        "Il": "Nevşehir",
        "No": 1,
        "Ilce": "Acıgöl",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 50,
        "Il": "Nevşehir",
        "No": 2,
        "Ilce": "Avanos",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 50,
        "Il": "Nevşehir",
        "No": 3,
        "Ilce": "Derinkuyu",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 50,
        "Il": "Nevşehir",
        "No": 4,
        "Ilce": "Gülşehir",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 50,
        "Il": "Nevşehir",
        "No": 5,
        "Ilce": "Hacıbektaş",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 50,
        "Il": "Nevşehir",
        "No": 6,
        "Ilce": "Kozaklı",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 50,
        "Il": "Nevşehir",
        "No": 7,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 50,
        "Il": "Nevşehir",
        "No": 8,
        "Ilce": "Ürgüp",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 51,
        "Il": "Niğde",
        "No": 1,
        "Ilce": "Altunhisar",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 51,
        "Il": "Niğde",
        "No": 2,
        "Ilce": "Bor",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 51,
        "Il": "Niğde",
        "No": 3,
        "Ilce": "Çamardı",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 51,
        "Il": "Niğde",
        "No": 4,
        "Ilce": "Çiftlik",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 51,
        "Il": "Niğde",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 51,
        "Il": "Niğde",
        "No": 6,
        "Ilce": "Ulukışla",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 1,
        "Ilce": "Akkuş",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 2,
        "Ilce": "Altınordu",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 3,
        "Ilce": "Aybastı",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 4,
        "Ilce": "Çamaş",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 5,
        "Ilce": "Çatalpınar",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 6,
        "Ilce": "Çaybaşı",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 7,
        "Ilce": "Fatsa",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 8,
        "Ilce": "Gölköy",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 9,
        "Ilce": "Gülyalı",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 10,
        "Ilce": "Gürgentepe",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 11,
        "Ilce": "İkizce",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 12,
        "Ilce": "Kabadüz",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 13,
        "Ilce": "Kabataş",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 14,
        "Ilce": "Korgan",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 15,
        "Ilce": "Kumru",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 16,
        "Ilce": "Mesudiye",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 17,
        "Ilce": "Perşembe",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 18,
        "Ilce": "Ulubey",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 52,
        "Il": "Ordu",
        "No": 19,
        "Ilce": "Ünye",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 80,
        "Il": "Osmaniye",
        "No": 1,
        "Ilce": "Bahçe",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 80,
        "Il": "Osmaniye",
        "No": 2,
        "Ilce": "Düziçi",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 80,
        "Il": "Osmaniye",
        "No": 3,
        "Ilce": "Hasanbeyli",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 80,
        "Il": "Osmaniye",
        "No": 4,
        "Ilce": "Kadirli",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 80,
        "Il": "Osmaniye",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 80,
        "Il": "Osmaniye",
        "No": 6,
        "Ilce": "Sumbas",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 80,
        "Il": "Osmaniye",
        "No": 7,
        "Ilce": "Toprakkale",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 1,
        "Ilce": "Ardeşen",
        "GelistirmeOdenegiPuani": 210
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 2,
        "Ilce": "Çamlıhemşin",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 3,
        "Ilce": "Çayeli",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 4,
        "Ilce": "Derepazarı",
        "GelistirmeOdenegiPuani": 180
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 5,
        "Ilce": "Fındıklı",
        "GelistirmeOdenegiPuani": 220
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 6,
        "Ilce": "Güneysu",
        "GelistirmeOdenegiPuani": 210
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 7,
        "Ilce": "Hemşin",
        "GelistirmeOdenegiPuani": 210
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 8,
        "Ilce": "İkizdere",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 9,
        "Ilce": "İyidere",
        "GelistirmeOdenegiPuani": 180
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 10,
        "Ilce": "Kalkandere",
        "GelistirmeOdenegiPuani": 210
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 11,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 53,
        "Il": "Rize",
        "No": 12,
        "Ilce": "Pazar",
        "GelistirmeOdenegiPuani": 210
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 1,
        "Ilce": "Adapazarı",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 2,
        "Ilce": "Akyazı",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 3,
        "Ilce": "Arifiye",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 4,
        "Ilce": "Erenler",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 5,
        "Ilce": "Ferizli",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 6,
        "Ilce": "Geyve",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 7,
        "Ilce": "Hendek",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 8,
        "Ilce": "Karapürçek",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 9,
        "Ilce": "Karasu",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 10,
        "Ilce": "Kaynarca",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 11,
        "Ilce": "Kocaali",
        "GelistirmeOdenegiPuani": 25
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 12,
        "Ilce": "Pamukova",
        "GelistirmeOdenegiPuani": 20
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 13,
        "Ilce": "Sapanca",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 14,
        "Ilce": "Serdivan",
        "GelistirmeOdenegiPuani": 15
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 15,
        "Ilce": "Söğütlü",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 54,
        "Il": "Sakarya",
        "No": 16,
        "Ilce": "Taraklı",
        "GelistirmeOdenegiPuani": 35
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 1,
        "Ilce": "Ondokuzmayıs",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 2,
        "Ilce": "Alaçam",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 3,
        "Ilce": "Asarcık",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 4,
        "Ilce": "Atakum",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 5,
        "Ilce": "Ayvacık",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 6,
        "Ilce": "Bafra",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 7,
        "Ilce": "Canik",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 8,
        "Ilce": "Çarşamba",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 9,
        "Ilce": "Havza",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 10,
        "Ilce": "İlkadım",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 11,
        "Ilce": "Kavak",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 12,
        "Ilce": "Ladik",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 13,
        "Ilce": "Salıpazarı",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 14,
        "Ilce": "Tekkeköy",
        "GelistirmeOdenegiPuani": 30
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 15,
        "Ilce": "Terme",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 16,
        "Ilce": "Vezirköprü",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 55,
        "Il": "Samsun",
        "No": 17,
        "Ilce": "Yakakent",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 56,
        "Il": "Siirt",
        "No": 1,
        "Ilce": "Baykan",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 56,
        "Il": "Siirt",
        "No": 2,
        "Ilce": "Eruh",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 56,
        "Il": "Siirt",
        "No": 3,
        "Ilce": "Kurtalan",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 56,
        "Il": "Siirt",
        "No": 4,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 56,
        "Il": "Siirt",
        "No": 5,
        "Ilce": "Pervari",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 56,
        "Il": "Siirt",
        "No": 6,
        "Ilce": "Şirvan",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 56,
        "Il": "Siirt",
        "No": 7,
        "Ilce": "Tillo",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 1,
        "Ilce": "Ayancık",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 2,
        "Ilce": "Boyabat",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 3,
        "Ilce": "Dikmen",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 4,
        "Ilce": "Durağan",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 5,
        "Ilce": "Erfelek",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 6,
        "Ilce": "Gerze",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 7,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 8,
        "Ilce": "Saraydüzü",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 57,
        "Il": "Sinop",
        "No": 9,
        "Ilce": "Türkeli",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 1,
        "Ilce": "Akıncılar",
        "GelistirmeOdenegiPuani": 180
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 2,
        "Ilce": "Altınyayla",
        "GelistirmeOdenegiPuani": 180
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 3,
        "Ilce": "Divriği",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 4,
        "Ilce": "Doğanşar",
        "GelistirmeOdenegiPuani": 190
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 5,
        "Ilce": "Gemerek",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 6,
        "Ilce": "Gölova",
        "GelistirmeOdenegiPuani": 190
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 7,
        "Ilce": "Gürün",
        "GelistirmeOdenegiPuani": 190
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 8,
        "Ilce": "Hafik",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 9,
        "Ilce": "İmranlı",
        "GelistirmeOdenegiPuani": 180
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 10,
        "Ilce": "Kangal",
        "GelistirmeOdenegiPuani": 190
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 11,
        "Ilce": "Koyulhisar",
        "GelistirmeOdenegiPuani": 190
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 12,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 13,
        "Ilce": "Şarkışla",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 14,
        "Ilce": "Suşehri",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 15,
        "Ilce": "Ulaş",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 16,
        "Ilce": "Yıldızeli",
        "GelistirmeOdenegiPuani": 180
    },
    {
        "Plaka": 58,
        "Il": "Sivas",
        "No": 17,
        "Ilce": "Zara",
        "GelistirmeOdenegiPuani": 160
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 1,
        "Ilce": "Akçakale",
        "GelistirmeOdenegiPuani": 260
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 2,
        "Ilce": "Birecik",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 3,
        "Ilce": "Bozova",
        "GelistirmeOdenegiPuani": 240
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 4,
        "Ilce": "Ceylanpınar",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 5,
        "Ilce": "Eyyübiye",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 6,
        "Ilce": "Halfeti",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 7,
        "Ilce": "Haliliye",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 8,
        "Ilce": "Harran",
        "GelistirmeOdenegiPuani": 260
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 9,
        "Ilce": "Hilvan",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 10,
        "Ilce": "Karaköprü",
        "GelistirmeOdenegiPuani": 230
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 11,
        "Ilce": "Siverek",
        "GelistirmeOdenegiPuani": 250
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 12,
        "Ilce": "Suruç",
        "GelistirmeOdenegiPuani": 240
    },
    {
        "Plaka": 63,
        "Il": "Şanlıurfa",
        "No": 13,
        "Ilce": "Viranşehir",
        "GelistirmeOdenegiPuani": 240
    },
    {
        "Plaka": 73,
        "Il": "Şırnak",
        "No": 1,
        "Ilce": "Beytüşşebap",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 73,
        "Il": "Şırnak",
        "No": 2,
        "Ilce": "Cizre",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 73,
        "Il": "Şırnak",
        "No": 3,
        "Ilce": "Güçlükonak",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 73,
        "Il": "Şırnak",
        "No": 4,
        "Ilce": "İdil",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 73,
        "Il": "Şırnak",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 73,
        "Il": "Şırnak",
        "No": 6,
        "Ilce": "Silopi",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 73,
        "Il": "Şırnak",
        "No": 7,
        "Ilce": "Uludere",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 1,
        "Ilce": "Çerkezköy",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 2,
        "Ilce": "Çorlu",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 3,
        "Ilce": "Ergene",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 4,
        "Ilce": "Hayrabolu",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 5,
        "Ilce": "Kapaklı",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 6,
        "Ilce": "Malkara",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 7,
        "Ilce": "Marmaraereğlisi",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 8,
        "Ilce": "Muratlı",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 9,
        "Ilce": "Saray",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 10,
        "Ilce": "Şarköy",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 59,
        "Il": "Tekirdağ",
        "No": 11,
        "Ilce": "Süleymanpaşa",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 1,
        "Ilce": "Almus",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 2,
        "Ilce": "Artova",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 3,
        "Ilce": "Başçiftlik",
        "GelistirmeOdenegiPuani": 130
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 4,
        "Ilce": "Erbaa",
        "GelistirmeOdenegiPuani": 120
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 6,
        "Ilce": "Niksar",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 7,
        "Ilce": "Pazar",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 8,
        "Ilce": "Reşadiye",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 9,
        "Ilce": "Sulusaray",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 10,
        "Ilce": "Turhal",
        "GelistirmeOdenegiPuani": 100
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 11,
        "Ilce": "Yeşilyurt",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 60,
        "Il": "Tokat",
        "No": 12,
        "Ilce": "Zile",
        "GelistirmeOdenegiPuani": 110
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 1,
        "Ilce": "Akçaabat",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 2,
        "Ilce": "Araklı",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 3,
        "Ilce": "Arsin",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 4,
        "Ilce": "Beşikdüzü",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 5,
        "Ilce": "Çarşıbaşı",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 6,
        "Ilce": "Çaykara",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 7,
        "Ilce": "Dernekpazarı",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 8,
        "Ilce": "Düzköy",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 9,
        "Ilce": "Hayrat",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 10,
        "Ilce": "Köprübaşı",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 11,
        "Ilce": "Maçka",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 12,
        "Ilce": "Of",
        "GelistirmeOdenegiPuani": 80
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 13,
        "Ilce": "Ortahisar",
        "GelistirmeOdenegiPuani": 50
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 14,
        "Ilce": "Şalpazarı",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 15,
        "Ilce": "Sürmene",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 16,
        "Ilce": "Tonya",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 17,
        "Ilce": "Vakfıkebir",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 61,
        "Il": "Trabzon",
        "No": 18,
        "Ilce": "Yomra",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 62,
        "Il": "Tunceli",
        "No": 1,
        "Ilce": "Çemişgezek",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 62,
        "Il": "Tunceli",
        "No": 2,
        "Ilce": "Hozat",
        "GelistirmeOdenegiPuani": 470
    },
    {
        "Plaka": 62,
        "Il": "Tunceli",
        "No": 3,
        "Ilce": "Mazgirt",
        "GelistirmeOdenegiPuani": 500
    },
    {
        "Plaka": 62,
        "Il": "Tunceli",
        "No": 4,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 450
    },
    {
        "Plaka": 62,
        "Il": "Tunceli",
        "No": 5,
        "Ilce": "Nazımiye",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 62,
        "Il": "Tunceli",
        "No": 6,
        "Ilce": "Ovacık",
        "GelistirmeOdenegiPuani": 480
    },
    {
        "Plaka": 62,
        "Il": "Tunceli",
        "No": 7,
        "Ilce": "Pertek",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 62,
        "Il": "Tunceli",
        "No": 8,
        "Ilce": "Pülümür",
        "GelistirmeOdenegiPuani": 490
    },
    {
        "Plaka": 64,
        "Il": "Uşak",
        "No": 1,
        "Ilce": "Banaz",
        "GelistirmeOdenegiPuani": 85
    },
    {
        "Plaka": 64,
        "Il": "Uşak",
        "No": 2,
        "Ilce": "Eşme",
        "GelistirmeOdenegiPuani": 85
    },
    {
        "Plaka": 64,
        "Il": "Uşak",
        "No": 3,
        "Ilce": "Karahallı",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 64,
        "Il": "Uşak",
        "No": 4,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 75
    },
    {
        "Plaka": 64,
        "Il": "Uşak",
        "No": 5,
        "Ilce": "Sivaslı",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 64,
        "Il": "Uşak",
        "No": 6,
        "Ilce": "Ulubey",
        "GelistirmeOdenegiPuani": 90
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 1,
        "Ilce": "Bahçesaray",
        "GelistirmeOdenegiPuani": 380
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 2,
        "Ilce": "Başkale",
        "GelistirmeOdenegiPuani": 370
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 3,
        "Ilce": "Çaldıran",
        "GelistirmeOdenegiPuani": 370
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 4,
        "Ilce": "Çatak",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 5,
        "Ilce": "Edremit",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 6,
        "Ilce": "Erciş",
        "GelistirmeOdenegiPuani": 340
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 7,
        "Ilce": "Gevaş",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 8,
        "Ilce": "Gürpınar",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 9,
        "Ilce": "İpekyolu",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 10,
        "Ilce": "Muradiye",
        "GelistirmeOdenegiPuani": 350
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 11,
        "Ilce": "Özalp",
        "GelistirmeOdenegiPuani": 360
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 12,
        "Ilce": "Saray",
        "GelistirmeOdenegiPuani": 370
    },
    {
        "Plaka": 65,
        "Il": "Van",
        "No": 13,
        "Ilce": "Tuşba",
        "GelistirmeOdenegiPuani": 330
    },
    {
        "Plaka": 77,
        "Il": "Yalova",
        "No": 1,
        "Ilce": "Altınova",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 77,
        "Il": "Yalova",
        "No": 2,
        "Ilce": "Armutlu",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 77,
        "Il": "Yalova",
        "No": 3,
        "Ilce": "Çiftlikköy",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 77,
        "Il": "Yalova",
        "No": 4,
        "Ilce": "Çınarcık",
        "GelistirmeOdenegiPuani": 65
    },
    {
        "Plaka": 77,
        "Il": "Yalova",
        "No": 5,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 65
    },
    {
        "Plaka": 77,
        "Il": "Yalova",
        "No": 6,
        "Ilce": "Termal",
        "GelistirmeOdenegiPuani": 70
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 1,
        "Ilce": "Akdağmadeni",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 2,
        "Ilce": "Aydıncık",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 3,
        "Ilce": "Boğazlıyan",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 4,
        "Ilce": "Çandır",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 5,
        "Ilce": "Çayıralan",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 6,
        "Ilce": "Çekerek",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 7,
        "Ilce": "Kadışehri",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 8,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 140
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 9,
        "Ilce": "Saraykent",
        "GelistirmeOdenegiPuani": 200
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 10,
        "Ilce": "Sarıkaya",
        "GelistirmeOdenegiPuani": 180
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 11,
        "Ilce": "Şefaatli",
        "GelistirmeOdenegiPuani": 170
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 12,
        "Ilce": "Sorgun",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 13,
        "Ilce": "Yenifakılı",
        "GelistirmeOdenegiPuani": 150
    },
    {
        "Plaka": 66,
        "Il": "Yozgat",
        "No": 14,
        "Ilce": "Yerköy",
        "GelistirmeOdenegiPuani": 145
    },
    {
        "Plaka": 67,
        "Il": "Zonguldak",
        "No": 1,
        "Ilce": "Alaplı",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 67,
        "Il": "Zonguldak",
        "No": 2,
        "Ilce": "Çaycuma",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 67,
        "Il": "Zonguldak",
        "No": 3,
        "Ilce": "Devrek",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 67,
        "Il": "Zonguldak",
        "No": 4,
        "Ilce": "Ereğli",
        "GelistirmeOdenegiPuani": 40
    },
    {
        "Plaka": 67,
        "Il": "Zonguldak",
        "No": 5,
        "Ilce": "Gökçebey",
        "GelistirmeOdenegiPuani": 60
    },
    {
        "Plaka": 67,
        "Il": "Zonguldak",
        "No": 6,
        "Ilce": "Kilimli",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 67,
        "Il": "Zonguldak",
        "No": 7,
        "Ilce": "Kozlu",
        "GelistirmeOdenegiPuani": 0
    },
    {
        "Plaka": 67,
        "Il": "Zonguldak",
        "No": 8,
        "Ilce": "Merkez",
        "GelistirmeOdenegiPuani": 50
    }
];
export default MaasGelistirmeOdenegi;
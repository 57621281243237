import React, { useState } from 'react';
import { Container, Row, Col, Form, Table, Alert, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"; 
const data = [
  { unvan: "UZMAN TABİP", evdm: 8624.06, tabanKatsayi: 4.5, brutTutar: 38808.38, gelirVergisi: 5821.24, damgaVergisi: 294.55, netTutar: 32692.48 },
  { unvan: "ASİSTAN (TABİP)", evdm: 8624.06, tabanKatsayi: 3.4, brutTutar: 29321.81, gelirVergisi: 4398.27, damgaVergisi: 222.55, netTutar: 24700.99 },
  { unvan: "TABİP RİSKLİ", evdm: 8624.06, tabanKatsayi: 2.486, brutTutar: 21439.42, gelirVergisi: 3215.91, damgaVergisi: 162.73, netTutar: 18060.78 },
  { unvan: "TABİP", evdm: 8624.06, tabanKatsayi: 2.26, brutTutar: 19490.38, gelirVergisi: 2923.56, damgaVergisi: 147.93, netTutar: 16418.89 },
  { unvan: "ECZACI", evdm: 8624.06, tabanKatsayi: 1.2, brutTutar: 10348.87, gelirVergisi: 1552.33, damgaVergisi: 78.55, netTutar: 8718.00 },
  { unvan: "HEMŞİRE - EBE-SAĞLIK MEMURU RİSKLİ", evdm: 8624.06, tabanKatsayi: 0.518, brutTutar: 4467.20, gelirVergisi: 670.09, damgaVergisi: 33.91, netTutar: 3763.27 },
  { unvan: "HEMŞİRE - EBE-SAĞLIK MEMURU NORMAL", evdm: 8624.06, tabanKatsayi: 0.37, brutTutar: 3190.90, gelirVergisi: 478.64, damgaVergisi: 24.22, netTutar: 2688.05 },
  { unvan: "ŞOFÖR", evdm: 8624.06, tabanKatsayi: 0.26, brutTutar: 2242.26, gelirVergisi: 336.34, damgaVergisi: 17.01, netTutar: 1888.90 },
  { unvan: "VERİ HAZIRLAMA VE KONTROL İŞLETME", evdm: 8624.06, tabanKatsayi: 0.14, brutTutar: 1208.89, gelirVergisi: 181.33, damgaVergisi: 9.18, netTutar: 1018.38 },
  { unvan: "HİZMETLİ", evdm: 8624.06, tabanKatsayi: 0.12, brutTutar: 1034.89, gelirVergisi: 155.23, damgaVergisi: 7.85, netTutar: 871.80 }
];

function TabanEkOdeme() {
  const [selectedUnvan, setSelectedUnvan] = useState(data[0]);
  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    const selected = data.find(item => item.unvan === event.target.value);
    setSelectedUnvan(selected);
  };

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1 id="taban-ek-odeme" className="my-4">
        TABAN EK ÖDEME HESAPLAMALARI
      </h1>
      <Form>
        <Form.Group as={Row} controlId="formUnvanSelect">
          <Form.Label column sm={2}>
            Ünvan Seçin
          </Form.Label>
          <Col sm={10}>
            <Form.Control as="select" onChange={handleSelectChange}>
              {data.map((item, index) => (
                <option key={index} value={item.unvan}>
                  {item.unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      </Form>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>E.V.D.M.</th>
            <th>Taban Katsayı</th>
            <th>Brüt Tutar</th>
            <th>Gelir Vergisi</th>
            <th>Damga Vergisi</th>
            <th>Net Tutar</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selectedUnvan.evdm}</td>
            <td>{selectedUnvan.tabanKatsayi}</td>
            <td>{selectedUnvan.brutTutar}</td>
            <td>{selectedUnvan.gelirVergisi}</td>
            <td>{selectedUnvan.damgaVergisi}</td>
            <td>{selectedUnvan.netTutar}</td>
          </tr>
        </tbody>
      </Table>
      <Alert variant="info" className="mt-4">
        <h3>Bilgilendirme</h3>
        <h4>---------------------</h4>
      </Alert>
    </Container>
  );
}

export default TabanEkOdeme;

import React, { useState } from 'react';
import { Container, Row, Col, Form, Table, Alert, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"; 


function Calisiyor() {

  const navigate = useNavigate();

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1 id="taban-ek-odeme" className="my-4">
        SAĞLIK SEN ÇALIŞIYOR...
      </h1>
     
    </Container>
  );
}

export default Calisiyor;

import React from 'react';
import './InfoComponent.css';

const TavanHastaneInfo = () => {
  return (
    <div className="info-container">
      <h2>TAVAN EK ÖDEME YAPILMASININ AMACI NEDİR?</h2>
      <p>
        Sağlık Bakanlığı ve bağlı kuruluşlarında memur ve sözleşmeli personel olarak görev yapan veya en az bir ay süreyle görevlendirilen personeline, ilgili mevzuatı uyarınca ödenecek olan ek ödeme tutarı, tavan ek ödeme tutarını geçemez. İlgili yönetmelik kapsamındaki sözleşmeli personele yapılacak ek ödemenin tutarı, aynı birimde aynı unvanlı kadroda çalışan ve hizmet yılı aynı olan emsali personel esas alınarak belirlenir ve bunlara yapılabilecek ek ödeme, hiçbir şekilde emsaline yapılabilecek tavan ek ödeme tutarını geçmemesidir.
      </p>
      
      <h2>TAVAN EK ÖDEME KİMLERİ KAPSAR?</h2>
      <p>
        İl sağlık müdürlüğü ve il sağlık müdürlüğü birimlerinde görevli ya da geçici görevlendirilen memur ve sözleşmeli personeli, açıktan vekil olarak atananları, 4/11/1981 tarihli ve 2547 sayılı Yükseköğretim Kanununun 38 inci maddesine göre bu kuruluşlarda görevlendirilen personeli, diğer kamu kurum ve kuruluşlarının kadrolarında bulunan ve en az bir ay süreyle bu kuruluşlarda görevlendirilen sağlık personelini kapsar.
      </p>

      <h2>TAVAN EK ÖDEME RESMİ DAYANAĞI NEDİR?</h2>
      <p>
        12/08/2022 tarih ve 31921 sayılı Resmi Gazete’de yayımlanarak yürürlüğe giren Sağlık Bakanlığı Ek Ödeme Yönetmeliğinin 14 üncü maddesine dayanılarak hazırlanmıştır.
      </p>

      <h2>TAVAN EK ÖDEME ESASLARI NELERDİR?</h2>
      <p>
        Tavan ek ödeme tutarı; Ek ödeme matrahı personelin bir ayda alacağı aylık (ek gösterge dahil), yan ödeme ve her türlü tazminat (makam, temsil ve görev tazminatı ile yabancı dil tazminatı hariç) toplamını esas almaktadır.
      </p>

      <h2>TAVAN EK ÖDEME NASIL HESAPLANIR?</h2>
      <p>
        Tavan ek ödeme tutarı: Personelin bir ayda alacağı aylık (ek gösterge dahil), yan ödeme ve her türlü tazminat (makam, temsil ve görev tazminatı ile yabancı dil tazminatı hariç) toplamının, Ek-1’deki tabloda yer alan katsayı çarpımı ile hesaplanır.
      </p>
    </div>
  );
}

export default TavanHastaneInfo;

// TavanUniversiteler.js
import React, { useState } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { data } from './TavanHastane'; // data değişkenini TavanHastane.js'den içe aktarın
import { useNavigate } from "react-router-dom"; 
import TavanHastaneInfo from './TavanHastaneInfo';

const TavanHastaneler = () => {
  const [selectedUnvani, setSelectedUnvani] = useState('');
  const [selectedBransi, setSelectedBransi] = useState('');
  const [selectedDerece, setSelectedDerece] = useState('');
  const [selectedKademe, setSelectedKademe] = useState('');

  const uniqueUnvanlar = [...new Set(data.map(item => item.Unvani))];

  const handleUnvaniChange = (e) => {
    setSelectedUnvani(e.target.value);
    setSelectedBransi('');
    setSelectedDerece('');
    setSelectedKademe('');
  };

  const handleBransiChange = (e) => {
    setSelectedBransi(e.target.value);
    setSelectedDerece('');
    setSelectedKademe('');
  };

  const handleDereceChange = (e) => {
    setSelectedDerece(e.target.value);
    setSelectedKademe('');
  };

  const handleKademeChange = (e) => {
    setSelectedKademe(e.target.value);
  };

  const filteredBranlar = data
    .filter(item => item.Unvani === selectedUnvani)
    .map(item => item.Bransi);
  const uniqueBranlar = [...new Set(filteredBranlar)];

  const filteredDereceler = data
    .filter(item => item.Unvani === selectedUnvani && item.Bransi === selectedBransi)
    .map(item => item.Derece);
  const uniqueDereceler = [...new Set(filteredDereceler)];

  const filteredKademe = data
    .filter(item => item.Unvani === selectedUnvani && item.Bransi === selectedBransi && item.Derece.toString() === selectedDerece)
    .map(item => item.Kademe);
  const uniqueKademe = [...new Set(filteredKademe)];

  const selectedData = data.find(
    item =>
      item.Unvani === selectedUnvani &&
      item.Bransi === selectedBransi &&
      item.Derece.toString() === selectedDerece &&
      item.Kademe.toString() === selectedKademe
  );

  const navigate = useNavigate();

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1>HASTANELER TAVAN HESAPLAMASI</h1>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">Unvan:</Form.Label>
          <Col sm="10">
            <Form.Control as="select" value={selectedUnvani} onChange={handleUnvaniChange}>
              <option value="">Seçiniz</option>
              {uniqueUnvanlar.map(unvan => (
                <option key={unvan} value={unvan}>{unvan}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {selectedUnvani && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Branş:</Form.Label>
            <Col sm="10">
              <Form.Control as="select" value={selectedBransi} onChange={handleBransiChange}>
                <option value="">Seçiniz</option>
                {uniqueBranlar.map(brans => (
                  <option key={brans} value={brans}>{brans}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedBransi && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Derece:</Form.Label>
            <Col sm="10">
              <Form.Control as="select" value={selectedDerece} onChange={handleDereceChange}>
                <option value="">Seçiniz</option>
                {uniqueDereceler.map(derece => (

                  <option key={derece} value={derece}>{derece}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedDerece && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Kademe:</Form.Label>
            <Col sm="10">
              <Form.Control as="select" value={selectedKademe} onChange={handleKademeChange}>
                <option value="">Seçiniz</option>
                {uniqueKademe.map(kademe => (

                  <option key={kademe} value={kademe}>{kademe}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedData && (
          <>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Ek Ödeme Matrahı:</Form.Label>
              <Col sm="8">
                <Form.Control type="text" value={selectedData.EkOdemeMatrahi.toFixed(2) + " ₺"} readOnly />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">T.E.K.Ö.K.S. Ödemeye Esas:</Form.Label>
              <Col sm="8">
                <Form.Control type="text" value={selectedData.TEKSOdemeyeEsas.toFixed(2)} readOnly />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Tavan Tutarı:</Form.Label>
              <Col sm="8">
                <Form.Control type="text" value={selectedData.TavanTutari.toFixed(2) + " ₺"} readOnly />
              </Col>
            </Form.Group>
          </>
        )}
      </Form>
      <TavanHastaneInfo />
    </Container>
  );
}

export default TavanHastaneler;

import React, { useState } from "react";
import { Container, Modal, Row, Col, Form, Alert,Button  } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import InfoComponent from "./InfoComponent";
import { useNavigate } from "react-router-dom"; 
const data = [
  {
    Unvani: "1-Eğitim Görevlisi, Başasistan, Uzman Tabip",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 160,
    normal: 144.14,
    riskli: 216.22,
    diniBayramNormal: 36.04,
    diniBayramRiskli: 54.05,
  },
  {
    Unvani: "2-Pratisyen Tabip",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 145,
    normal: 130.63,
    riskli: 195.95,
    diniBayramNormal: 32.66,
    diniBayramRiskli: 48.99,
  },
  {
    Unvani: "3-Diş tabibi ve Eczacılar",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 130,
    normal: 117.12,
    riskli: 175.68,
    diniBayramNormal: 29.28,
    diniBayramRiskli: 43.92,
  },
  {
    Unvani: "4-Lisans ve Önlisans Sağlık Personeli",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 100,
    normal: 90.09,
    riskli: 135.14,
    diniBayramNormal: 22.52,
    diniBayramRiskli: 33.78,
  },
  {
    Unvani: "4-Lisans ve Önlisans Sağlık Personeli",
    memuriyetTipi: "4B",
    gostergeRakamı: 100,
    normal: 77.48,
    riskli: 116.22,
    diniBayramNormal: 19.37,
    diniBayramRiskli: 29.05,
  },
  {
    Unvani: "5-Lise veya dengi Sağlık Personeli",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 85,
    normal: 76.58,
    riskli: 114.87,
    diniBayramNormal: 19.14,
    diniBayramRiskli: 28.72,
  },
  {
    Unvani: "5-Lise veya dengi Sağlık Personeli",
    memuriyetTipi: "4B",
    gostergeRakamı: 85,
    normal: 65.86,
    riskli: 98.78,
    diniBayramNormal: 16.46,
    diniBayramRiskli: 24.70,
  },
  {
    Unvani: "6-Diğer personel",
    memuriyetTipi: "KADROLU",
    gostergeRakamı: 65,
    normal: 58.56,
    riskli: 87.84,
    diniBayramNormal: 14.64,
    diniBayramRiskli: 21.96,
  },
];

function IlkBasamakOdeme() {
  const [selectedUnvan, setSelectedUnvan] = useState("");
  const [selectedmemuriyetTipi, setSelectedmemuriyetTipi] = useState("");
  const [normalNobet, setNormalNobet] = useState(0);
  const [riskliNobet, setRiskliNobet] = useState(0);
  const [diniBayramNormal, setDiniBayramNormal] = useState(0);
  const [diniBayramRiskli, setDiniBayramRiskli] = useState(0);

  const [toplamGun, setToplamGun] = useState(0);
  const [nobetTip, SetNobetTip] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [netTutar, setNetTutar] = useState(0);
  const navigate = useNavigate();
  const handleClose = () => setShowModal(false);
  const uniqueUnvanlar = Array.from(new Set(data.map((item) => item.Unvani)));
  const filteredmemuriyetTipi = data.filter(
    (item) => item.Unvani === selectedUnvan
  );
  const selectedData = data.find(
    (item) =>
      item.Unvani === selectedUnvan &&
      item.memuriyetTipi === selectedmemuriyetTipi
  );

  const nobetTuruChange = (val) => {
    SetNobetTip(val);
    if (val === 0 && selectedData) {
      setNetTutar(selectedData.normal * normalNobet + (selectedData.diniBayramNormal) * diniBayramNormal);
    }
    if (val === 1 && selectedData) {
      setNetTutar(selectedData.riskli * riskliNobet + (selectedData.diniBayramRiskli) * diniBayramRiskli);
    }
  };

  const unvanChange = (val) => {
    setSelectedUnvan(val);
    setNormalNobet(0);
    setRiskliNobet(0);
    setDiniBayramNormal(0);
    setDiniBayramRiskli(0);
    setNetTutar(0);
  };
  const memuriyetTipiChange = (val) => {
    setSelectedmemuriyetTipi(val);
    setNormalNobet(0);
    setRiskliNobet(0);
    setDiniBayramNormal(0);
    setDiniBayramRiskli(0);
    setNetTutar(0);
  };

  const normalNobetSet = (saat) => {
    if (Number(saat) < Number(diniBayramNormal)) {
      setModalMessage(
        "Bayram nöbet saati nöbet saatinden fazla olamaz."
      );
      setShowModal(true);
    } else {
      const toplam = Number(saat) + Number(diniBayramNormal);
      console.log(toplam);
      if (saat > 130) {
        setModalMessage("Nöbet 130 nöbet/saatten fazla olamaz.");
        setShowModal(true);
      } else if (saat < 0) {
        setNormalNobet(0);
      } else {
        setNormalNobet(saat);
      }
      setNetTutar((selectedData.normal * saat) + ((selectedData.diniBayramNormal) * diniBayramNormal));
    }
    

  };
  const bayramNobetSet = (saat) => {
    if (Number(saat) > Number(normalNobet)) {
      setModalMessage(
        "Bayram nöbet saati nöbet saatinden fazla olamaz."
      );
      setShowModal(true);
    } else {
      if ( saat > 130) {
        setModalMessage("Bayram nöbet saati 130 saatten fazla olamaz.");
        setShowModal(true);
      } else if (saat < 0) {
        setDiniBayramNormal(0);
      } else {
        setDiniBayramNormal(saat);
      }
      setNetTutar((selectedData.normal * normalNobet) + ((selectedData.diniBayramNormal) * saat));
    }

  };
  const riskliNobetSet = (saat) => {
    if (Number(saat) < Number(diniBayramRiskli)) {
      setModalMessage(
        "Bayram nöbet saati nöbet saatinden fazla olamaz."
      );
      setShowModal(true);
    } else {
      const toplam = Number(saat) + Number(diniBayramRiskli);
      if (saat > 130) {
        setModalMessage("Nöbet 130 nöbet/saatten fazla olamaz.");
        setShowModal(true);
      } else if (saat < 0) {
        setRiskliNobet(0);
      } else {
        setRiskliNobet(saat);
      }
      setNetTutar((selectedData.riskli * saat) + (selectedData.diniBayramRiskli * diniBayramRiskli));
    }
    
  };
  const riskliBayramNobetSet = (saat) => {
    if (Number(saat) > Number(riskliNobet)) {
      setModalMessage("Bayram nöbet saati nöbet saatinden fazla olamaz.");
      setShowModal(true);
    } else {
      if (saat > 130) {
        setModalMessage("Bayram nöbet saati 130 saatten fazla olamaz.");
        setShowModal(true);
      } else if (saat < 0) {
        setDiniBayramRiskli(0);
      } else {
        setDiniBayramRiskli(saat);
      }
      setNetTutar(
        selectedData.riskli * riskliNobet + selectedData.diniBayramRiskli * saat
      );
    }
  };

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1
        className="text-center mb-4"
        style={{ fontSize: "2.2rem", fontWeight: "bold" }}
      >
        NÖBET ÜCRETİ HESAPLAMA
      </h1>
      <Form>
        <Form.Group as={Row} className="mb-3" style={{ fontSize: "1.5rem" }}>
          <Form.Label column sm="6">
            Ünvan:
          </Form.Label>
          <Col sm="6">
            <Form.Control
              as="select"
              style={{ fontSize: "1.5rem" }}
              value={selectedUnvan}
              onChange={(e) => unvanChange(e.target.value)}
            >
              <option value="">Seçiniz</option>
              {uniqueUnvanlar.map((unvan) => (
                <option key={unvan} value={unvan}>
                  {unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {selectedUnvan && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
              Memuriyet Tipi:
            </Form.Label>
            <Col sm="6">
              <Form.Control
                as="select"
                style={{ fontSize: "1.5rem" }}
                value={selectedmemuriyetTipi}
                onChange={(e) => memuriyetTipiChange(e.target.value)}
              >
                <option value="">Seçiniz</option>
                {filteredmemuriyetTipi.map((item) => (
                  <option key={item.memuriyetTipi} value={item.memuriyetTipi}>
                    {item.memuriyetTipi}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedData && (
          <>
            <Form.Group as={Row} className="mb-3 align-items-center">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Gösterge Rakamı:
              </Form.Label>
              <Form.Label
                column
                sm="6"
                style={{
                  fontSize: "1.5rem",
                  backgroundColor: "red",
                  maxWidth: "25%",
                  marginLeft: "12.5%",
                  color: "white",
                }}
              >
                {selectedData.gostergeRakamı}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                Nöbet Türü:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  as="select"
                  value={nobetTip}
                  onChange={(e) => nobetTuruChange(Number(e.target.value))}
                  style={{ fontSize: "1.5rem" }}
                >
                  <option value={0}>Normal</option>
                  <option value={1}>Riskli</option>
                </Form.Control>
              </Col>
            </Form.Group>
            {nobetTip === 0 ? (
              <>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                    Nöbet Saati:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="number"
                      value={normalNobet}
                      max={130}
                      min={0}
                      onChange={(e) => normalNobetSet(e.target.value)}
                      style={{ fontSize: "1.5rem" }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="6" style={{ fontSize: "1.5rem" }}>
                    Bayram Nöbet Saati:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="number"
                      value={diniBayramNormal}
                      max={130}
                      min={0}
                      onChange={(e) => bayramNobetSet(e.target.value)}
                      style={{ fontSize: "1.5rem" }}
                    />
                  </Col>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label
                    column
                    sm="6"
                    className="basamak-yazi"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Riskli Nöbet Saati:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="number"
                      value={riskliNobet}
                      max={130}
                      min={0}
                      onChange={(e) => riskliNobetSet(e.target.value)}
                      style={{ fontSize: "1.5rem" }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label
                    column
                    sm="6"
                    className="basamak-yazi"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Riskli Bayram Nöbet Saati:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="number"
                      value={diniBayramRiskli}
                      max={130}
                      min={0}
                      onChange={(e) => riskliBayramNobetSet(e.target.value)}
                      style={{ fontSize: "1.5rem" }}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
          </>
        )}

        {selectedData && netTutar > 0 && (
          <Alert variant="success" className="basamak-sonuc">
            <h4
              className="basamak-yazi"
              style={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              ÖDENECEK NET TUTAR:{" "}
              {netTutar.toFixed(2).toLocaleString(undefined)} ₺
            </h4>
          </Alert>
        )}
      </Form>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Uyarı</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Alert variant="info" className="mt-4"> */}
      <InfoComponent></InfoComponent>
      {/* </Alert> */}
    </Container>
  );
}

export default IlkBasamakOdeme;

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Alert,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const data = [
  {
    unvan: "ECZACI",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 10265.72,
    mesaiIciKatsayi: 345,
    mesaiDisiKatsayi: 69,
    sabitEkOdemeTutari: 15553.04,
    tabanEkOdemeTutari: 10348.85,
    toplamMesaiIciDisiLimitTutari: 35416.73,
  },
  {
    unvan: "KİMYAGER",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "LABORATUVAR",
    ekOdemeMatrahi: 14982.95,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 35209.93,
  },
  {
    unvan: "VEZNEDAR",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 9193.74,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14256.95,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 21605.29,
  },
  {
    unvan: "ŞOFÖR",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 6552.98,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 12960.86,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 15399.51,
  },
  {
    unvan: "DİĞER SAĞLIK PERSONELİ",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 6886.91,
    mesaiIciKatsayi: 270,
    mesaiDisiKatsayi: 54,
    sabitEkOdemeTutari: 10972.44,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 18594.65,
  },
  {
    unvan: "UZMAN (ÖĞRT. GÖR.)",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 17104.38,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 8532.57,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 40195.29,
  },
  {
    unvan: "UZMAN DR. (ÖĞRT. GÖR.)",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 17143.42,
    mesaiIciKatsayi: 675,
    mesaiDisiKatsayi: 675,
    sabitEkOdemeTutari: 28890.58,
    tabanEkOdemeTutari: 38808.23,
    toplamMesaiIciDisiLimitTutari: 115718.05,
  },
  {
    unvan: "PSİKOLOG",
    birimAdi: "POLİKLİNİK-SERVİS",
    altBirimAdi: "POLİKLİNİK-SERVİS",
    ekOdemeMatrahi: 12676.56,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 29789.91,
  },
  {
    unvan: "SAĞLIK FİZİKÇİSİ",
    birimAdi: "POLİKLİNİK-SERVİS",
    altBirimAdi: "POLİKLİNİK-SERVİS",
    ekOdemeMatrahi: 11818.71,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 13608.9,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 27773.96,
  },
  {
    unvan: "TABİP",
    birimAdi: "DEKANLIK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 14304.88,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 25827.08,
    tabanEkOdemeTutari: 7789.37,
    toplamMesaiIciDisiLimitTutari: 33616.46,
  },
  {
    unvan: "LABORANT",
    birimAdi: "DEKANLIK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 13484.51,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 31688.6,
  },
  {
    unvan: "DİYETİSYEN",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 13484.51,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 31688.6,
  },
  {
    unvan: "MÜHENDİS",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 19606.28,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 24168.46,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 46074.77,
  },
  {
    unvan: "TEKNİKER",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 12259.89,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 28810.74,
  },
  {
    unvan: "TEKNİSYEN YARDIMCISI",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 5825.75,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 11664.78,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 13690.52,
  },
  {
    unvan: "AŞÇI",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 5536.25,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 11664.78,
    tabanEkOdemeTutari: 1345.4,
    toplamMesaiIciDisiLimitTutari: 13010.18,
  },
  {
    unvan: "BİLGİSAYAR İŞLETMENİ",
    birimAdi: "DEKANLIK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 7941.89,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 12960.86,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 18663.45,
  },
  {
    unvan: "HASTA BAKICI",
    birimAdi: "POLİKLİNİK-SERVİS",
    altBirimAdi: "POLİKLİNİK-SERVİS",
    ekOdemeMatrahi: 4578.65,
    mesaiIciKatsayi: 270,
    mesaiDisiKatsayi: 54,
    sabitEkOdemeTutari: 9072.62,
    tabanEkOdemeTutari: 1034.88,
    toplamMesaiIciDisiLimitTutari: 12362.36,
  },
  {
    unvan: "HİZMETLİ",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 4896.38,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 10368.68,
    tabanEkOdemeTutari: 1034.88,
    toplamMesaiIciDisiLimitTutari: 11506.48,
  },
  {
    unvan: "MEMUR",
    birimAdi: "POLİKLİNİK-SERVİS",
    altBirimAdi: "POLİKLİNİK-SERVİS",
    ekOdemeMatrahi: 6503.05,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 12960.86,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 15282.17,
  },
  {
    unvan: "DESTEK PERSONELİ",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 6886.91,
    mesaiIciKatsayi: 270,
    mesaiDisiKatsayi: 54,
    sabitEkOdemeTutari: 8127.73,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 18594.65,
  },
  {
    unvan: "HASTANE BAŞMÜDÜRÜ",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "BAŞMÜDÜRLÜK",
    ekOdemeMatrahi: 24005.73,
    mesaiIciKatsayi: 345,
    mesaiDisiKatsayi: 69,
    sabitEkOdemeTutari: 22239.84,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 82819.78,
  },
  {
    unvan: "AVUKAT",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 12243.03,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 18145.2,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 28771.12,
  },
  {
    unvan: "ŞEF",
    birimAdi: "DEKANLIK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 11176.78,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 26265.44,
  },
  {
    unvan: "ARAŞTIRMA GÖREVLİSİ (YAN DAL)",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "ANABİLİM DALI",
    ekOdemeMatrahi: 21801.05,
    mesaiIciKatsayi: 650,
    mesaiDisiKatsayi: 650,
    sabitEkOdemeTutari: 28890.58,
    tabanEkOdemeTutari: 58212.33,
    toplamMesaiIciDisiLimitTutari: 141706.83,
  },
  {
    unvan: "REKTÖR",
    birimAdi: "REKTÖRLÜK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 34965.25,
    mesaiIciKatsayi: 600,
    mesaiDisiKatsayi: 0,
    sabitEkOdemeTutari: 11457.49,
    tabanEkOdemeTutari: 0,
    toplamMesaiIciDisiLimitTutari: 209791.51,
  },
  {
    unvan: "REKTÖR YARDIMCISI",
    birimAdi: "REKTÖRLÜK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 31878.75,
    mesaiIciKatsayi: 300,
    mesaiDisiKatsayi: 0,
    sabitEkOdemeTutari: 10188.68,
    tabanEkOdemeTutari: 0,
    toplamMesaiIciDisiLimitTutari: 95636.25,
  },
  {
    unvan: "PROF. DR.",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 29563.86,
    mesaiIciKatsayi: 750,
    mesaiDisiKatsayi: 950,
    sabitEkOdemeTutari: 9951.73,
    tabanEkOdemeTutari: 34496.2,
    toplamMesaiIciDisiLimitTutari: 221728.96,
  },
  {
    unvan: "DOÇ. DR.",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 22074.55,
    mesaiIciKatsayi: 750,
    mesaiDisiKatsayi: 950,
    sabitEkOdemeTutari: 8056.16,
    tabanEkOdemeTutari: 34496.2,
    toplamMesaiIciDisiLimitTutari: 165559.09,
  },
  {
    unvan: "ÖĞRETİM GÖREVLİSİ",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 14853.03,
    mesaiIciKatsayi: 750,
    mesaiDisiKatsayi: 950,
    sabitEkOdemeTutari: 9359.37,
    tabanEkOdemeTutari: 34496.2,
    toplamMesaiIciDisiLimitTutari: 111397.75,
  },
  {
    unvan: "ÖĞRETİM GÖREVLİSİ",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 17104.38,
    mesaiIciKatsayi: 950,
    mesaiDisiKatsayi: 950,
    sabitEkOdemeTutari: 9359.37,
    tabanEkOdemeTutari: 38808.23,
    toplamMesaiIciDisiLimitTutari: 162491.58,
  },
  {
    unvan: "ARAŞTIRMA GÖREVLİSİ",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "ANABİLİM DALI",
    ekOdemeMatrahi: 14908.2,
    mesaiIciKatsayi: 650,
    mesaiDisiKatsayi: 650,
    sabitEkOdemeTutari: 22853.75,
    tabanEkOdemeTutari: 29321.77,
    toplamMesaiIciDisiLimitTutari: 96903.31,
  },
  {
    unvan: "GENEL SEKRETER",
    birimAdi: "REKTÖRLÜK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 29563.86,
    mesaiIciKatsayi: 200,
    mesaiDisiKatsayi: 0,
    sabitEkOdemeTutari: 6036.84,
    tabanEkOdemeTutari: 0,
    toplamMesaiIciDisiLimitTutari: 59127.72,
  },
  {
    unvan: "BAŞHEMŞİRE",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "ANABİLİM DALI",
    ekOdemeMatrahi: 13484.51,
    mesaiIciKatsayi: 270,
    mesaiDisiKatsayi: 54,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 36408.18,
  },
  {
    unvan: "BAŞHEMŞİRE YARDIMCISI",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "ANABİLİM DALI",
    ekOdemeMatrahi: 13484.51,
    mesaiIciKatsayi: 270,
    mesaiDisiKatsayi: 54,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 36408.18,
  },
  {
    unvan: "FAKÜLTE SEKRETERİ",
    birimAdi: "DEKANLIK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 19233.71,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 22033.47,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 45199.23,
  },
  {
    unvan: "HASTANE MÜDÜR YARDIMCISI",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 16919.35,
    mesaiIciKatsayi: 270,
    mesaiDisiKatsayi: 54,
    sabitEkOdemeTutari: 21385.42,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 45682.25,
  },
  {
    unvan: "İSTATİSTİKÇİ",
    birimAdi: "DEKANLIK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 14842.25,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 15553.04,
    tabanEkOdemeTutari: 1379.84,
    toplamMesaiIciDisiLimitTutari: 34879.28,
  },
  {
    unvan: "SAĞLIK TEKNİKERİ",
    birimAdi: "POLİKLİNİK-SERVİS",
    altBirimAdi: "POLİKLİNİK-SERVİS",
    ekOdemeMatrahi: 13484.51,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 31688.6,
  },
  {
    unvan: "FİZYOTERAPİST",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "ANABİLİM DALI",
    ekOdemeMatrahi: 13484.51,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 31688.6,
  },
  {
    unvan: "BİYOLOG",
    birimAdi: "DEKANLIK",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 14029.19,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 32968.59,
  },
  {
    unvan: "SOSYAL ÇALIŞMACI",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 13484.51,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 2759.7,
    toplamMesaiIciDisiLimitTutari: 31688.6,
  },
  {
    unvan: "EBE",
    birimAdi: "POLİKLİNİK-SERVİS",
    altBirimAdi: "POLİKLİNİK-SERVİS",
    ekOdemeMatrahi: 13375.57,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 31432.59,
  },
  {
    unvan: "SAĞLIK TEKNİSYENİ",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 12880.83,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 30269.94,
  },
  {
    unvan: "HEMŞİRE",
    birimAdi: "GENEL İDARE",
    altBirimAdi: "GENEL İDARE",
    ekOdemeMatrahi: 10829.2,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 12312.83,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 25448.61,
  },
  {
    unvan: "HEMŞİRE",
    birimAdi: "POLİKLİNİK-SERVİS",
    altBirimAdi: "POLİKLİNİK-SERVİS",
    ekOdemeMatrahi: 12880.83,
    mesaiIciKatsayi: 235,
    mesaiDisiKatsayi: 47,
    sabitEkOdemeTutari: 14904.99,
    tabanEkOdemeTutari: 3190.9,
    toplamMesaiIciDisiLimitTutari: 30269.94,
  },
  {
    unvan: "DOKTOR ÖĞRETİM ÜYESİ",
    birimAdi: "ANABİLİM DALI",
    altBirimAdi: "REKTÖRLÜK",
    ekOdemeMatrahi: 20985.2,
    mesaiIciKatsayi: 950,
    mesaiDisiKatsayi: 950,
    sabitEkOdemeTutari: 9477.82,
    tabanEkOdemeTutari: 38808.23,
    toplamMesaiIciDisiLimitTutari: 199359.37,
  },
];

const TavanUniversite = () => {
  const [selectedUnvan, setSelectedUnvan] = useState("");
  const [selectedBirim, setSelectedBirim] = useState("");
  const [selectedAltBirim, setSelectedAltBirim] = useState("");
  const navigate = useNavigate();
  const uniqueUnvanlar = [...new Set(data.map((item) => item.unvan))];

  const handleUnvanChange = (e) => {
    setSelectedUnvan(e.target.value);
    setSelectedBirim("");
    setSelectedAltBirim("");
  };

  const handleBirimChange = (e) => {
    setSelectedBirim(e.target.value);
    setSelectedAltBirim("");
  };

  const handleAltBirimChange = (e) => {
    setSelectedAltBirim(e.target.value);
  };

  const filteredBirimler = data
    .filter((item) => item.unvan === selectedUnvan)
    .map((item) => item.birimAdi);
  const uniqueBirimler = [...new Set(filteredBirimler)];

  const filteredAltBirimler = data
    .filter(
      (item) => item.unvan === selectedUnvan && item.birimAdi === selectedBirim
    )
    .map((item) => item.altBirimAdi);
  const uniqueAltBirimler = [...new Set(filteredAltBirimler)];

  const selectedData = data.find(
    (item) =>
      item.unvan === selectedUnvan &&
      item.birimAdi === selectedBirim &&
      item.altBirimAdi === selectedAltBirim
  );

  return (
    <Container className="BasamakOdeme mt-5">
      <Button
        variant="primary"
        className="mt-2"
        style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "1.2rem", backgroundColor: "#17b6cd", borderColor: "#17b6cd" }}
        onClick={() => navigate(-1)}
      >
        Seçim Sayfasına Geri Dön
      </Button>
      <h1>Üniversite Tavan Ek Ödeme Bilgileri</h1>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Ünvan:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={selectedUnvan}
              onChange={handleUnvanChange}
            >
              <option value="">Seçiniz</option>
              {uniqueUnvanlar.sort().map((unvan) => (
                <option key={unvan} value={unvan}>
                  {unvan}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {selectedUnvan && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Birim Adı:
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="select"
                value={selectedBirim}
                onChange={handleBirimChange}
              >
                <option value="">Seçiniz</option>
                {uniqueBirimler.sort().map((birim) => (
                  <option key={birim} value={birim}>
                    {birim}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedBirim && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Alt Birim Adı:
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="select"
                value={selectedAltBirim}
                onChange={handleAltBirimChange}
              >
                <option value="">Seçiniz</option>
                {uniqueAltBirimler.map((altBirim) => (
                  <option key={altBirim} value={altBirim}>
                    {altBirim}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {selectedData && (
          <>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Ek Ödeme Matrahı:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={selectedData.ekOdemeMatrahi}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Mesai İçi Katsayı:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={selectedData.mesaiIciKatsayi}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Mesai Dışı Katsayı:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={selectedData.mesaiDisiKatsayi}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Sabit Ek Ödeme Tutarı:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={selectedData.sabitEkOdemeTutari}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Taban Ek Ödeme Tutarı:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={selectedData.tabanEkOdemeTutari}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Toplam Mesai İçi ve Dışı Limit Tutarı:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={selectedData.toplamMesaiIciDisiLimitTutari}
                  readOnly
                />
              </Col>
            </Form.Group>
          </>
        )}
      </Form>
    </Container>
  );
}

export default TavanUniversite;
import React from 'react';
import './InfoComponent.css';

const TayinBedeliInfo = () => {
  return (
    <div className="info-container">
      <h2>Bilgilendirme</h2>
      <p>
      * 7. Dönem Toplu Sözleşme Madde 18 (1) "Sağlık Bakanlığı 112 acil
          sağlık hizmetlerinde görev yapan kamu görevlilerinden Devlet Memurları
          Yiyecek Yardımı Yönetmeliğinin ek 2. maddesinden yararlanma imkanı
          bulunamayanlara, ödener sermaye bütçesinden karşılanmak üzere
          22/6/1978 tarihli ve 2155 sayılı Kanuna göre tayın bedeli verilir. Bu
          maddenin uygulanmasına ilişkin usul ve esaslar Maliye Bakanlığının
          görüşü üzerine Sağlık Bakanlığınca belirlenir." hükmü uygulanır.
      </p>
      
      <p>
      * Tayın (Yemek) Bedeli Ödemesi A1, A2 ve C tipi Acil Sağlık Hizmetleri
          İstasyonları ile İl Ambulans Servisi Komuta Kontrol Merkezinde fiilen
          görev yapan personele tayın bedeli verilir.
      </p>
      
      <p>
          * Tayın bedelinin aylık tutarı, 2155 sayılı Bazı Kamu Personeline
          Tayın Bedeli Verilmesi Hakkında Kanun uyarınca yıl Merkezi Yönetim
          Bütçe Kanununda belirlenen gösterge rakamının memur aylık katsayısı
          ile çarpımı sonucu bulunacak tutarı geçemez.
        </p>
    </div>
  );
}

export default TayinBedeliInfo;
